import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes"
var authUser = localStorage.authUser;
if (authUser) {
  if (authUser == 'undefined') {
    localStorage.clear();
  } else {
    authUser = JSON.parse(authUser);
  }
} else {
  authUser = [];
}
// console.log('authUser', authUser);
const initialState = {
  error: "",
  loading: false,
  loggedinUser: authUser,
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        loggedinUser: action.payload,
      }
      break
    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default login
