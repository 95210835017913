import React, { Component } from "react"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { withRouter, Link } from "react-router-dom"



// users
import user1 from "../../../assets/images/users/user.png"





class ProfileMenu extends Component {
  constructor(props) {
    super(props)
    let userimg = user1;
    if (this.props.loggedinUser) {
      if (this.props.loggedinUser.image_url != 0) {
        userimg = this.props.loggedinUser.image_url;
      }
    } else {
      localStorage.clear();
    }
    this.state = {
      menu: false,
      userimg: userimg
    }
    this.toggle = this.toggle.bind(this)

  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }))
  }

  componentDidMount() {

    // console.log('this.props.loggedinUser', this.props.loggedinUser.image_url);
    // console.log('this.state.userimg', this.state.userimg);

  }

  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
            tag="button"
          >
            <img
              className="rounded-circle header-profile-user"
              src={this.state.userimg}
              alt="Header Avatar"
            />{" "}
            <span className="d-none d-xl-inline-block ms-1">
              {this.props.loggedinUser.name}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem tag="a" href="/profile">
              <i className="bx bx-user font-size-16 align-middle ms-1" />
              Profile
            </DropdownItem>



            <div className="dropdown-divider" />
            <Link to="/logout" className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
              <span>Logout</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    )
  }
}



const mapStateToProps = (state) => ({ loggedinUser: state.Login.loggedinUser })
export default connect(mapStateToProps)(withRouter(ProfileMenu))