import React, { Component } from "react"
import ReactDOM from "react-dom";
import { Alert, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Modal, Button, Table } from "reactstrap";

import MetaTags from 'react-meta-tags';
import axios from 'axios';
// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"
import { SCH_URL } from "../../../helpers/url_helper";
// import SweetAlert from "react-bootstrap-sweetalert"
// import Switch from 'react-switch';
// import Select from 'react-select';
import Flatpickr from "react-flatpickr"
import swal from 'sweetalert';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "../../../components/Common/datatables.scss"
const initialstate = {
  itempageabout: 'Time Table Template | Section',
  itemmainname: 'Section',
  itemsubname: 'Time Table Template',

  tttdays: [],
  timetemplate: {},
  showAlert: false,
  alertType: '',
  alertText: '',

  addKeyModal: false,

};
class TimeTable extends Component {
  constructor(props) {
    super(props)
    // this.state = {}
    this.handleGetkeys = this.handleGetkeys.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleAddTimeName = this.handleAddTimeName.bind(this);
    this.handleDkey = this.handleDkey.bind(this);
    this.handleTkey = this.handleTkey.bind(this);
    this.handleEditkey = this.handleEditkey.bind(this);
    // this.setTimenow = this.setTimenow.bind(this);
    flatpickr("#myID", {});
  }
  state = { ...initialstate };
  componentDidMount() {

    this.handleGetkeys();


  }

  setdayTimenow = (event) => {
    const { name, value } = event.target;
    console.log(name + ' ' + value);
    this.setState({ [name]: value });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name + ' ' + value);
    this.setState({ [name]: value });
  };
  handleGetkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
      tttid: localStorage.tttid,
      table: 'schsectt',
    };
    const getuurl = SCH_URL + 'gettttdays';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);

          console.log('timetemplate', response.data.details);



          this.setState({
            wdtimenamess: response.data.wdtimenamess,
            tttdays: response.data.details.templatedays,
            timetemplate: response.data.details,
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        // localStorage.clear();
        //this.props.history.push('/'); //eslint-disable-line
      });
  };


  handleAddTimeName = (wdid, wdname) => {

    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var addkeyurl = SCH_URL + 'addtttwd';
    var postData = {

      uid: localStorage.usercode,
      table: 'schsecttdtime',
      mtable: 'schsecttd',
      mmtable: 'schsectt',
      mitem: wdid,
      mmitem: localStorage.tttid,

    };
    axios
      .post(addkeyurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log(response.data.message);
        console.log('response.data', response.data);
        if (response.data.error) {
          this.setState({
            showAlert: true,
            alertType: 'warning',
            alertText: response.data.message,
          });
        } else {
          localStorage.setItem("token", response.data.token);
          this.handleGetkeys();

          swal(response.data.message, {
            icon: 'success',
          });
        }
      })
      .catch((error) => {
        console.log('Error is ', error);
        this.setState({
          alertType: 'danger',
          showAlert: true,
          alertText: 'Failed Try Again Later!',
        });
      });


  };


  handleValidSubmit = (event, values) => {
    event.preventDefault();
    console.log('values', values);
    if (this.state.mitemvalue == '') {
      this.setState({
        showAlert: true,
        alertType: 'warning',
        alertText: 'Select ' + this.state.mitemsubonename,
      });
    } else {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: localStorage.token,
      };
      var addkeyurl = SCH_URL + 'addmabitem';
      var postData = {
        itema: values.itema,
        itemb: values.itemb,
        description: values.description,
        mitem: this.state.mitemvalue,
        uid: localStorage.usercode,
        table: this.state.tname,
        mtable: this.state.tmdepname,
        clmb: this.state.clmb,

      };
      if (this.state.editmode) {
        addkeyurl = SCH_URL + 'editmabitem';
        postData = {
          itemid: this.state.editid,
          itema: values.itema,
          itemb: values.itemb,
          description: values.description,
          mitem: this.state.mitemvalue,
          uid: localStorage.usercode,
          table: this.state.tname,
          mtable: this.state.tmdepname,
          clmb: this.state.clmb,

        };
      } else if (this.state.csvmode) {
        addkeyurl = SCH_URL + 'csvmabitem';
        postData = {
          mitem: this.state.mitemvalue,
          uid: localStorage.usercode,
          table: this.state.tname,
          mtable: this.state.tmdepname,
          clmb: this.state.clmb,

        };
      }


      axios
        .post(addkeyurl, postData, {
          headers: headers,
        })
        .then((response) => {
          console.log(response.data.message);
          console.log('response.data', response.data);
          if (response.data.error) {
            this.setState({
              showAlert: true,
              alertType: 'warning',
              alertText: response.data.message,
            });
          } else {
            localStorage.setItem("token", response.data.token);
            this.handleGetkeys();
            this.setState(
              {
                addKeyModal: false,
              });
            swal(values.itema + ' ' + response.data.message, {
              icon: 'success',
            });
          }
        })
        .catch((error) => {
          console.log('Error is ', error);
          this.setState({
            alertType: 'danger',
            showAlert: true,
            alertText: 'Failed Try Again Later!',
          });
        });

    }
  };

  handleDkey = (id, item, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'id');
    console.log(item + 'item');

    swal({
      title: 'Delete ' + item,
      text: 'Are you sure?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((keyconfirm) => {
      if (keyconfirm) {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: localStorage.token,
        };

        const delkeyurl = SCH_URL + 'delitem';
        var postData = {
          keyid: id,
          uid: localStorage.usercode,
          table: 'schsecttdtime',
        };
        axios
          .post(delkeyurl, postData, {
            headers: headers,
          })
          .then((response) => {
            console.log(response.data.message);

            if (response.data.error) {
              swal(item + ' ' + response.data.message, {
                icon: 'warning',
              });
            } else {
              localStorage.setItem("token", response.data.token);
              this.handleGetkeys();
              swal(item + ' ' + response.data.message, {
                icon: 'success',
              });
            }
          })
          .catch((error) => {
            console.log('Error is ', error);
            swal('Try Again Later', {
              icon: 'warning',
            });
          });
      }
    });
  };
  handleTkey = (id, item, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'id');
    console.log(item + 'item');
    var keysts = checked == true ? 1 : 0;
    var kstatus = 'Deactivate';
    if (checked) {
      kstatus = 'Activate';
    }
    swal({
      title: kstatus + ' ' + item,
      text: 'Are you sure?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((keyconfirm) => {
      if (keyconfirm) {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: localStorage.token,
        };

        const togkeyurl = SCH_URL + 'togitem';
        var postData = {
          keyid: id,
          kstatus: keysts,
          uid: localStorage.usercode,
          table: this.state.tname,

        };
        axios
          .post(togkeyurl, postData, {
            headers: headers,
          })
          .then((response) => {
            console.log(response.data.message);

            if (response.data.error) {
              swal(item + ' ' + response.data.message, {
                icon: 'warning',
              });
            } else {
              localStorage.setItem("token", response.data.token);
              this.handleGetkeys();
              swal(item + ' ' + response.data.message, {
                icon: 'success',
              });
            }
          })
          .catch((error) => {
            console.log('Error is ', error);
            swal('Try Again Later', {
              icon: 'Danger',
            });
          });
      }
    });
  };

  handleAkey = (id, name) => {
    console.log(id + 'id');
    console.log(name + 'name');
    this.setState({
      addKeyModal: true,
      itemactionname: 'Add to ' + name + '',
      editmode: false,
      itemavalue: '',
      itembvalue: '',
      mitemvalue: id,
      descriptionvalue: '',
    });
  };
  handleCkey = () => {
    this.setState({
      addKeyModal: false,
      addCsvModal: false,
      editmode: false,
      itemavalue: '',
      itembvalue: '',
      mitemvalue: '',
      descriptionvalue: '',

    });
  };
  handleEditkey = (id, itema, itemb, mitem, description, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'id');
    console.log(itema + 'itema');
    console.log(itemb + 'itemb');
    console.log(mitem + 'mitem');
    console.log(description + 'description');
    this.setState({
      addKeyModal: true,
      editmode: true,
      editid: id,
      itemavalue: itema,
      itembvalue: itemb,
      mitemvalue: mitem,
      descriptionvalue: description,
      itemactionname: 'Edit ' + this.state.itemasubonename,
    });
  };

  handleWDtime = (event, values) => {
    event.preventDefault();
    console.log('values', values);



  };

  handleSelectMitemChange = (selectedOption) => {
    let mitemvalue = selectedOption.value;
    this.setState({ mitemvalue: mitemvalue });
  };

  render() {
    const { timetemplate } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.state.itempageabout} </title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title={this.state.itemmainname} breadcrumbItem={this.state.itemsubname} />
            <AvForm
              className="needs-validation"
              onValidSubmit={this.handleWDtime}
            >
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">Name: {timetemplate.itemname}

                      </CardTitle>
                      <p className="card-title-desc">Description: {timetemplate.description}</p>


                    </CardBody>
                  </Card>
                </Col>

                {this.state.tttdays &&
                  this.state.tttdays.map((tttday, dayindex) => {
                    var dayitems = tttday.dayitems;
                    return (
                      <Col className="col-12" key={dayindex}>
                        <Card>
                          <CardBody>
                            <CardTitle className="h4">{tttday.weekday}
                              <Button
                                className="float-end btn-sm"
                                color="success"
                                onClick={this.handleAddTimeName.bind(this, tttday.tttdayid, tttday.weekday)}
                              >
                                +
								</Button>
                            </CardTitle>


                            <div className="table-responsive">
                              <Table className="table mb-0">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>
                                      <Row>
                                        <div className="col-3">Time Name</div>
                                        <div className="col-3">Start Time</div>
                                        <div className="col-3">End Time</div>
                                      </Row>
                                    </th>

                                  </tr>
                                </thead>
                                <tbody>
                                  {dayitems &&
                                    dayitems.map((daytime, timeindex) => {
                                      let rowid = timeindex;
                                      let stime = 'stime' + dayindex + '' + timeindex;
                                      let etime = 'etime' + dayindex + '' + timeindex;
                                      return (
                                        <tr key={timeindex}>
                                          <th scope="row">{rowid + 1}</th>
                                          <td>

                                            <Row>
                                              <div className="col-3">
                                                <div className="form-group">
                                                  <AvField type="select" name={'tname' + dayindex + '' + timeindex} required>
                                                    <option value=''>Select</option>
                                                    {this.state.wdtimenamess && this.state.wdtimenamess.map((wdaytime, index) => {
                                                      return (
                                                        <option key={index} value={wdaytime.id}>{wdaytime.name}</option>
                                                      )
                                                    })}
                                                  </AvField>
                                                </div>
                                              </div>

                                              <div className="col-3">
                                                <div className="form-group">
                                                  <Flatpickr
                                                    className="form-control d-block"
                                                    name={stime}
                                                    value={date}
                                                    options={{
                                                      enableTime: true,
                                                      noCalendar: true,
                                                      dateFormat: "H:i",
                                                      minTime: "7:00",
                                                      maxTime: "18:30",
                                                      defaultDate: this.state.defaultitemdatevalue,
                                                      onClose: function (selectedDates, dateStr, instance) {
                                                        console.log('selectedDates', selectedDates);
                                                        console.log('dateStr', dateStr);
                                                        console.log('instance', instance);
                                                        // localStorage.setItem('itemdate', dateStr);

                                                      }
                                                    }}
                                                    onChange={date => {
                                                      this.setState({ [stime]: date });
                                                    }}
                                                  // value={this.state.itemdate} 
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-3">
                                                <div className="form-group">
                                                  <Flatpickr
                                                    className="form-control d-block"
                                                    name="etime"
                                                    options={{
                                                      enableTime: true,
                                                      noCalendar: true,
                                                      dateFormat: "H:i",
                                                      minTime: "7:00",
                                                      maxTime: "18:30",
                                                      defaultDate: this.state.defaultitemdatevalue,
                                                      onClose: function (selectedDates, dateStr, instance) {
                                                        console.log('selectedDates', selectedDates);
                                                        console.log('dateStr', dateStr);
                                                        console.log('instance', instance);
                                                        // localStorage.setItem('itemdate', dateStr);
                                                      }
                                                    }}
                                                  // value={this.state.itemdate} 
                                                  />
                                                </div>
                                              </div>



                                              <div className="col-2">

                                                <Button
                                                  className="btn-sm btn-danger xmart0"
                                                  onClick={this.handleDkey.bind(
                                                    this,
                                                    daytime.id,
                                                    daytime.timename,
                                                  )}
                                                >
                                                  <i className="bx bx-trash font-size-16 align-middle ms-1" />
                                                </Button>

                                              </div>
                                            </Row>


                                          </td>



                                        </tr>
                                      );
                                    })}

                                </tbody>
                              </Table>
                            </div>

                          </CardBody>
                        </Card>
                      </Col>
                    )
                  })}

              </Row>
              <div className="col-12">
                <Row>
                  <div className="col-8">

                    {' '}
                  </div>
                  <div className="col-4">

                    <Button
                      className="btn-sm btn-success xmart0"
                      type="submit" style={{ marginTop: '18px' }}
                    >
                      + Submit Time Table Template
                                </Button>
                  </div>
                </Row>
              </div>
            </AvForm>
          </div>
          <Modal
            isOpen={this.state.addKeyModal}

            centered={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">{this.state.itemactionname} </h5>
              <button
                type="button"
                onClick={this.handleCkey}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">


              <AvForm
                className="needs-validation"
                onValidSubmit={this.handleValidSubmit}
              >
                {this.state.showAlert ? (
                  <Alert color={this.state.alertType}>
                    <p>{this.state.alertText}</p>
                  </Alert>
                ) : null}



                <div className="mt-4 d-grid">
                  <button
                    className="btn btn-primary btn-block waves-effect waves-light"
                    type="submit"
                  >
                    {this.state.itemactionname}
                  </button>
                </div>


              </AvForm>
            </div>
          </Modal>

        </div>
      </React.Fragment >
    )
  }
}

export default TimeTable
