import React, { Component } from "react"
import { Alert, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Modal, Button, Table } from "reactstrap";

import MetaTags from 'react-meta-tags';
import axios from 'axios';
// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"
import { USR_URL } from "../../helpers/url_helper";
// import SweetAlert from "react-bootstrap-sweetalert"
import Switch from 'react-switch';
import Select from 'react-select';
import swal from 'sweetalert';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../components/Common/datatables.scss"
const initialstate = {
  itempageabout: 'User Management | Management',
  itemmainname: 'User Role',
  itemsubname: 'User Management',
  itemsubonename: 'Email to Login',
  mitemsubonename: 'User Role',
  itemactionname: 'Add Email to Login',
  tname: 'u',
  tdepname: 'dummy',
  itemvalue: '',
  userrole: '',
  name: '',
  mobile: '',
  role: 'all',
  select_mitems: [
    { value: '', label: (<div>Select User Role</div>), },
    { value: 'admin', label: (<div>Admin</div>) },
    { value: 'coadmin', label: (<div>Schools-Admin</div>) },
    { value: 'operator', label: (<div>Operator</div>) },
    { value: 'librarian', label: (<div>Librarian</div>) },
    { value: 'principal', label: (<div>Principal</div>) },
    { value: 'vprincipal', label: (<div>Vice Principal</div>) },
    { value: 'accountant', label: (<div>Accountant</div>) },
    { value: 'hod', label: (<div>HOD</div>) },
    { value: 'hr', label: (<div>HR</div>) },
    { value: 'transmanager', label: (<div>Transport Manager</div>) },
    { value: 'hostelwarden', label: (<div>Hostel Warden</div>) },
    { value: 'frontdesk', label: (<div>Front Desk</div>) },
    { value: 'gatekeeper', label: (<div>Gate Keeper</div>) },
    { value: 'teacher', label: (<div>Teacher</div>) },
    { value: 'parent', label: (<div>Parent</div>) },
    { value: 'student', label: (<div>Student</div>) },
  ],
  allitems: [],
  allmitems: [],
  relateditems: [],
  showAlert: false,
  alertType: '',
  alertText: '',
  keyId: 0,
  keyName: '',
  oldkeyname: '',
  newkeyname: '',
  noorid: 0,

  addKeyModal: false,
  akeys: 0,
  bkeys: 0,
  tkeys: 0,
};
class UserMng extends Component {
  constructor(props) {
    super(props)
    // this.state = {}
    this.handleGetkeys = this.handleGetkeys.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleDkey = this.handleDkey.bind(this);
    this.handleTkey = this.handleTkey.bind(this);
    this.handleEditkey = this.handleEditkey.bind(this);

  }
  state = { ...initialstate };
  componentDidMount() {

    this.handleGetkeys();


  }

  handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name + ' ' + value);
    this.setState({ [name]: value });
  };
  handleGetkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
      table: this.state.tname,
      deptable: this.state.tdepname,
      role: this.state.role,
    };
    const getuurl = USR_URL + 'getusers';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);
          const allitems = [...response.data.details];
          console.log('allitems', response.data.details);


          let akeys = 0;

          let tkeys = 0;
          allitems.map((dkey) => {
            akeys = akeys + dkey.status;
            tkeys = tkeys + 1;

          });

          this.setState({
            akeys: akeys,
            bkeys: tkeys - akeys,
            tkeys: tkeys,
            relateditems: allitems,
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        // localStorage.clear();
        //this.props.history.push('/'); //eslint-disable-line
      });
  };

  handleValidSubmit = (event, values) => {
    event.preventDefault();
    console.log('values', values);
    if (this.state.userrole == '') {
      this.setState({
        showAlert: true,
        alertType: 'warning',
        alertText: 'Select ' + this.state.mitemsubonename,
      });
    } else {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: localStorage.token,
      };
      var addkeyurl = USR_URL + 'adduser';
      var postData = {
        item: values.item,
        password: values.password,
        name: values.name,
        mobile: values.mobile,
        role: this.state.userrole,
        uid: localStorage.usercode,
        table: this.state.tname,

      };
      if (this.state.editmode) {
        addkeyurl = USR_URL + 'edituser';
        postData = {
          itemid: this.state.editid,
          item: values.item,
          password: values.password,
          name: values.name,
          mobile: values.mobile,
          role: this.state.userrole,
          uid: localStorage.usercode,
          table: this.state.tname,

        };
      }
      axios
        .post(addkeyurl, postData, {
          headers: headers,
        })
        .then((response) => {
          console.log(response.data.message);
          if (response.data.error) {
            this.setState({
              showAlert: true,
              alertType: 'warning',
              alertText: response.data.message,
            });
          } else {
            localStorage.setItem("token", response.data.token);
            this.handleGetkeys();
            this.setState(
              {
                addKeyModal: false,
              });
            swal(values.item + ' ' + response.data.message, {
              icon: 'success',
            });
          }
        })
        .catch((error) => {
          console.log('Error is ', error);
          this.setState({
            alertType: 'danger',
            showAlert: true,
            alertText: 'Failed Try Again Later!',
          });
        });

    }
  };

  handleDkey = (id, item, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'id');
    console.log(item + 'item');

    swal({
      title: 'Delete ' + item,
      text: 'Are you sure?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((keyconfirm) => {
      if (keyconfirm) {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: localStorage.token,
        };

        const delkeyurl = USR_URL + 'delitem';
        var postData = {
          keyid: id,
          uid: localStorage.usercode,
          table: this.state.tname,
        };
        axios
          .post(delkeyurl, postData, {
            headers: headers,
          })
          .then((response) => {
            console.log(response.data.message);

            if (response.data.error) {
              swal(item + ' ' + response.data.message, {
                icon: 'warning',
              });
            } else {
              localStorage.setItem("token", response.data.token);
              this.handleGetkeys();
              swal(item + ' ' + response.data.message, {
                icon: 'success',
              });
            }
          })
          .catch((error) => {
            console.log('Error is ', error);
            swal('Try Again Later', {
              icon: 'warning',
            });
          });
      }
    });
  };
  handleTkey = (id, item, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'id');
    console.log(item + 'item');
    var keysts = checked == true ? 1 : 0;
    var kstatus = 'Deactivate';
    if (checked) {
      kstatus = 'Activate';
    }
    swal({
      title: kstatus + ' ' + item,
      text: 'Are you sure?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((keyconfirm) => {
      if (keyconfirm) {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: localStorage.token,
        };

        const togkeyurl = USR_URL + 'togitem';
        var postData = {
          keyid: id,
          kstatus: keysts,
          uid: localStorage.usercode,
          table: this.state.tname,

        };
        axios
          .post(togkeyurl, postData, {
            headers: headers,
          })
          .then((response) => {
            console.log(response.data.message);

            if (response.data.error) {
              swal(item + ' ' + response.data.message, {
                icon: 'warning',
              });
            } else {
              localStorage.setItem("token", response.data.token);
              this.handleGetkeys();
              swal(item + ' ' + response.data.message, {
                icon: 'success',
              });
            }
          })
          .catch((error) => {
            console.log('Error is ', error);
            swal('Try Again Later', {
              icon: 'Danger',
            });
          });
      }
    });
  };

  handleAkey = () => {
    this.setState({
      addKeyModal: true,
      itemactionname: 'Add ' + this.state.itemsubonename,
      editmode: false,
      itemvalue: '',
      userrole: '',
      name: '',
      mobile: '',
      password: '',
    });
  };
  handleCkey = () => {
    this.setState({
      addKeyModal: false,
      editmode: false,
      itemvalue: '',
      userrole: '',
      name: '',
      mobile: '',
      password: '',

    });
  };
  handleEditkey = (id, item, role, name, mobile, password, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'id');
    console.log(item + 'item');
    console.log(role + 'role');
    console.log(name + 'name');
    console.log(mobile + 'mobile');
    this.setState({
      addKeyModal: true,
      editmode: true,
      editid: id,
      itemvalue: item,
      userrole: role,
      name: name,
      mobile: mobile,
      password: password,
      itemactionname: 'Edit ' + this.state.itemsubonename,
    });
  };

  handleSelectMitemChange = (selectedOption) => {
    let userrole = selectedOption.value;
    this.setState({ userrole: userrole });
  };

  render() {

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.state.itempageabout} </title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title={this.state.itemmainname} breadcrumbItem={this.state.itemsubname} />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">{this.state.itemsubname}
                      <Button
                        className="float-end btn-sm"
                        color="success"
                        onClick={this.handleAkey}
                      >
                        +
                      </Button>
                    </CardTitle>
                    <p className="card-title-desc">Some Description</p>


                  </CardBody>
                </Card>
              </Col>

              <Col className="col-12">
                <Card>
                  <CardBody>




                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Role</th>
                            <th>Name</th>
                            <th>{this.state.itemsubonename}</th>
                            <th>Mobile</th>
                            <th>Password</th>
                            <th>date Added</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.relateditems &&
                            this.state.relateditems.map((item, index) => {


                              return (
                                <tr key={index}>
                                  <th scope="row">{index + 1}</th>
                                  <td>
                                    {item.role}
                                  </td>
                                  <td>
                                    <span className="float-start"
                                      style={{ marginRight: '10px', cursor: 'pointer' }}
                                      onClick={this.handleEditkey.bind(
                                        this,
                                        item.id,
                                        item.email,
                                        item.role,
                                        item.itemname,
                                        item.mobile,
                                        item.domain,
                                      )}
                                    >
                                      <i className="mdi mdi-pencil d-block font-size-16"></i>{" "}{" "}
                                    </span>
                                    <strong>{" "}{item.itemname}</strong>
                                  </td>
                                  <td>{item.email}</td>
                                  <td>{item.mobile}</td>
                                  <td>
                                    {item.domain}
                                  </td>

                                  <td>
                                    {item.posted}
                                  </td>

                                </tr>
                              );
                            })}

                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>

            </Row>
          </div>
          <Modal
            isOpen={this.state.addKeyModal}

            centered={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">{this.state.itemactionname} </h5>
              <button
                type="button"
                onClick={this.handleCkey}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">


              <AvForm
                className="needs-validation"
                onValidSubmit={this.handleValidSubmit}
              >
                {this.state.showAlert ? (
                  <Alert color={this.state.alertType}>
                    <p>{this.state.alertText}</p>
                  </Alert>
                ) : null}

                <div className="mb-3">

                  <div className="form__form-group">
                    <span className="form__form-group-label">{this.state.mitemsubonename} </span>
                    <div className="form__form-group-field" style={{ height: '30px' }}>

                      <Select
                        name="mitem"
                        value={this.state.select_mitems.filter(
                          (option) => option.value == this.state.userrole,
                        )}
                        onChange={this.handleSelectMitemChange}
                        options={this.state.select_mitems}
                        clearable={false}
                        className="react-select-team"
                        classNamePrefix="react-select"
                      // defaultMenuIsOpen={true}
                      // isDisabled={true}
                      />
                    </div>
                  </div>

                </div>
                <div className="mb-3">
                  <AvField
                    name="item"
                    label={this.state.itemsubonename}
                    value={this.state.itemvalue}
                    type="email"
                    required
                    placeholder={'Enter ' + this.state.itemsubonename}
                  />
                </div>

                <div className="mb-3">
                  <AvField
                    name="password"
                    label="Password"
                    value={this.state.password}
                    type="password"
                    required
                    placeholder="Enter Password"
                  />
                </div>

                <div className="mb-3">
                  <AvField
                    name="name"
                    label="Name"
                    value={this.state.name}
                    type="text"
                    required
                    placeholder={'Enter Name'}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    name="mobile"
                    label="Mobile"
                    value={this.state.mobile}
                    type="number"
                    required
                    placeholder={'Enter Mobile'}
                  />
                </div>


                <div className="mt-4 d-grid">
                  <button
                    className="btn btn-primary btn-block waves-effect waves-light"
                    type="submit"
                  >
                    {this.state.itemactionname}
                  </button>
                </div>


              </AvForm>
            </div>
          </Modal>
        </div>
      </React.Fragment>
    )
  }
}

export default UserMng
