import React, { Component } from "react"
import {
  Container, Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Row,
  Label,
  Input,
  Button
} from "reactstrap"

class Dashboard extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <h3>Admin Dashboard</h3>

            <Card>
              <CardBody>
                <CardTitle className="h4">Title text</CardTitle>
                <p className="card-title-desc">
                  Some text
                </p>

                {/* <div className="mb-3 row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                  >
                    Academic Year
            </label>
                  <div className="col-md-10">
                    <input
                      className="form-control"
                      type="text"
                      defaultValue="Artisanal kale"
                    />
                  </div>
                </div>
                 <div className="mb-3 row">
                  <label className="col-md-2 col-form-label">Academic year  no dep</label>
                  <div className="col-md-10">
                    <select className="form-select">
                      <option>Select</option>
                      <option>2020-2021</option>
                      <option>2021-2022</option>
                    </select>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-md-2 col-form-label">Board  no dep</label>
                  <div className="col-md-10">
                    <select className="form-select">
                      <option>Select</option>
                      <option>CBSC</option>
                      <option>SSC</option>
                    </select>
                  </div>
                </div>

                <div className="mb-3 row">
                  <label className="col-md-2 col-form-label">Class Category</label>
                  <div className="col-md-10">
                    <select className="form-select">
                      <option>Select</option>
                      <option>Primary</option>
                      <option>Upper Primary</option>
                    </select>
                  </div>
                </div>

                <div className="mb-3 row">
                  <label className="col-md-2 col-form-label">Class on dep class category</label>
                  <div className="col-md-10">
                    <select className="form-select">
                      <option>Select</option>
                      <option>class 1</option>
                      <option>class 2</option>
                    </select>
                  </div>
                </div>

                <div className="mb-3 row">
                  <label className="col-md-2 col-form-label">Subjects no dep</label>
                  <div className="col-md-10">
                    <select className="form-select">
                      <option>Select</option>
                      <option>English</option>
                      <option>Maths</option>
                    </select>
                  </div>
                </div>

                <div className="mb-3 row">
                  <label className="col-md-2 col-form-label">Class room no dep</label>
                  <div className="col-md-10">
                    <select className="form-select">
                      <option>Select</option>
                      <option>Room1</option>
                      <option>Room2</option>
                    </select>
                  </div>
                </div>

                <div className="mb-3 row">
                  <label className="col-md-2 col-form-label">Sections no dep</label>
                  <div className="col-md-10">
                    <select className="form-select">
                      <option>Select</option>
                      <option>Section1</option>
                      <option>Section2</option>
                    </select>
                  </div>
                </div> */}


              </CardBody>
            </Card>


          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Dashboard;
