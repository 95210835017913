import React, { Component } from "react"
import { Alert, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Modal, Button, Table } from "reactstrap";

import MetaTags from 'react-meta-tags';
import axios from 'axios';
// availity-reactstrap-validation

import { SCH_URL } from "../../../helpers/url_helper";
// import SweetAlert from "react-bootstrap-sweetalert"

import swal from 'sweetalert';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "../../../components/Common/datatables.scss"
const initialstate = {
  itempageabout: 'Branches | ',
  itemmainname: localStorage.schname,
  itemsubname: ' Branches',
  itemsubonename: 'Branch',
  itemactionname: 'Add Branch',
  tname: 'schbran',
  tdepname: 'schmedim',
  tmdepname: 'schnme',
  itemvalue: '',
  allitems: [],
  relateditems: [],
  showAlert: false,
  alertType: '',
  alertText: '',
  keyId: 0,
  keyName: '',
  oldkeyname: '',
  newkeyname: '',
  noorid: 0,

  addKeyModal: false,
  akeys: 0,
  bkeys: 0,
  tkeys: 0,

};
class SchoolStructures extends Component {
  constructor(props) {
    super(props)
    // this.state = {}
    this.handleGetkeys = this.handleGetkeys.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleDkey = this.handleDkey.bind(this);
    this.handleTkey = this.handleTkey.bind(this);
    this.handleEditkey = this.handleEditkey.bind(this);
    localStorage.setItem('schbranid', '');
    localStorage.setItem('schbranname', '');
  }
  state = { ...initialstate };
  componentDidMount() {
    if (localStorage.schname == '') {
      this.props.history.push('/addschool'); //eslint-disable-line
    }
    this.handleGetkeys();


  }

  handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name + ' ' + value);
    this.setState({ [name]: value });
  };
  handleGetkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
      mitem: localStorage.schid,
    };
    const getuurl = SCH_URL + 'getschstats';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);
          const allitems = [...response.data.allitems];
          console.log('allitems', response.data.allitems);
          const allacas = [...response.data.acastructures];
          let akeys = 0;

          let tkeys = 0;
          allitems.map((dkey) => {
            akeys = akeys + dkey.status;
            tkeys = tkeys + 1;
          });

          this.setState({
            akeys: akeys,
            bkeys: tkeys - akeys,
            tkeys: tkeys,
            allitems: response.data.allitems,
            schbranches: response.data.allitems,
            allacas: response.data.allacas,

          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        // localStorage.clear();
        this.props.history.push('/'); //eslint-disable-line
      });
  };

  handleValidSubmit = (event, values) => {
    event.preventDefault();
    console.log('values', values);
    console.log('this.state.wheretodo', this.state.wheretodo);
    var postData = {
      uid: localStorage.usercode,

    };
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var addkeyurl = SCH_URL + 'addmitem';
    if (this.state.wheretodo == 0) {
      this.setState({
        showAlert: true,
        alertType: 'warning',
        alertText: 'Invalid Request ',
      });
    } else {


      if (this.state.editmode) {
        addkeyurl = SCH_URL + 'editmitem';
        postData = {
          itemid: this.state.editid,
          item: values.item,
          mitem: localStorage.schid,
          uid: localStorage.usercode,
          table: this.state.tname,
          mtable: this.state.tmdepname,

        };
      } else {
        if (this.state.wheretodo == 1) {
          postData = {
            item: values.item,
            mitem: localStorage.schid,
            uid: localStorage.usercode,
            table: this.state.tname,
            mtable: this.state.tmdepname,

          };
        } else if (this.state.wheretodo == 2) {
          postData = {
            uid: localStorage.usercode,
            item: values.item,
            mitem: this.state.mitemvalue,
            mmitem: this.state.mmitemvalue,
            table: 'schmedim',
            mtable: 'schbran',
            mmtable: 'schnme',

          };
          addkeyurl = SCH_URL + 'addmmitem';
        }
      }





      axios
        .post(addkeyurl, postData, {
          headers: headers,
        })
        .then((response) => {
          console.log(response.data.message);
          if (response.data.error) {
            this.setState({
              showAlert: true,
              alertType: 'warning',
              alertText: response.data.message,
            });
          } else {
            localStorage.setItem("token", response.data.token);
            this.handleGetkeys();
            this.setState(
              {
                addKeyModal: false,
              });
            swal(values.item + ' ' + response.data.message, {
              icon: 'success',
            });
          }
        })
        .catch((error) => {
          console.log('Error is ', error);
          this.setState({
            alertType: 'danger',
            showAlert: true,
            alertText: 'Failed Try Again Later!',
          });
        });

    }
  };



  handleValidBoardSubmit = (event, values) => {
    event.preventDefault();
    console.log('values', values);
    if (this.state.mitemvalue == '') {
      this.setState({
        showAlert: true,
        alertType: 'warning',
        alertText: 'Select ' + this.state.mitemsubonename,
      });
    } else if (values.acastructurevalue.length == 0) {
      this.setState({
        showAlert: true,
        alertType: 'danger',
        alertText: 'Select Categories',
      });
    } else {

      var acastructurevalue = JSON.stringify(values.acastructurevalue);
      const headers = {
        'Content-Type': 'application/json',
        Authorization: localStorage.token,
      };
      var addkeyurl = SCH_URL + 'linkschaca';
      var postData = {
        item: acastructurevalue,
        mitem: this.state.mitemvalue,
        mmitem: this.state.mmitemvalue,
        mmmitem: localStorage.schid,
        uid: localStorage.usercode,
        table: 'schstruc',
        mtable: 'schmedim',
        mmtable: 'schbran',
        mmmtable: 'schnme',
      };

      axios
        .post(addkeyurl, postData, {
          headers: headers,
        })
        .then((response) => {
          console.log(response.data.message);
          if (response.data.error) {
            this.setState({
              showAlert: true,
              alertType: 'warning',
              alertText: response.data.message,
            });
          } else {
            localStorage.setItem("token", response.data.token);
            this.handleGetkeys();
            this.setState(
              {
                addKeyModal: false,
                activeone: false,
              });
            swal('Board Structure ' + response.data.message, {
              icon: 'success',
            });
          }
        })
        .catch((error) => {
          console.log('Error is ', error);
          this.setState({
            alertType: 'danger',
            showAlert: true,
            alertText: 'Failed Try Again Later!',
          });
        });

    }
  };


  handleDkey = (id, item, tname, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'id');
    console.log(item + 'item');

    swal({
      title: 'Delete ' + item,
      text: 'Are you sure?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((keyconfirm) => {
      if (keyconfirm) {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: localStorage.token,
        };

        const delkeyurl = SCH_URL + 'delitem';
        var postData = {
          keyid: id,
          uid: localStorage.usercode,
          table: tname,
        };
        axios
          .post(delkeyurl, postData, {
            headers: headers,
          })
          .then((response) => {
            console.log(response.data.message);

            if (response.data.error) {
              swal(item + ' ' + response.data.message, {
                icon: 'warning',
              });
            } else {
              localStorage.setItem("token", response.data.token);
              this.handleGetkeys();
              swal(item + ' ' + response.data.message, {
                icon: 'success',
              });
            }
          })
          .catch((error) => {
            console.log('Error is ', error);
            swal('Try Again Later', {
              icon: 'warning',
            });
          });
      }
    });
  };
  handleTkey = (id, item, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'id');
    console.log(item + 'item');
    var keysts = checked == true ? 1 : 0;
    var kstatus = 'Deactivate';
    if (checked) {
      kstatus = 'Activate';
    }
    swal({
      title: kstatus + ' ' + item,
      text: 'Are you sure?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((keyconfirm) => {
      if (keyconfirm) {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: localStorage.token,
        };

        const togkeyurl = SCH_URL + 'togitem';
        var postData = {
          keyid: id,
          kstatus: keysts,
          uid: localStorage.usercode,
          table: this.state.tname,

        };
        axios
          .post(togkeyurl, postData, {
            headers: headers,
          })
          .then((response) => {
            console.log(response.data.message);

            if (response.data.error) {
              swal(item + ' ' + response.data.message, {
                icon: 'warning',
              });
            } else {
              localStorage.setItem("token", response.data.token);
              this.handleGetkeys();
              swal(item + ' ' + response.data.message, {
                icon: 'success',
              });
            }
          })
          .catch((error) => {
            console.log('Error is ', error);
            swal('Try Again Later', {
              icon: 'Danger',
            });
          });
      }
    });
  };

  handleAkey = (whattodo, step, mitemvalue, mmitemvalue, mmmitemvalue, mitemvaluename) => {
    console.log('whattodo', whattodo);
    console.log('step', step);
    console.log('mitemvalue', mitemvalue);
    console.log('mmitemvalue', mmitemvalue);
    console.log('mmmitemvalue', mmmitemvalue);
    console.log('mitemvaluename', mitemvaluename);

    this.setState({
      addKeyModal: true,
      activeone: false,
      itemactionname: 'Add ' + whattodo + ' to ' + mitemvaluename,
      itemsubonename: whattodo,
      mitemsubonename: whattodo,
      editmode: false,
      itemvalue: '',
      wheretodo: step,
      mitemvalue: mitemvalue,
      mmitemvalue: mmitemvalue,
      mmmitemvalue: mmmitemvalue,
    });
  };
  handleCkey = () => {
    this.setState({
      addKeyModal: false,
      activeone: false,
      editmode: false,
      itemvalue: '',
      wheretodo: 0,
      mitemvalue: '',
      mmitemvalue: '',
      mmmitemvalue: '',

    });
  };


  handleAddBoard = (mitemvalue, mitemvaluename, mmitemvalue, mmitemvaluename, racas, event) => {
    console.log('medid', mitemvalue);
    var allacas = [];

    if (racas.length >= 1) {
      allacas = racas;
    } else {
      allacas = this.state.allacas;
    }

    this.setState({
      showAlert: false,
      activeone: true,
      addKeyModal: true,
      allacas: allacas,
      itemactionname: 'Assign Board to ' + mitemvaluename + ' of  ' + mmitemvaluename + ' Branch',
      mitemvalue: mitemvalue,
      mmitemvalue: mmitemvalue,
    });
  };
  handleEditkey = (id, item, step, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'id');
    console.log(item + 'item');
    this.setState({
      addKeyModal: true,
      editmode: true,
      editid: id,
      itemvalue: item,
      itemactionname: 'Edit ' + this.state.itemsubonename,
      wheretodo: step,
    });
  };
  render() {

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.state.itempageabout} {localStorage.schname}  </title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title={localStorage.schname} breadcrumbItem={localStorage.schname + this.state.itemsubname} />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">{localStorage.schname + this.state.itemsubname}
                      <Button
                        className="float-end btn-sm"
                        color="success"
                        onClick={() => {
                          this.props.history.push('/managestructure'); //eslint-disable-line
                        }}
                      >
                        Manage Structure
								</Button>
                    </CardTitle>
                    <p className="card-title-desc onclicklink" onClick={() => {
                      this.props.history.push('/addschool'); //eslint-disable-line
                    }}>Back to Schools</p>

                    {this.state.schbranches &&
                      this.state.schbranches.map((schbranch, index) => {

                        return (
                          <div className="col-md-12" key={index}>
                            <Card className="catcard">
                              <CardBody>
                                <CardTitle className="h4">{schbranch.schbranch.itemname}


                                </CardTitle>

                              </CardBody>
                            </Card>


                            {schbranch.allschbranchmeds.length >= 1 ? (

                              <>
                                {schbranch.allschbranchmeds && schbranch.allschbranchmeds.map((schbranchmed, index) => {


                                  return (
                                    <Card key={index}>
                                      <CardBody>
                                        <div className="row">
                                          <div className="col-md-2">
                                            <span className="as-class clearfix"><i className="mdi mdi-chevron-right" />  {schbranchmed.schbranmed.itemname}




                                            </span>
                                          </div>
                                          <div className="col-md-10">
                                            <>
                                              {schbranchmed.schscrucdtls && schbranchmed.schscrucdtls.map((schscruc, index) => {
                                                return (

                                                  <span key={index} className="as-subject"><i className="mdi mdi-chevron-double-right" />  {schscruc.structurename} </span>


                                                )
                                              })}
                                            </>
                                            {schbranchmed.schscrucdtls.length >= 1 ? null : "No Assigned Board"}


                                          </div>
                                        </div>
                                      </CardBody>
                                    </Card>
                                  )
                                })}
                              </>

                            ) : (
                              <Card>
                                <CardBody>
                                  <div className="row">
                                    <div className="col-md-2">
                                      No Medium

                                    </div>
                                    <div className="col-md-10">Add Medium to Assign Board</div>
                                  </div>
                                </CardBody>
                              </Card>

                            )}



                          </div>
                        )
                      })}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>

        </div>
      </React.Fragment>
    )
  }
}

export default SchoolStructures
