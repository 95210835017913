import React, { Component } from "react"
import {
  Alert, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Modal, Button, Table, FormGroup, Input, Label, Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  Collapse,
} from "reactstrap";
import classnames from "classnames"
import MetaTags from 'react-meta-tags';
import axios from 'axios';
// availity-reactstrap-validation
import { AvField, AvForm, AvGroup, AvInput, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import { ACA_URL } from "../../helpers/url_helper";
// import SweetAlert from "react-bootstrap-sweetalert"
import Switch from 'react-switch';
import Select from 'react-select';
import swal from 'sweetalert';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../components/Common/datatables.scss"
const initialstate = {
  customActiveTab: 1,
  itempageabout: 'Manage Academic Structure | ',

  itemsubname: 'Manage Academic Structure',
  itemsubonename: 'Structure',
  itemactionname: 'Add Structure',
  tname: 'astruc',
  tdepname: 'schstruc',

  aca_str: '',
  boardvalue: '',
  boardnamevalue: '',
  categoryvalue: [],
  rboards: [],
  rcats: [],
  rcls: [],
  rsubs: [],

  activemngcats: '',
  activemngcls: '',
  activemngsubs: '',

  aclassvalue: '',
  subjectvalue: '',
  branchvaluename: '',
  academicyearvaluename: '',
  boardvaluename: '',
  categoryvaluename: '',
  aclassvaluename: '',
  subjectvaluename: '',
  selectedclass: '',
  classesselected: '',
  activeone: true,
  activetwo: false,
  activethree: false,
  activefour: false,
  allitems: [],
  relateditems: [],
  showAlert: false,
  alertType: '',
  alertText: '',
  keyId: 0,
  keyName: '',
  oldkeyname: '',
  newkeyname: '',
  noorid: 0,

  addKeyModal: false,
  akeys: 0,
  bkeys: 0,
  tkeys: 0,
  select_bitems: [{
    value: '',
    label: (<div>
      Select Board
    </div>
    ),
  }],


};
class AcademicStructure extends Component {
  constructor(props) {
    super(props)
    // this.state = {}

    this.handleGetkeys = this.handleGetkeys.bind(this);
    this.handleAddBoard = this.handleAddBoard.bind(this);
    this.handleAddBoardCats = this.handleAddBoardCats.bind(this);
    this.handleAddCatsCls = this.handleAddCatsCls.bind(this);
    this.handleRemoveCatsCls = this.handleRemoveCatsCls.bind(this);
    this.handleAddClsSubs = this.handleAddClsSubs.bind(this);
    this.handleRemoveClsSubs = this.handleRemoveClsSubs.bind(this);

    this.toggleCustom = this.toggleCustom.bind(this);

    this.manageCats = this.manageCats.bind(this);
    this.manageCls = this.manageCls.bind(this);
    this.manageSubs = this.manageSubs.bind(this);

  }
  state = { ...initialstate };
  componentDidMount() {
    if (localStorage.boardid) {
      this.handleGetkeys();

    } else {
      this.props.history.push('/academicstructureboards'); //eslint-disable-line
    }



  }
  toggleCustom(tab) {
    if (this.state.customActiveTab !== tab) {
      this.setState({
        customActiveTab: tab,
      })
    }
  }
  handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name + ' ' + value);
    this.setState({ [name]: value });
  };
  handleGetkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
      mitem: localStorage.boardid,
    };
    const getuurl = ACA_URL + 'getboardacademicstats';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);
          const allitems = [...response.data.allitems];
          console.log('allitems', response.data.allitems);

          const allselect_bitems = [...response.data.rboards];

          let akeys = 0;

          let tkeys = 0;
          allitems.map((dkey) => {
            akeys = akeys + dkey.status;
            tkeys = tkeys + 1;
          });

          this.setState({
            akeys: akeys,
            bkeys: tkeys - akeys,
            tkeys: tkeys,
            rboards: response.data.rboards,
            acats: response.data.acats,
            acls: response.data.acls,
            asubs: response.data.asubs,
            allitems: response.data.allitems,
            relateditems: response.data.allitems,

            select_bitems: [{
              value: '',
              label: (<div>
                Select Board
              </div>
              ),
            }],

          }, () => {
            if (allselect_bitems.length >= 1) {
              allselect_bitems.map((item) => {
                this.state.select_bitems.push({
                  value: item.id,
                  label: (
                    <div>
                      {item.name}
                    </div>
                  ),
                });
              });
            }
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        // localStorage.clear();
        //this.props.history.push('/'); //eslint-disable-line
      });
  };

  handleSelectboardChange = (selectedOption) => {
    let boardvalue = selectedOption.value;
    this.setState({ boardvalue: boardvalue, boardvaluename: selectedOption.label.props.children, });
  };

  handleAddBoard = (event, values) => {
    event.preventDefault();
    console.log('values', values);
    //  item academicyearvalue boardvalue categoryvalue  
    if (this.state.boardvalue == '') {
      this.setState({
        showAlert: true,
        alertType: 'danger',
        alertText: 'Select Board',
      });
    } else {

      this.setState({
        showAlert: false,

        boardvalue: this.state.boardvalue,
        rcats: this.state.acats,
        activeone: true,
        activemngcls: false,
        activemngsubs: false,
        addKeyModal: true,
        itemactionname: 'Add ' + this.state.itemsubonename,

      });

    }
  };
  handleAddBoardCats = (event, values) => {
    event.preventDefault();
    console.log('values', values);
    if (this.state.boardvalue == '') {
      this.setState({
        showAlert: true,
        alertType: 'danger',
        alertText: 'Select Board',
      });
    } else if (values.categoryvalue.length == 0) {
      this.setState({
        showAlert: true,
        alertType: 'danger',
        alertText: 'Select Categories',
      });
    } else {

      var categoryvalue = JSON.stringify(values.categoryvalue);
      const headers = {
        'Content-Type': 'application/json',
        Authorization: localStorage.token,
      };
      var addkeyurl = ACA_URL + 'addmstructure';
      var postData = {

        boardvalue: this.state.boardvalue,
        categoryvalue: categoryvalue,
        branchvaluename: this.state.branchvaluename,
        academicyearvaluename: this.state.academicyearvaluename,
        boardvaluename: this.state.boardvaluename,
        uid: localStorage.usercode,
        table: this.state.tname,

      };

      axios
        .post(addkeyurl, postData, {
          headers: headers,
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error) {
            this.setState({
              showAlert: true,
              alertType: 'warning',
              alertText: response.data.message,
            });
          } else {
            localStorage.setItem("token", response.data.token);
            this.handleGetkeys();
            this.setState(
              {
                addKeyModal: false,
                showAlert: false,
              });
            swal(response.data.message, {
              icon: 'success',
            });
          }
        })
        .catch((error) => {
          console.log('Error is ', error);
          this.setState({
            alertType: 'danger',
            showAlert: true,
            alertText: 'Failed Try Again Later!',
          });
        });
    }
  };





  handleMngSubject = (acaid, mclid, mclname, e) => {
    this.setState({
      addKeyModal: true,
      itemactionname: 'Add Subject to ' + mclname,

      acaidvalue: acaid,
      mclidvalue: mclid,
      mclnamevalue: mclname,
      activeone: false,
      activetwo: false,
      activethree: true,
      activefour: false,
      addKeyModal: true,
    });
  };
  handleCkey = () => {
    this.setState({
      addKeyModal: false,
      showAlert: false,
      itemvalue: '',

    });
  };

  handleValidSubmitCats = (event, values) => {
    event.preventDefault();
    console.log('values', values);
    if (this.state.boardvalue == '') {
      this.setState({
        showAlert: true,
        alertType: 'danger',
        alertText: 'Select Board',
      });
    } else if (values.categoryvalue.length == 0) {
      this.setState({
        showAlert: true,
        alertType: 'danger',
        alertText: 'Select Categories',
      });
    } else {

      var categoryvalue = JSON.stringify(values.categoryvalue);
      const headers = {
        'Content-Type': 'application/json',
        Authorization: localStorage.token,
      };
      var addkeyurl = ACA_URL + 'addmboardcats';
      var postData = {

        aca_str: this.state.aca_str,
        boardvalue: this.state.boardvalue,
        mcats: categoryvalue,
        uid: localStorage.usercode,


      };

      axios
        .post(addkeyurl, postData, {
          headers: headers,
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error) {
            this.setState({
              showAlert: true,
              alertType: 'warning',
              alertText: response.data.message,
            });
          } else {
            localStorage.setItem("token", response.data.token);
            this.handleGetkeys();
            this.setState(
              {
                addKeyModal: false,
                showAlert: false,
              });
            swal(response.data.message, {
              icon: 'success',
            });
          }
        })
        .catch((error) => {
          console.log('Error is ', error);
          this.setState({
            alertType: 'danger',
            showAlert: true,
            alertText: 'Failed Try Again Later!',
          });
        });
    }
  };
  handleValidRemoveCats = (event, values) => {
    event.preventDefault();
    console.log('values', values);
    if (this.state.boardvalue == '') {
      this.setState({
        showAlert: true,
        alertType: 'danger',
        alertText: 'Select Board',
      });
    } else if (values.categoryvalue.length == 0) {
      this.setState({
        showAlert: true,
        alertType: 'danger',
        alertText: 'Select Categories',
      });
    } else {

      var categoryvalue = JSON.stringify(values.categoryvalue);
      const headers = {
        'Content-Type': 'application/json',
        Authorization: localStorage.token,
      };
      var addkeyurl = ACA_URL + 'removemboardcats';
      var postData = {

        aca_str: this.state.aca_str,
        boardvalue: this.state.boardvalue,
        mcats: categoryvalue,
        uid: localStorage.usercode,


      };

      axios
        .post(addkeyurl, postData, {
          headers: headers,
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error) {
            this.setState({
              showAlert: true,
              alertType: 'warning',
              alertText: response.data.message,
            });
          } else {
            localStorage.setItem("token", response.data.token);
            this.handleGetkeys();
            this.setState(
              {
                addKeyModal: false,
                showAlert: false,
              });
            swal(response.data.message, {
              icon: 'success',
            });
          }
        })
        .catch((error) => {
          console.log('Error is ', error);
          this.setState({
            alertType: 'danger',
            showAlert: true,
            alertText: 'Failed Try Again Later!',
          });
        });
    }
  };
  manageCats = (aca_str, bid, bname, waction, rcats, ucats, e) => {

    var whattodo = '';
    var showcats = [];
    if (waction == 'Add') {
      whattodo = waction + ' Category to ' + bname;
      if (rcats.length >= 1) {
        showcats = rcats;
      } else {
        showcats = this.state.acats;
      }
    } else if (waction == 'Remove') {
      whattodo = waction + ' Category from ' + bname;
      showcats = ucats;
    }

    this.setState({
      itemactionname: whattodo,
      aca_str: aca_str,
      boardvalue: bid,
      boardnamevalue: bname,
      rcats: showcats,
      activemngcats: waction,
      activeone: false,
      activemngcls: false,
      activemngsubs: false,
      addKeyModal: true,

    });
  };

  handleAddCatsCls = (event, values) => {
    event.preventDefault();
    console.log('values', values);


    var mcatclassesvalue = JSON.stringify(values.mcatclasses);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var addkeyurl = ACA_URL + 'addmcatclass';
    var postData = {
      // item: values.item,
      aca_str: this.state.aca_str,
      m_cat: this.state.mcatvalue,
      aclasses: mcatclassesvalue,
      uid: localStorage.usercode,
    };

    axios
      .post(addkeyurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.error) {
          this.setState({
            showAlert: true,
            alertType: 'warning',
            alertText: response.data.message,
          });
        } else {
          localStorage.setItem("token", response.data.token);
          this.handleGetkeys();
          this.setState(
            {
              addKeyModal: false,
              activeone: false,
              activetwo: false,
              activethree: false,
              activefour: false,
              showAlert: false,
              acaidvalue: '',
              mcatidvalue: '',
              mcatnamevalue: '',
            });
          swal(response.data.message, {
            icon: 'success',
          });
        }
      })
      .catch((error) => {
        console.log('Error is ', error);
        this.setState({
          alertType: 'danger',
          showAlert: true,
          alertText: 'Failed Try Again Later!',
        });
      });

  };

  handleRemoveCatsCls = (event, values) => {
    event.preventDefault();
    console.log('values', values);


    var mcatclassesvalue = JSON.stringify(values.mcatclasses);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var addkeyurl = ACA_URL + 'removemcatclass';
    var postData = {
      // item: values.item,
      aca_str: this.state.aca_str,
      m_cat: this.state.mcatvalue,
      aclasses: mcatclassesvalue,
      uid: localStorage.usercode,
    };

    axios
      .post(addkeyurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.error) {
          this.setState({
            showAlert: true,
            alertType: 'warning',
            alertText: response.data.message,
          });
        } else {
          localStorage.setItem("token", response.data.token);
          this.handleGetkeys();
          this.setState(
            {
              addKeyModal: false,
              activeone: false,
              activetwo: false,
              activethree: false,
              activefour: false,
              showAlert: false,
              acaidvalue: '',
              mcatidvalue: '',
              mcatnamevalue: '',
            });
          swal(response.data.message, {
            icon: 'success',
          });
        }
      })
      .catch((error) => {
        console.log('Error is ', error);
        this.setState({
          alertType: 'danger',
          showAlert: true,
          alertText: 'Failed Try Again Later!',
        });
      });

  };


  manageCls = (aca_str, catid, catname, waction, rcls, ucls, e) => {
    console.log('ucls', ucls);
    console.log('rcls', rcls);
    console.log('waction', waction);
    var whattodo = '';
    var showcls = [];
    if (waction == 'Add') {
      whattodo = waction + ' Class to ' + catname;
      if (rcls.length >= 1) {
        showcls = rcls;
      } else {
        showcls = this.state.acls;
      }
    } else if (waction == 'Remove') {
      whattodo = waction + ' Class from ' + catname;
      showcls = ucls;
    }

    this.setState({
      itemactionname: whattodo,
      aca_str: aca_str,
      mcatvalue: catid,
      mcatnamevalue: catname,
      rcls: showcls,
      activemngcls: waction,
      activeone: false,
      activemngcats: false,
      activemngsubs: false,
      addKeyModal: true,

    });
  };
  manageSubs = (aca_str, clsid, clsname, waction, rsubs, usubs, e) => {
    console.log('rsubs', rsubs);
    console.log('usubs', usubs);
    console.log('waction', waction);
    var whattodo = '';
    var showsubs = [];
    if (waction == 'Add') {
      whattodo = waction + ' Subject to ' + clsname;
      if (rsubs.length >= 1) {
        showsubs = rsubs;
      } else {
        showsubs = this.state.asubs;
      }
    } else if (waction == 'Remove') {
      whattodo = waction + ' Subject from ' + clsname;
      showsubs = usubs;
    }

    this.setState({
      itemactionname: whattodo,
      aca_str: aca_str,
      mclsvalue: clsid,
      mclsnamevalue: clsname,
      rsubs: showsubs,
      activemngsubs: waction,
      activeone: false,
      activemngcats: false,
      activemngcls: false,
      addKeyModal: true,

    });
  };

  handleAddClsSubs = (event, values) => {
    event.preventDefault();
    console.log('values', values);


    var mclasssubs = JSON.stringify(values.mclasssubs);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var addkeyurl = ACA_URL + 'addmclasssub';
    var postData = {
      // item: values.item,
      aca_str: this.state.aca_str,
      m_cls: this.state.mclsvalue,
      aclsubs: mclasssubs,
      uid: localStorage.usercode,
    };

    axios
      .post(addkeyurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.error) {
          this.setState({
            showAlert: true,
            alertType: 'warning',
            alertText: response.data.message,
          });
        } else {
          localStorage.setItem("token", response.data.token);
          this.handleGetkeys();
          this.setState(
            {
              addKeyModal: false,
              activeone: false,
              activetwo: false,
              activethree: false,
              activefour: false,
              showAlert: false,
              aca_str: '',
              mclsvalue: '',
              mclsnamevalue: '',
            });
          swal(response.data.message, {
            icon: 'success',
          });
        }
      })
      .catch((error) => {
        console.log('Error is ', error);
        this.setState({
          alertType: 'danger',
          showAlert: true,
          alertText: 'Failed Try Again Later!',
        });
      });

  };

  handleRemoveClsSubs = (event, values) => {
    event.preventDefault();
    console.log('values', values);


    var mclasssubs = JSON.stringify(values.mclasssubs);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var addkeyurl = ACA_URL + 'removemclasssub';
    var postData = {
      // item: values.item,
      aca_str: this.state.aca_str,
      m_cls: this.state.mclsvalue,
      aclsubs: mclasssubs,
      uid: localStorage.usercode,
    };

    axios
      .post(addkeyurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.error) {
          this.setState({
            showAlert: true,
            alertType: 'warning',
            alertText: response.data.message,
          });
        } else {
          localStorage.setItem("token", response.data.token);
          this.handleGetkeys();
          this.setState(
            {
              addKeyModal: false,
              activeone: false,
              activetwo: false,
              activethree: false,
              activefour: false,
              showAlert: false,
              aca_str: '',
              mclsvalue: '',
              mclsnamevalue: '',
            });
          swal(response.data.message, {
            icon: 'success',
          });
        }
      })
      .catch((error) => {
        console.log('Error is ', error);
        this.setState({
          alertType: 'danger',
          showAlert: true,
          alertText: 'Failed Try Again Later!',
        });
      });

  };

  render() {
    const { rboards } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.state.itempageabout}{localStorage.boardname} </title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title={localStorage.acaboardname} breadcrumbItem={this.state.itemsubname} />


            <Card>
              <CardBody>
                <p className="card-title-desc onclicklink" onClick={() => {
                  this.props.history.push('/academicstructureboards'); //eslint-disable-line
                }}>Back to Boards</p>

                <Nav tabs className="nav-tabs-custom nav-justified">

                  {this.state.relateditems &&
                    this.state.relateditems.map((mitem, index) => {

                      var rcats = [];
                      var ucats = [];
                      if (mitem.structure_dtls.length >= 1) {
                        rcats = mitem.structure_dtls[index].rcats;
                        ucats = mitem.structure_dtls[index].ucats;
                      }

                      var isi = index + 1;
                      return (
                        <NavItem key={index}>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: this.state.customActiveTab == isi,
                            })}
                            onClick={() => {
                              this.toggleCustom(isi)
                            }}
                          >
                            <span className="d-none d-sm-block">Board: {mitem.itemname}
                              <Button
                                className="btn-sm xmarl10"
                                color="success"
                                onClick={this.manageCats.bind(this, mitem.id, mitem.boardvalue, mitem.itemname, 'Add', rcats, ucats)}
                              >
                                <i className="mdi mdi-plus" /> Category
                              </Button>
                              <Button
                                className="btn-sm xmarl10"
                                color="danger"
                                onClick={this.manageCats.bind(this, mitem.id, mitem.boardvalue, mitem.itemname, 'Remove', rcats, ucats)}
                              >
                                <i className="mdi mdi-minus" /> Category
                              </Button>
                            </span>
                          </NavLink>
                        </NavItem>
                      );
                    })}

                </Nav>

                <TabContent activeTab={this.state.customActiveTab} className="p-3 text-muted">
                  {this.state.relateditems &&
                    this.state.relateditems.map((mitem, index) => {
                      var issi = index + 1;
                      return (
                        <TabPane tabId={issi} key={index}>
                          {mitem.structure_dtls && mitem.structure_dtls.map((mcat, index) => {
                            var rcls = mcat.rcls;
                            var ucls = mcat.ucls;

                            return (
                              <div className="col-md-12" key={index}>
                                <Card className="catcard">
                                  <CardBody>
                                    <CardTitle className="h4">{mcat.mcatname}
                                      <Button
                                        className="btn-sm xmarl10"
                                        color="success"
                                        onClick={this.manageCls.bind(this, mitem.id, mcat.accatid, mcat.mcatname, 'Add', rcls, ucls)}
                                      >
                                        <i className="mdi mdi-plus" /> Class
                              </Button>
                                      <Button
                                        className="btn-sm xmarl10"
                                        color="danger"
                                        onClick={this.manageCls.bind(this, mitem.id, mcat.accatid, mcat.mcatname, 'Remove', rcls, ucls)}
                                      >
                                        <i className="mdi mdi-minus" /> Class
                              </Button>
                                    </CardTitle>

                                  </CardBody>
                                </Card>


                                {mcat.mcls.length >= 1 ? (

                                  <>
                                    {mcat.mcls && mcat.mcls.map((mclass, index) => {
                                      var rsubs = mclass.rsubs;
                                      var usubs = mclass.usubs;

                                      return (
                                        <Card key={index}>
                                          <CardBody>
                                            <div className="row">
                                              <div className="col-md-2">
                                                <span className="as-class clearfix"><i className="mdi mdi-chevron-right" />  {mclass.mclname}
                                                  <br />
                                                  <Button
                                                    className="btn-sm xmarl10"
                                                    color="success"
                                                    onClick={this.manageSubs.bind(this, mitem.id, mclass.acclid, mclass.mclname, 'Add', rsubs, usubs)}
                                                  >
                                                    <i className="mdi mdi-plus" /> Subject
                              </Button>
                                                  <Button
                                                    className="btn-sm xmarl10"
                                                    color="danger"
                                                    onClick={this.manageSubs.bind(this, mitem.id, mclass.acclid, mclass.mclname, 'Remove', rsubs, usubs)}
                                                  >
                                                    <i className="mdi mdi-minus" /> Subject
                              </Button>

                                                </span>
                                              </div>
                                              <div className="col-md-10">

                                                {mclass.clsubs.length >= 1 ? (
                                                  <>
                                                    {mclass.clsubs && mclass.clsubs.map((msub, index) => {
                                                      return (

                                                        <span key={index} className="as-subject"><i className="mdi mdi-chevron-double-right" />  {msub.msubname}</span>


                                                      )
                                                    })}
                                                  </>
                                                ) : "No Subjects"}

                                              </div>
                                            </div>
                                          </CardBody>
                                        </Card>
                                      )
                                    })}
                                  </>

                                ) : (
                                  <Card>
                                    <CardBody>
                                      <div className="row">
                                        <div className="col-md-2">
                                          No Classes
                                    </div>
                                        <div className="col-md-10">No Subjects</div>
                                      </div>
                                    </CardBody>
                                  </Card>

                                )}



                              </div>
                            )
                          })}
                        </TabPane>
                      );
                    })}

                </TabContent>
              </CardBody>
            </Card>

          </div>
          <Modal
            isOpen={this.state.addKeyModal}

            centered={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">{this.state.itemactionname} </h5>
              <button
                type="button"
                onClick={this.handleCkey}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {this.state.showAlert ? (
                <Alert color={this.state.alertType}>
                  <p>{this.state.alertText}</p>
                </Alert>
              ) : null}
              {this.state.activemngcats == 'Remove' ? (
                <AvForm
                  className="needs-validation"
                  onValidSubmit={this.handleValidRemoveCats}
                >


                  <div className="mb-3">
                    <AvCheckboxGroup name="categoryvalue" label="Select Class Categories" required>
                      {this.state.rcats && this.state.rcats.map((aclaasit, index) => {
                        return (
                          <AvCheckbox key={index} label={aclaasit.name} value={aclaasit.id} />

                        )
                      })}
                    </AvCheckboxGroup>
                  </div>

                  <div className="mt-4 d-grid">
                    <button
                      className="btn btn-danger btn-block waves-effect waves-light"
                      type="submit"
                    >
                      {this.state.itemactionname}
                    </button>
                  </div>
                </AvForm>
              ) : null}

              {this.state.activemngcats == 'Add' ? (
                <AvForm
                  className="needs-validation"
                  onValidSubmit={this.handleValidSubmitCats}
                >


                  <div className="mb-3">
                    <AvCheckboxGroup name="categoryvalue" label="Select Class Categories" required>
                      {this.state.rcats && this.state.rcats.map((aclaasit, index) => {
                        return (
                          <AvCheckbox key={index} label={aclaasit.name} value={aclaasit.id} />

                        )
                      })}
                    </AvCheckboxGroup>
                  </div>

                  <div className="mt-4 d-grid">
                    <button
                      className="btn btn-primary btn-block waves-effect waves-light"
                      type="submit"
                    >
                      {this.state.itemactionname}
                    </button>
                  </div>
                </AvForm>
              ) : null}
              {this.state.activeone ? (
                <AvForm
                  className="needs-validation"
                  onValidSubmit={this.handleAddBoardCats}
                >


                  <div className="mb-3">
                    <AvCheckboxGroup name="categoryvalue" label="Select Class Categories" required>
                      {this.state.rcats && this.state.rcats.map((aclaasit, index) => {
                        return (
                          <AvCheckbox key={index} label={aclaasit.name} value={aclaasit.id} />

                        )
                      })}
                    </AvCheckboxGroup>
                  </div>

                  <div className="mt-4 d-grid">
                    <button
                      className="btn btn-primary btn-block waves-effect waves-light"
                      type="submit"
                    >
                      {this.state.itemactionname}
                    </button>
                  </div>
                </AvForm>
              ) : null}

              {this.state.activemngcls == 'Add' ? (
                <AvForm
                  className="needs-validation"
                  onValidSubmit={this.handleAddCatsCls}
                >


                  <div className="mb-3">
                    <AvCheckboxGroup name="mcatclasses" label={this.state.itemactionname} required>
                      {this.state.rcls && this.state.rcls.map((aclaasit, index) => {
                        return (
                          <AvCheckbox key={index} label={aclaasit.name} value={aclaasit.id} />

                        )
                      })}

                    </AvCheckboxGroup>

                  </div>

                  <div className="mt-4 d-grid">
                    <button
                      className="btn btn-primary btn-block waves-effect waves-light"
                      type="submit"
                    >
                      {this.state.itemactionname}
                    </button>
                  </div>


                </AvForm>
              ) : null}

              {this.state.activemngcls == 'Remove' ? (
                <AvForm
                  className="needs-validation"
                  onValidSubmit={this.handleRemoveCatsCls}
                >


                  <div className="mb-3">
                    <AvCheckboxGroup name="mcatclasses" label={this.state.itemactionname} required>
                      {this.state.rcls && this.state.rcls.map((aclaasit, index) => {
                        return (
                          <AvCheckbox key={index} label={aclaasit.name} value={aclaasit.id} />

                        )
                      })}

                    </AvCheckboxGroup>

                  </div>

                  <div className="mt-4 d-grid">
                    <button
                      className="btn btn-danger btn-block waves-effect waves-light"
                      type="submit"
                    >
                      {this.state.itemactionname}
                    </button>
                  </div>


                </AvForm>
              ) : null}

              {this.state.activemngsubs == 'Add' ? (
                <AvForm
                  className="needs-validation"
                  onValidSubmit={this.handleAddClsSubs}
                >
                  <div className="mb-3">
                    <AvCheckboxGroup name="mclasssubs" label={this.state.itemactionname} required>
                      {this.state.rsubs && this.state.rsubs.map((asub, index) => {
                        return (
                          <AvCheckbox key={index} label={asub.name} value={asub.id} />

                        )
                      })}

                    </AvCheckboxGroup>
                  </div>
                  <div className="mt-4 d-grid">
                    <button
                      className="btn btn-primary btn-block waves-effect waves-light"
                      type="submit"
                    >
                      {this.state.itemactionname}
                    </button>
                  </div>
                </AvForm>
              ) : null}
              {this.state.activemngsubs == 'Remove' ? (
                <AvForm
                  className="needs-validation"
                  onValidSubmit={this.handleRemoveClsSubs}
                >
                  <div className="mb-3">
                    <AvCheckboxGroup name="mclasssubs" label={this.state.itemactionname} required>
                      {this.state.rsubs && this.state.rsubs.map((asub, index) => {
                        return (
                          <AvCheckbox key={index} label={asub.name} value={asub.id} />

                        )
                      })}

                    </AvCheckboxGroup>
                  </div>
                  <div className="mt-4 d-grid">
                    <button
                      className="btn btn-danger btn-block waves-effect waves-light"
                      type="submit"
                    >
                      {this.state.itemactionname}
                    </button>
                  </div>
                </AvForm>
              ) : null}
            </div>
          </Modal>
        </div>
      </React.Fragment>
    )
  }
}

export default AcademicStructure
