import React, { Component } from "react"
import DefaultDashboard from "./Default/index"
import AdminDashboard from "./Admin/index"
import CoAdminDashboard from "./CoAdmin/index"

class Dashboard extends Component {
  render() {
    var userDashboard = '';
    if (localStorage.upanel == 'admin-panel') {
      userDashboard = <AdminDashboard />;
    } else if (localStorage.upanel == 'coadmin-panel') {
      userDashboard = <CoAdminDashboard />;
    } else {
      userDashboard = <DefaultDashboard />;
    }
    return (
      <React.Fragment>
        {userDashboard}
      </React.Fragment>
    )
  }
}

export default Dashboard;
