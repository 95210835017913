import React, { Component } from "react"
import {
  Alert, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Modal, Button, Table, ButtonDropdown,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import MetaTags from 'react-meta-tags';
import axios from 'axios';
// availity-reactstrap-validation
import { AvField, AvForm, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import { SCH_URL } from "../../../helpers/url_helper";
// import SweetAlert from "react-bootstrap-sweetalert"
import Select from 'react-select';
import swal from 'sweetalert';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "../../../components/Common/datatables.scss"
const initialstate = {
  itempageabout: 'Schools | ',
  itemmainname: localStorage.schname,
  itemsubname: ' Schools',
  itemsubonename: 'School',
  itemactionname: 'Add School',
  tname: 'schbran',
  tdepname: 'schmedim',
  tmdepname: 'schnme',
  itemvalue: '',
  allitems: [],
  relateditems: [],
  select_coadmins: [{
    value: '',
    label: (<div>
      Select Schools-Admin
    </div>
    ),
  }],
  showAlert: false,
  alertType: '',
  alertText: '',
  keyId: 0,
  keyName: '',
  oldkeyname: '',
  newkeyname: '',
  noorid: 0,

  addKeyModal: false,
  akeys: 0,
  bkeys: 0,
  tkeys: 0,

};
class ManageStructure extends Component {
  constructor(props) {
    super(props)
    // this.state = {}
    this.handleGetkeys = this.handleGetkeys.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleDkey = this.handleDkey.bind(this);
    this.handleTkey = this.handleTkey.bind(this);
    this.handleEditkey = this.handleEditkey.bind(this);
    localStorage.setItem('schbranid', '');
    localStorage.setItem('schbranname', '');
  }
  state = { ...initialstate };
  componentDidMount() {
    if (localStorage.schname == '') {
      this.props.history.push('/addschool'); //eslint-disable-line
    }
    this.handleGetkeys();


  }

  handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name + ' ' + value);
    this.setState({ [name]: value });
  };
  handleGetkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
      mitem: localStorage.schid,
    };
    const getuurl = SCH_URL + 'getschstats';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);
          const allitems = [...response.data.allitems];
          console.log('allitems', response.data.allitems);
          const allcoadmins = [...response.data.coadmins];
          let akeys = 0;

          let tkeys = 0;
          allitems.map((dkey) => {
            akeys = akeys + dkey.status;
            tkeys = tkeys + 1;
          });

          this.setState({
            akeys: akeys,
            bkeys: tkeys - akeys,
            tkeys: tkeys,
            allitems: response.data.allitems,
            schbranches: response.data.allitems,
            allacas: response.data.acastructures,
            allcoadmins: response.data.allcoadmins,
            select_coadmins: [{
              value: '',
              label: (<div>
                Select Schools-Admin
              </div>
              ),
            }],

          }, () => {
            if (allcoadmins.length >= 1) {
              allcoadmins.map((item) => {
                this.state.select_coadmins.push({
                  value: item.id,
                  label: (
                    <div>
                      {item.name}
                    </div>
                  ),
                });
              });
            }
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        // localStorage.clear();
        this.props.history.push('/'); //eslint-disable-line
      });
  };

  handleSelectcoadminChange = (selectedOption) => {
    let coadminvalue = selectedOption.value;
    this.setState({ coadminvalue: coadminvalue, coadminvaluename: selectedOption.label.props.children, });
  };


  handleValidSubmit = (event, values) => {
    event.preventDefault();
    console.log('values', values);
    console.log('this.state.wheretodo', this.state.wheretodo);
    var postData = {
      uid: localStorage.usercode,

    };
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var addkeyurl = SCH_URL + 'addmitem';
    if (this.state.wheretodo == 0) {
      this.setState({
        showAlert: true,
        alertType: 'warning',
        alertText: 'Invalid Request ',
      });
    } else {


      if (this.state.editmode) {
        addkeyurl = SCH_URL + 'edititem';
        postData = {
          itemid: this.state.editid,
          item: values.item,
          uid: localStorage.usercode,
          table: this.state.edittable,

        };
      } else {
        if (this.state.wheretodo == 1) {
          postData = {
            item: values.item,
            mitem: localStorage.schid,
            uid: localStorage.usercode,
            table: this.state.tname,
            mtable: this.state.tmdepname,

          };
        } else if (this.state.wheretodo == 2) {
          postData = {
            uid: localStorage.usercode,
            item: values.item,
            mitem: this.state.mitemvalue,
            mmitem: this.state.mmitemvalue,
            table: 'schmedim',
            mtable: 'schbran',
            mmtable: 'schnme',

          };
          addkeyurl = SCH_URL + 'addmmitem';
        }
      }





      axios
        .post(addkeyurl, postData, {
          headers: headers,
        })
        .then((response) => {
          console.log(response.data.message);
          if (response.data.error) {
            this.setState({
              showAlert: true,
              alertType: 'warning',
              alertText: response.data.message,
            });
          } else {
            localStorage.setItem("token", response.data.token);
            this.handleGetkeys();
            this.setState(
              {
                addKeyModal: false,
              });
            swal(values.item + ' ' + response.data.message, {
              icon: 'success',
            });
          }
        })
        .catch((error) => {
          console.log('Error is ', error);
          this.setState({
            alertType: 'danger',
            showAlert: true,
            alertText: 'Failed Try Again Later!',
          });
        });

    }
  };



  handleValidBoardSubmit = (event, values) => {
    event.preventDefault();
    console.log('values', values);
    if (this.state.mitemvalue == '') {
      this.setState({
        showAlert: true,
        alertType: 'warning',
        alertText: 'Select ' + this.state.mitemsubonename,
      });
    } else if (values.acastructurevalue.length == 0) {
      this.setState({
        showAlert: true,
        alertType: 'danger',
        alertText: 'Select Categories',
      });
    } else {

      var acastructurevalue = JSON.stringify(values.acastructurevalue);
      const headers = {
        'Content-Type': 'application/json',
        Authorization: localStorage.token,
      };
      var addkeyurl = SCH_URL + 'linkschaca';
      var postData = {
        item: acastructurevalue,
        mitem: this.state.mitemvalue,
        mmitem: this.state.mmitemvalue,
        mmmitem: localStorage.schid,
        uid: localStorage.usercode,
        table: 'schstruc',
        mtable: 'schmedim',
        mmtable: 'schbran',
        mmmtable: 'schnme',
      };

      axios
        .post(addkeyurl, postData, {
          headers: headers,
        })
        .then((response) => {
          console.log(response.data.message);
          if (response.data.error) {
            this.setState({
              showAlert: true,
              alertType: 'warning',
              alertText: response.data.message,
            });
          } else {
            localStorage.setItem("token", response.data.token);
            this.handleGetkeys();
            this.setState(
              {
                addKeyModal: false,
                activeone: false,
              });
            swal('Board Structure ' + response.data.message, {
              icon: 'success',
            });
          }
        })
        .catch((error) => {
          console.log('Error is ', error);
          this.setState({
            alertType: 'danger',
            showAlert: true,
            alertText: 'Failed Try Again Later!',
          });
        });

    }
  };

  handleValidCoAdSubmit = (event, values) => {
    event.preventDefault();
    console.log('values', values);
    if (this.state.coadminvalue == '') {
      this.setState({
        showAlert: true,
        alertType: 'warning',
        alertText: 'Select Schools-Admin',
      });
    } else {


      const headers = {
        'Content-Type': 'application/json',
        Authorization: localStorage.token,
      };
      var addkeyurl = SCH_URL + 'linkcoadmin';
      var postData = {
        coadmin: this.state.coadminvalue,
        itemid: this.state.schstrucid,
        uid: localStorage.usercode,
        table: 'schstruc',
      };

      axios
        .post(addkeyurl, postData, {
          headers: headers,
        })
        .then((response) => {
          console.log(response.data.message);
          if (response.data.error) {
            this.setState({
              showAlert: true,
              alertType: 'warning',
              alertText: response.data.message,
            });
          } else {
            localStorage.setItem("token", response.data.token);
            this.handleGetkeys();
            this.setState(
              {
                addCoAdModal: false,
                schstrucid: '',
                coadminvalue: '',
              });
            swal('Schools-Admin ' + response.data.message, {
              icon: 'success',
            });
          }
        })
        .catch((error) => {
          console.log('Error is ', error);
          this.setState({
            alertType: 'danger',
            showAlert: true,
            alertText: 'Failed Try Again Later!',
          });
        });

    }
  };

  handleDkey = (id, item, tname, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'id');
    console.log(item + 'item');

    swal({
      title: 'Delete ' + item,
      text: 'Are you sure?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((keyconfirm) => {
      if (keyconfirm) {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: localStorage.token,
        };

        const delkeyurl = SCH_URL + 'delitem';
        var postData = {
          keyid: id,
          uid: localStorage.usercode,
          table: tname,
        };
        axios
          .post(delkeyurl, postData, {
            headers: headers,
          })
          .then((response) => {
            console.log(response.data.message);

            if (response.data.error) {
              swal(item + ' ' + response.data.message, {
                icon: 'warning',
              });
            } else {
              localStorage.setItem("token", response.data.token);
              this.handleGetkeys();
              swal(item + ' ' + response.data.message, {
                icon: 'success',
              });
            }
          })
          .catch((error) => {
            console.log('Error is ', error);
            swal('Try Again Later', {
              icon: 'warning',
            });
          });
      }
    });
  };
  handleTkey = (id, item, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'id');
    console.log(item + 'item');
    var keysts = checked == true ? 1 : 0;
    var kstatus = 'Deactivate';
    if (checked) {
      kstatus = 'Activate';
    }
    swal({
      title: kstatus + ' ' + item,
      text: 'Are you sure?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((keyconfirm) => {
      if (keyconfirm) {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: localStorage.token,
        };

        const togkeyurl = SCH_URL + 'togitem';
        var postData = {
          keyid: id,
          kstatus: keysts,
          uid: localStorage.usercode,
          table: this.state.tname,

        };
        axios
          .post(togkeyurl, postData, {
            headers: headers,
          })
          .then((response) => {
            console.log(response.data.message);

            if (response.data.error) {
              swal(item + ' ' + response.data.message, {
                icon: 'warning',
              });
            } else {
              localStorage.setItem("token", response.data.token);
              this.handleGetkeys();
              swal(item + ' ' + response.data.message, {
                icon: 'success',
              });
            }
          })
          .catch((error) => {
            console.log('Error is ', error);
            swal('Try Again Later', {
              icon: 'Danger',
            });
          });
      }
    });
  };

  handleAkey = (whattodo, step, mitemvalue, mmitemvalue, mmmitemvalue, mitemvaluename) => {
    console.log('whattodo', whattodo);
    console.log('step', step);
    console.log('mitemvalue', mitemvalue);
    console.log('mmitemvalue', mmitemvalue);
    console.log('mmmitemvalue', mmmitemvalue);
    console.log('mitemvaluename', mitemvaluename);

    this.setState({
      addKeyModal: true,
      activeone: false,
      itemactionname: 'Add ' + whattodo + ' to ' + mitemvaluename,
      itemsubonename: whattodo,
      mitemsubonename: whattodo,
      editmode: false,
      itemvalue: '',
      wheretodo: step,
      mitemvalue: mitemvalue,
      mmitemvalue: mmitemvalue,
      mmmitemvalue: mmmitemvalue,
    });
  };
  handleCkey = () => {
    this.setState({
      addKeyModal: false,
      addCoAdModal: false,
      activeone: false,
      editmode: false,
      schstrucid: '',
      itemvalue: '',
      wheretodo: 0,
      mitemvalue: '',
      mmitemvalue: '',
      mmmitemvalue: '',

    });
  };


  handleAddBoard = (mitemvalue, mitemvaluename, mmitemvalue, mmitemvaluename, racas, event) => {
    console.log('medid', mitemvalue);
    var allacas = [];

    if (racas.length >= 1) {
      allacas = racas;
    } else {
      allacas = this.state.allacas;
    }

    this.setState({
      showAlert: false,
      activeone: true,
      addKeyModal: true,
      allacas: allacas,
      itemactionname: 'Assign Board to ' + mitemvaluename + ' of  ' + mmitemvaluename + ' School',
      mitemvalue: mitemvalue,
      mmitemvalue: mmitemvalue,
    });
  };
  handleEditkey = (id, item, name, edittable, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'id');
    console.log(item + 'item');
    this.setState({
      addKeyModal: true,
      editmode: true,
      edittable: edittable,
      editid: id,
      itemvalue: item,
      itemsubonename: name,
      itemactionname: 'Edit ' + name + ' ' + item,
      wheretodo: 1,
    });
  };
  handleCoAdminkey = (id, checked) => {
    console.log(id + 'id');
    this.setState({
      addCoAdModal: true,
      addKeyModal: false,
      schstrucid: id,
      itemactionname: 'Assign CoAdmin ',
    });
  };
  render() {

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.state.itempageabout} {localStorage.schname}  </title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title={localStorage.schname} breadcrumbItem={localStorage.schname + this.state.itemsubname} />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">{localStorage.schname + this.state.itemsubname}
                      <Button
                        className="float-end btn-sm"
                        color="success"
                        onClick={this.handleAkey.bind(this, 'School', 1, localStorage.schid, 0, 0, localStorage.schname)}
                      >
                        + School
                      </Button>
                    </CardTitle>
                    <p className="card-title-desc onclicklink" onClick={() => {
                      this.props.history.push('/addschool'); //eslint-disable-line
                    }}>Back to Schools</p>

                    {this.state.schbranches &&
                      this.state.schbranches.map((schbranch, index) => {

                        return (
                          <div className="col-md-12" key={index}>
                            <Card className="catcard">
                              <CardBody>
                                <CardTitle className="h4">{schbranch.schbranch.itemname}
                                  <Button
                                    className="float-end btn-sm"
                                    color="secondary"
                                    onClick={this.handleAkey.bind(this, 'Medium', 2, schbranch.schbranch.id, localStorage.schid, 0, schbranch.schbranch.itemname)}
                                  >
                                    + Medium to {schbranch.schbranch.itemname}
                                  </Button>
                                  {schbranch.schbranch.del == 0 ? (
                                    <span className="btn-sm btn-danger" style={{ marginLeft: '10px' }} onClick={this.handleDkey.bind(this, schbranch.schbranch.id, schbranch.schbranch.itemname, 'schbran')}>-</span>
                                  ) : null}

                                </CardTitle>

                              </CardBody>
                            </Card>


                            {schbranch.allschbranchmeds.length >= 1 ? (

                              <>
                                {schbranch.allschbranchmeds && schbranch.allschbranchmeds.map((schbranchmed, medindex) => {


                                  return (
                                    <Card key={medindex}>
                                      <CardBody>
                                        <div className="row">
                                          <div className="col-md-2">
                                            <span className="as-class clearfix">

                                              <ButtonDropdown
                                                isOpen={this.state['med' + medindex + schbranchmed.schbranmed.id]}
                                                toggle={() =>
                                                  this.setState({
                                                    ['med' + medindex + schbranchmed.schbranmed.id]: !this.state['med' + medindex + schbranchmed.schbranmed.id],
                                                  })
                                                }
                                              >
                                                <Button className="btn btn-secondary btn-sm">
                                                  <i className="mdi mdi-chevron-right" /><b>Medium: </b> {schbranchmed.schbranmed.itemname}
                                                </Button>
                                                <DropdownToggle
                                                  caret
                                                  color="secondary"
                                                  className="btn btn-secondary btn-sm"
                                                >
                                                  <i className="mdi mdi-chevron-down"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                  <DropdownItem onClick={this.handleEditkey.bind(this, schbranchmed.schbranmed.id, schbranchmed.schbranmed.itemname, 'Medium', 'schmedim')}><i className="bx bx-pencil font-size-16 align-middle ms-1" /> Edit {schbranchmed.schbranmed.itemname} </DropdownItem>
                                                  <DropdownItem divider />
                                                  {schbranchmed.acastruclist.length >= 1 ? (
                                                    <DropdownItem onClick={this.handleAddBoard.bind(this, schbranchmed.schbranmed.id, schbranchmed.schbranmed.itemname, schbranch.schbranch.id, schbranch.schbranch.itemname, schbranchmed.acastruclist)}>+ Assign Board Structure</DropdownItem>
                                                  ) : null}
                                                  <DropdownItem divider />
                                                  {schbranchmed.schbranmed.del == 0 ? (
                                                    <DropdownItem onClick={this.handleDkey.bind(this, schbranchmed.schbranmed.id, schbranchmed.schbranmed.itemname, 'schmedim')}><i className="bx bx-trash font-size-16 align-middle ms-1" /> {schbranchmed.schbranmed.itemname}</DropdownItem>
                                                  ) : (
                                                    <DropdownItem disabled><i className="bx bx-trash font-size-16 align-middle ms-1" /> {schbranchmed.schbranmed.itemname}</DropdownItem>
                                                  )}

                                                </DropdownMenu>
                                              </ButtonDropdown>


                                            </span>
                                          </div>
                                          <div className="col-md-10">
                                            <>
                                              {schbranchmed.schscrucdtls && schbranchmed.schscrucdtls.map((schscruc, strindex) => {
                                                return (

                                                  <span key={strindex} className="sch-board">

                                                    <ButtonDropdown
                                                      isOpen={this.state['board' + medindex + strindex + schscruc.id]}
                                                      toggle={() =>
                                                        this.setState({
                                                          ['board' + medindex + strindex + schscruc.id]: !this.state['board' + medindex + strindex + schscruc.id],
                                                        })
                                                      }
                                                    >
                                                      <Button className="btn btn-info btn-sm">
                                                        <i className="mdi mdi-chevron-double-right" /> <b>Board: </b>{schscruc.structurename}
                                                      </Button>
                                                      <DropdownToggle
                                                        caret
                                                        color="info"
                                                        className="btn btn-info btn-sm"
                                                      >
                                                        <i className="mdi mdi-chevron-down"></i>
                                                      </DropdownToggle>
                                                      <DropdownMenu>
                                                        {schscruc.coadmin ? (
                                                          <DropdownItem onClick={this.handleCoAdminkey.bind(this, schscruc.id)}><i className="bx bx-user-minus font-size-16 align-middle ms-1" /> ReAssign Schools-Admin to {schscruc.structurename}</DropdownItem>
                                                        ) : (
                                                          <DropdownItem onClick={this.handleCoAdminkey.bind(this, schscruc.id)}><i className="bx bx-user-plus font-size-16 align-middle ms-1" /> Assign Schools-Admin to {schscruc.structurename}</DropdownItem>
                                                        )}
                                                        <DropdownItem divider />
                                                        <DropdownItem onClick={this.handleDkey.bind(this, schscruc.id, schscruc.structurename, 'schstruc')}><i className="bx bx-trash font-size-16 align-middle ms-1" /> {schscruc.structurename}</DropdownItem>
                                                      </DropdownMenu>
                                                    </ButtonDropdown>


                                                    {schscruc.coadmin ? (
                                                      <>
                                                        <i className="mdi mdi-chevron-double-right" /> <i className="mdi mdi-chevron-double-right" />
                                                        <Button
                                                          className="btn-sm"
                                                          color="success"
                                                        >
                                                          <b><i className="bx bx-user font-size-16 align-middle ms-1" /> Schools-Admin: </b> {schscruc.coadmin}
                                                        </Button>
                                                      </>
                                                    ) : null}


                                                  </span>


                                                )
                                              })}


                                            </>



                                          </div>
                                        </div>
                                      </CardBody>
                                    </Card>
                                  )
                                })}
                              </>

                            ) : (
                              <Card>
                                <CardBody>
                                  <div className="row">
                                    <div className="col-md-2">

                                      <Button
                                        className="btn-sm"
                                        color="secondary"
                                        onClick={this.handleAkey.bind(this, 'Medium', 2, schbranch.schbranch.id, localStorage.schid, 0, schbranch.schbranch.itemname)}
                                      >
                                        + Medium to {schbranch.schbranch.itemname}
                                      </Button>
                                    </div>
                                    <div className="col-md-10">Add Medium to Assign Board</div>
                                  </div>
                                </CardBody>
                              </Card>

                            )}



                          </div>
                        )
                      })}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          <Modal
            isOpen={this.state.addKeyModal}

            centered={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">{this.state.itemactionname} </h5>
              <button
                type="button"
                onClick={this.handleCkey}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">


              {this.state.activeone ? (
                <AvForm
                  className="needs-validation"
                  onValidSubmit={this.handleValidBoardSubmit}
                >
                  {this.state.showAlert ? (
                    <Alert color={this.state.alertType}>
                      <p>{this.state.alertText}</p>
                    </Alert>
                  ) : null}



                  <div className="mb-3">
                    <AvCheckboxGroup name="acastructurevalue" label="Select Board Structures" required>
                      {this.state.allacas && this.state.allacas.map((acastruc, index) => {
                        return (
                          <AvCheckbox key={index} label={acastruc.name} value={acastruc.id} />
                        )
                      })}
                    </AvCheckboxGroup>
                  </div>






                  <div className="mt-4 d-grid">

                    <button
                      className="btn btn-primary btn-block waves-effect waves-light"
                      type="submit" style={{ marginTop: '18px' }}
                    >
                      {this.state.itemactionname}
                    </button>
                  </div>


                </AvForm>
              ) : (
                <AvForm
                  className="needs-validation"
                  onValidSubmit={this.handleValidSubmit}
                >
                  {this.state.showAlert ? (
                    <Alert color={this.state.alertType}>
                      <p>{this.state.alertText}</p>
                    </Alert>
                  ) : null}


                  <div className="mb-3">
                    <AvField
                      name="item"
                      label={this.state.itemsubonename}
                      value={this.state.itemvalue}
                      type="text"
                      required
                      placeholder={'Enter ' + this.state.itemsubonename}
                    />
                  </div>
                  <div className="mt-4 d-grid">
                    <button
                      className="btn btn-primary btn-block waves-effect waves-light"
                      type="submit"
                    >
                      {this.state.itemactionname}
                    </button>
                  </div>


                </AvForm>
              )}
            </div>
          </Modal>

          <Modal
            isOpen={this.state.addCoAdModal}

            centered={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">{this.state.itemactionname} </h5>
              <button
                type="button"
                onClick={this.handleCkey}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">



              <AvForm
                className="needs-validation"
                onValidSubmit={this.handleValidCoAdSubmit}
              >
                {this.state.showAlert ? (
                  <Alert color={this.state.alertType}>
                    <p>{this.state.alertText}</p>
                  </Alert>
                ) : null}

                {/* schstrucid */}

                <div className="mb-3">
                  <div className="form-group">
                    <label className="">Schools-Admins</label>
                    <div className="form__form-group-field" style={{ height: '30px' }}>
                      <Select
                        name="coadminvalue"
                        value={this.state.select_coadmins.filter(
                          (option) => option.value == this.state.coadminvalue,
                        )}
                        onChange={this.handleSelectcoadminChange}
                        options={this.state.select_coadmins}
                        clearable={false}
                        className="react-select-team"
                        classNamePrefix="react-select"
                      />
                    </div>
                  </div>
                </div>






                <div className="mt-4 d-grid">

                  <button
                    className="btn btn-primary btn-block waves-effect waves-light"
                    type="submit" style={{ marginTop: '18px' }}
                  >
                    {this.state.itemactionname}
                  </button>
                </div>


              </AvForm>

            </div>
          </Modal>
        </div>
      </React.Fragment>
    )
  }
}

export default ManageStructure
