import React, { Component } from "react"
import PropTypes from 'prop-types'
import { Link, withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  UncontrolledTooltip,
  Button
} from "reactstrap"


class TeacherCard extends Component {
  constructor(props) {
    super(props)

    this.handleViewTea = this.handleViewTea.bind(this);
    this.handleEditkey = this.handleEditkey.bind(this);
  }
  handleViewTea = (teaid) => {

    localStorage.setItem('teaid', teaid);
    this.props.history.push('/viewteacher'); //eslint-disable-line
  };
  handleEditkey = (teaid) => {
    localStorage.setItem('whattodoteacher', 'Edit Teacher');
    localStorage.setItem('teaid', teaid);
    this.props.history.push('/mngteacher'); //eslint-disable-line
  };

  render() {
    const user = this.props.user
    return (
      <React.Fragment>
        <Col xl="3" sm="6">
          <Card>
            <CardBody>
              <div className="text-center">
                {!user.items.photo ? (
                  <div className="avatar-sm mx-auto mb-4">
                    <span
                      className={
                        "avatar-title rounded-circle bg-soft bg-primary text-primary font-size-16"
                      }
                    >
                      {user.itemname.charAt(0)}
                    </span>
                  </div>
                ) : (
                  <div className="mb-4">
                    <img
                      className="rounded-circle avatar-sm"
                      src={user.items.photo}
                      alt=""
                    />
                  </div>
                )}

                <h5 className="font-size-15 mb-2">
                  <Link to="#" className="text-dark">
                    {user.itemname}
                  </Link>
                </h5>
                <p className="text-muted"><b>Joined:</b> {user.items.joining_date}</p>
              </div>
              <p className="text-muted"><b>Designation:</b> <span className="float-end">{user.items.designation}</span></p>


              <div>
                {user.items.subjects && user.items.subjects.map((subject, key) => (
                  <span
                    className="badge bg-primary font-size-11 m-1"
                    key={"_subject_" + key}
                  >
                    {subject.name}
                  </span>
                ))}
              </div>
            </CardBody>
            <CardFooter className="bg-transparent border-top">
              <div className="contact-links d-flex font-size-20">
                <div className="flex-fill">
                  <Button
                    className="float-end btn-sm"
                    color="success"
                    onClick={this.handleEditkey.bind(this, user.id)}
                  >
                    Edit Details
								</Button>
                </div>
                <div className="flex-fill">

                </div>
                <div className="flex-fill">
                  <Button
                    className="float-end btn-sm"
                    color="success"
                    onClick={this.handleViewTea.bind(this, user.id)}
                  >
                    View Details
								</Button>
                </div>
              </div>
            </CardFooter>
          </Card>
        </Col>
      </React.Fragment>
    )
  }
}

TeacherCard.propTypes = {
  user: PropTypes.object
}


export default withRouter(TeacherCard)
