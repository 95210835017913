import React, { Component } from "react"
import { Alert, Row, Col, Card, CardBody, CardTitle, CardImg, CardText, CardSubtitle, Modal, Button, FormGroup, InputGroup, Label, Table } from "reactstrap";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';
import MetaTags from 'react-meta-tags';
import axios from 'axios';
// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"
import { TEACH_URL, USER_IMG, NO_IMG } from "../../../helpers/url_helper";
// import SweetAlert from "react-bootstrap-sweetalert"

import Select from 'react-select';
import swal from 'sweetalert';
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "../../../components/Common/datatables.scss"

class MngTeacher extends Component {
  constructor(props) {
    super(props)
    this.state = {
      itempageabout: 'Add Teacher | Management',
      itemmainname: 'Teacher Management',
      itemsubname: 'Add Teacher',
      itemsubonename: 'Teacher',
      tname: 'schtea',
      tdepname: 'dummy',
      itemvalue: '',
      showAlert: false,
      alertType: '',
      alertText: '',

      select_gender: [{
        value: '',
        label: (<div>
          Select Gender
        </div>
        ),
      }],
      select_religion: [{
        value: '',
        label: (<div>
          Select Religion
        </div>
        ),
      }],
      select_ccaste: [{
        value: '',
        label: (<div>
          Select Caste Category
        </div>
        ),
      }],
      select_caste: [{
        value: '',
        label: (<div>
          Select Caste
        </div>
        ),
      }],
      select_qualification: [{
        value: '',
        label: (<div>
          Select Qualification
        </div>
        ),
      }],

      phone: '',
      gendervalue: '',
      dob: '',
      email: '',
      religionvalue: '',
      ccastevalue: '',
      castevalue: '',
      qualificationvalue: '',
      qualification_description: '',
      address: '',
      joining_date: '',
      employee_id: '',
      photo: USER_IMG,
      adhaar_num: '',
      adhaar_copy: NO_IMG,
      pf_num: '',
      esi_num: '',
      pancard_num: '',
      pancard_copy: NO_IMG,
      bank_name: '',
      account_num: '',
      branch_name: '',
      ifsc_code: '',
      micr_code: '',
      swift_code: '',
      idx: 1,
      profilesrc: null,
      adhaarsrc: null,
      pansrc: null,
      crop: {
        unit: "%",
        width: 30,
        aspect: 1 / 1
      },
    }

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleEditkey = this.handleEditkey.bind(this);

    this.handlepic = this.handlepic.bind(this);
    this.handleProfileFile = this.handleProfileFile.bind(this);
    this.handleAdhaarFile = this.handleAdhaarFile.bind(this);
    this.handlePanFile = this.handlePanFile.bind(this);

    localStorage.setItem('dob', '');
    localStorage.setItem('joining_date', '');
  }

  componentDidMount() {
    if (localStorage.whattodoteacher == 'Add Teacher') {
      this.handleAddkeys();
    } else {
      if (localStorage.teaid != '') {
        this.handleEditkeys();
      } else {
        this.props.history.push('/teachers'); //eslint-disable-line
      }
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name + ' ' + value);
    this.setState({ [name]: value });
  };
  handleAddkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
    };
    const getuurl = TEACH_URL + 'getteaoptions';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);
          const allgenders = [...response.data.genders];
          const allreligions = [...response.data.religions];
          const allccastes = [...response.data.ccastes];
          const allcastes = [...response.data.castes];
          const allqualifications = [...response.data.qualifications];


          this.setState({
            editmode: false,
            // itemvalue: teacherdata.itemname,
            // phone: teacherdata.phone,
            // dob: teacherdata.dob,
            // email: teacherdata.email,
            // castevalue: teacherdata.caste,
            // gendervalue: parseInt(teacherdata.gender, 0),
            // religionvalue: parseInt(teacherdata.religion, 0),
            // ccastevalue: parseInt(teacherdata.ccaste, 0),
            // qualificationvalue: parseInt(teacherdata.qualification, 0),
            // qualification_description: teacherdata.qualification_description,
            // address: teacherdata.address,
            // joining_date: teacherdata.joining_date,
            // employee_id: teacherdata.employee_id,
            // photo: teacherdata.photo,
            // adhaar_num: teacherdata.adhaar_num,
            // adhaar_copy: teacherdata.adhaar_copy,
            // pf_num: teacherdata.pf_num,
            // esi_num: teacherdata.esi_num,
            // pancard_num: teacherdata.pancard_num,
            // pancard_copy: teacherdata.pancard_copy,
            // bank_name: teacherdata.bank_name,
            // account_num: teacherdata.account_num,
            // branch_name: teacherdata.branch_name,
            // ifsc_code: teacherdata.ifsc_code,
            // micr_code: teacherdata.micr_code,
            // swift_code: teacherdata.swift_code,
            // allcastes: allcastes,

          }, () => {
            if (allgenders.length >= 1) {
              allgenders.map((item) => {
                this.state.select_gender.push({
                  value: item.id,
                  label: (
                    <div>
                      {item.name}
                    </div>
                  ),
                });
              });
            }
            if (allreligions.length >= 1) {
              allreligions.map((item) => {
                this.state.select_religion.push({
                  value: item.id,
                  label: (
                    <div>
                      {item.name}
                    </div>
                  ),
                });
              });
            }
            if (allccastes.length >= 1) {
              allccastes.map((item) => {
                this.state.select_ccaste.push({
                  value: item.id,
                  label: (
                    <div>
                      {item.name}
                    </div>
                  ),
                });
              });
            }

            if (allqualifications.length >= 1) {
              allqualifications.map((item) => {
                this.state.select_qualification.push({
                  value: item.id,
                  label: (
                    <div>
                      {item.name}
                    </div>
                  ),
                });
              });
            }
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        localStorage.clear();
        this.props.history.push('/'); //eslint-disable-line
      });
  };

  handleEditkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
      table: this.state.tname,
      teaid: localStorage.teaid,
    };
    const getuurl = TEACH_URL + 'getteadetails';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);
          const allgenders = [...response.data.genders];
          const allreligions = [...response.data.religions];
          const allccastes = [...response.data.ccastes];
          const allcastes = [...response.data.castes];
          const allqualifications = [...response.data.qualifications];
          var teacherdata = response.data.allitems;


          localStorage.setItem('teadob', teacherdata.dob);
          localStorage.setItem('teajoining_date', teacherdata.joining_date);

          this.setState({
            editmode: true,
            editid: teacherdata.id,
            itemvalue: teacherdata.itemname,
            phone: teacherdata.phone,
            dob: teacherdata.dob,
            email: teacherdata.email,
            castevalue: teacherdata.caste,
            gendervalue: parseInt(teacherdata.gender, 0),
            religionvalue: parseInt(teacherdata.religion, 0),
            ccastevalue: parseInt(teacherdata.ccaste, 0),
            qualificationvalue: parseInt(teacherdata.qualification, 0),
            qualification_description: teacherdata.qualification_description,
            address: teacherdata.address,
            joining_date: teacherdata.joining_date,
            employee_id: teacherdata.employee_id,
            photo: teacherdata.photo,
            adhaar_num: teacherdata.adhaar_num,
            adhaar_copy: teacherdata.adhaar_copy,
            pf_num: teacherdata.pf_num,
            esi_num: teacherdata.esi_num,
            pancard_num: teacherdata.pancard_num,
            pancard_copy: teacherdata.pancard_copy,
            bank_name: teacherdata.bank_name,
            account_num: teacherdata.account_num,
            branch_name: teacherdata.branch_name,
            ifsc_code: teacherdata.ifsc_code,
            micr_code: teacherdata.micr_code,
            swift_code: teacherdata.swift_code,
            allcastes: allcastes,

          }, () => {
            if (allgenders.length >= 1) {
              allgenders.map((item) => {
                this.state.select_gender.push({
                  value: item.id,
                  label: (
                    <div>
                      {item.name}
                    </div>
                  ),
                });
              });
            }
            if (allreligions.length >= 1) {
              allreligions.map((item) => {
                this.state.select_religion.push({
                  value: item.id,
                  label: (
                    <div>
                      {item.name}
                    </div>
                  ),
                });
              });
            }
            if (allccastes.length >= 1) {
              allccastes.map((item) => {
                this.state.select_ccaste.push({
                  value: item.id,
                  label: (
                    <div>
                      {item.name}
                    </div>
                  ),
                });
              });
            }

            if (allqualifications.length >= 1) {
              allqualifications.map((item) => {
                this.state.select_qualification.push({
                  value: item.id,
                  label: (
                    <div>
                      {item.name}
                    </div>
                  ),
                });
              });
            }
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        localStorage.clear();
        this.props.history.push('/'); //eslint-disable-line
      });
  };

  handleValidSubmit = (event, values) => {
    event.preventDefault();

    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var addkeyurl = TEACH_URL + 'additemtea';
    if (!this.state.gendervalue) {
      swal("Select Gender", {
        icon: 'warning',
      });
    } else if (!this.state.photo) {
      swal("Select Teacher Photo", {
        icon: 'warning',
      });
    } else if (!this.state.qualificationvalue) {
      swal("Select Qualification", {
        icon: 'warning',
      });
    } else if (!localStorage.teajoining_date) {
      swal("Select Joining Date", {
        icon: 'warning',
      });
    } else if (!localStorage.teadob) {
      swal("Select DOB", {
        icon: 'warning',
      });
    } else {
      var postData = {
        item: values.item,
        uid: localStorage.usercode,
        table: this.state.tname,
        phone: values.phone,
        dob: localStorage.teadob,
        email: values.email,
        gender: this.state.gendervalue,
        religion: this.state.religionvalue,
        ccaste: this.state.ccastevalue,
        caste: values.castevalue,
        qualification: this.state.qualificationvalue,
        qualification_description: values.qualification_description,
        address: values.address,
        joining_date: localStorage.teajoining_date,
        employee_id: values.employee_id,
        photo: this.state.photo,
        adhaar_num: values.adhaar_num,
        adhaar_copy: this.state.adhaar_copy,
        pf_num: values.pf_num,
        esi_num: values.esi_num,
        pancard_num: values.pancard_num,
        pancard_copy: this.state.pancard_copy,
        bank_name: values.bank_name,
        account_num: values.account_num,
        branch_name: values.branch_name,
        ifsc_code: values.ifsc_code,
        micr_code: values.micr_code,
        swift_code: values.swift_code,
      };
      if (this.state.editmode) {
        addkeyurl = TEACH_URL + 'edititemtea';
        postData = {
          itemid: this.state.editid,
          item: values.item,
          uid: localStorage.usercode,
          table: this.state.tname,
          phone: this.state.phone,
          gender: this.state.gendervalue,
          dob: localStorage.teadob,
          email: this.state.email,
          religion: this.state.religionvalue,
          ccaste: this.state.ccastevalue,
          caste: this.state.castevalue,
          qualification: this.state.qualificationvalue,
          qualification_description: this.state.qualification_description,
          address: this.state.address,
          joining_date: localStorage.teajoining_date,
          employee_id: this.state.employee_id,
          photo: this.state.photo,
          adhaar_num: this.state.adhaar_num,
          adhaar_copy: this.state.adhaar_copy,
          pf_num: this.state.pf_num,
          esi_num: this.state.esi_num,
          pancard_num: this.state.pancard_num,
          pancard_copy: this.state.pancard_copy,
          bank_name: this.state.bank_name,
          account_num: this.state.account_num,
          branch_name: this.state.branch_name,
          ifsc_code: this.state.ifsc_code,
          micr_code: this.state.micr_code,
          swift_code: this.state.swift_code,

        };
      }


      axios
        .post(addkeyurl, postData, {
          headers: headers,
        })
        .then((response) => {
          console.log(response.data.message);
          if (response.data.error) {
            this.setState({
              showAlert: true,
              alertType: 'warning',
              alertText: response.data.message,
            });
          } else {
            localStorage.setItem("token", response.data.token);


            swal(values.item + ' ' + response.data.message, {
              icon: 'success',
            });
            this.props.history.push('/viewteacher'); //eslint-disable-line
          }
        })
        .catch((error) => {
          console.log('Error is ', error);
          this.setState({
            alertType: 'danger',
            showAlert: true,
            alertText: 'Failed Try Again Later!',
          });
        });
    }
  };






  handleEditkey = () => {
    if (localStorage.teacherdata) {
      var teacherdata = JSON.parse(localStorage.teacherdata);

      const items = teacherdata.items;

      this.setState({
        editid: teacherdata.id,
        itemvalue: teacherdata.itemname,
        phone: items.phone,
        gendervalue: items.gender,
        dob: items.dob,
        email: items.email,
        religionvalue: items.religion,
        ccastevalue: items.ccaste,
        castevalue: items.caste,
        qualificationvalue: items.qualification,
        qualification_description: items.qualification_description,
        address: items.address,
        joining_date: items.joining_date,
        employee_id: items.employee_id,
        photo: items.photo,
        adhaar_num: items.adhaar_num,
        adhaar_copy: items.adhaar_copy,
        pf_num: items.pf_num,
        esi_num: items.esi_num,
        pancard_num: items.pancard_num,
        pancard_copy: items.pancard_copy,
        bank_name: items.bank_name,
        account_num: items.account_num,
        branch_name: items.branch_name,
        ifsc_code: items.ifsc_code,
        micr_code: items.micr_code,
        swift_code: items.swift_code,
      });
    } else {
      this.props.history.push('/teachers'); //eslint-disable-line
    }
  };

  handleSelectgenderChange = (selectedOption) => {
    let gendervalue = selectedOption.value;
    this.setState({ gendervalue: gendervalue, gendervaluename: selectedOption.label.props.children, });
  };
  handleSelectccasteChange = (selectedOption) => {
    let ccastevalue = selectedOption.value;
    this.setState({
      ccastevalue: ccastevalue, ccastevaluename: selectedOption.label.props.children,

    });
  };

  handleSelectreligionChange = (selectedOption) => {
    let religionvalue = selectedOption.value;
    this.setState({ religionvalue: religionvalue, religionvaluename: selectedOption.label.props.children, });
  };
  handleSelectqualificationChange = (selectedOption) => {
    let qualificationvalue = selectedOption.value;
    this.setState({ qualificationvalue: qualificationvalue, qualificationvaluename: selectedOption.label.props.children, });
  };

  uploadProfileImage = (event) => {
    console.log(this.state.photo);
    var formData = new FormData();
    formData.append('file', this.state.croppedprofileImage);
    formData.append('item', this.state.ofwhich);
    formData.append('uid', localStorage.usercode);
    formData.append('token', localStorage.token);
    // console.log(event.target.files[0]);
    let proimgurl = TEACH_URL + 'addfile';
    fetch(proimgurl, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {

        console.log('data', data);
        if (data.error) {
          console.log('data.error', data.error);
          swal(item + ' ' + data.message, {
            icon: 'warning',
          });
        } else {
          localStorage.setItem("token", data.token);
          this.setState(
            {
              photo: data.urlimage,
              addKeyModal: false,
            },
            () => {
              console.log(this.state.photo);
            },
          );
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  uploadAdhaarImage = (event) => {
    console.log(this.state.adhaar_copy);
    var formData = new FormData();
    formData.append('file', this.state.croppedadhaarImage);
    formData.append('item', this.state.ofwhich);
    formData.append('uid', localStorage.usercode);
    formData.append('token', localStorage.token);
    // console.log(event.target.files[0]);
    let proimgurl = TEACH_URL + 'addfile';
    fetch(proimgurl, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {

        console.log('data', data);
        if (data.error) {
          console.log('data.error', data.error);
          swal(item + ' ' + data.message, {
            icon: 'warning',
          });
        } else {
          localStorage.setItem("token", data.token);
          this.setState(
            {
              adhaar_copy: data.urlimage,
              addKeyModal: false,
            },
            () => {
              console.log(this.state.adhaar_copy);
            },
          );
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  uploadPanImage = (event) => {
    console.log(this.state.pancard_copy);
    var formData = new FormData();
    formData.append('file', this.state.croppedpanImage);
    formData.append('item', this.state.ofwhich);
    formData.append('uid', localStorage.usercode);
    formData.append('token', localStorage.token);
    // console.log(event.target.files[0]);
    let proimgurl = TEACH_URL + 'addfile';
    fetch(proimgurl, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {

        console.log('data', data);
        if (data.error) {
          console.log('data.error', data.error);
          swal(item + ' ' + data.message, {
            icon: 'warning',
          });
        } else {
          localStorage.setItem("token", data.token);
          this.setState(
            {
              pancard_copy: data.urlimage,
              addKeyModal: false,
            },
            () => {
              console.log(this.state.pancard_copy);
            },
          );
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  onImageLoaded = image => {
    this.imageRef = image
  }

  onCropChange = (crop) => {
    this.setState({ crop });
  }

  onProfileCropComplete = crop => {
    if (this.imageRef && crop.width && crop.height) {
      this.getCroppedImg(this.imageRef, crop, 'profile');
    }
  }
  onAdhaarCropComplete = crop => {
    if (this.imageRef && crop.width && crop.height) {
      this.getCroppedImg(this.imageRef, crop, 'adhaar');
    }
  }
  onPanCropComplete = crop => {
    if (this.imageRef && crop.width && crop.height) {
      this.getCroppedImg(this.imageRef, crop, 'pan');
    }
  }

  getCroppedImg(image, crop, whichimg) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    const reader = new FileReader()
    canvas.toBlob(blob => {
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        if (whichimg == 'profile') {
          this.dataURLtoFileProfile(reader.result, whichimg)
        } else if (whichimg == 'adhaar') {
          this.dataURLtoFileAdhaar(reader.result, whichimg)
        } else if (whichimg == 'pan') {
          this.dataURLtoFilePan(reader.result, whichimg)
        }
      }
    })
  }

  dataURLtoFileProfile(dataurl) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    var imgext = mime.split('/');
    let cropedfilename = 'profilecropped.' + imgext[1];
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], cropedfilename, { type: mime });
    this.setState({ croppedprofileImage: croppedImage }, () => {
      console.log('croppedImage', this.state.croppedprofileImage);
      console.log('profilemime', mime);
      console.log('profilecropedfilename', cropedfilename);
    })
  }
  dataURLtoFileAdhaar(dataurl) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    var imgext = mime.split('/');
    let cropedfilename = 'adhaarcropped.' + imgext[1];
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], cropedfilename, { type: mime });
    this.setState({ croppedadhaarImage: croppedImage }, () => {
      console.log('croppedImage', this.state.croppedadhaarImage);
      console.log('adhaarmime', mime);
      console.log('adhaarcropedfilename', cropedfilename);
    })
  }
  dataURLtoFilePan(dataurl) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    var imgext = mime.split('/');
    let cropedfilename = 'pancropped.' + imgext[1];
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], cropedfilename, { type: mime });
    this.setState({ croppedpanImage: croppedImage }, () => {
      console.log('croppedImage', this.state.croppedpanImage);
      console.log('panmime', mime);
      console.log('pancropedfilename', cropedfilename);
    })
  }
  handleCkey = () => {
    this.setState({
      addKeyModal: false,
      croppedprofileImage: '',
      croppedprofileImage: '',
      croppedadhaarImage: '',
      croppedpanImage: '',

    });
  };
  handlepic = (ofwhich) => {
    this.setState({
      addKeyModal: true,
      ofwhich: ofwhich,
      croppedprofileImage: '',
      croppedadhaarImage: '',
      croppedpanImage: '',
    });
  };
  handleProfileFile = e => {
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
      this.setState({
        profilesrc: fileReader.result,
      })
    }
    fileReader.readAsDataURL(e.target.files[0])
  }
  handleAdhaarFile = e => {
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
      this.setState({ adhaarsrc: fileReader.result })
    }
    fileReader.readAsDataURL(e.target.files[0])
  }
  handlePanFile = e => {
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
      this.setState({ pansrc: fileReader.result })
    }
    fileReader.readAsDataURL(e.target.files[0])
  }

  render() {
    const { crop, profilesrc, adhaarsrc, pansrc } = this.state
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.state.itempageabout} </title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title={this.state.itemmainname} breadcrumbItem={localStorage.whattodoteacher} />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">{localStorage.whattodoteacher}

                    </CardTitle>
                    <p className="card-title-desc onclicklink"
                      onClick={() => {

                        this.props.history.push('/teachers'); //eslint-disable-line
                      }}
                    >Back to Teachers</p>


                    <AvForm
                      className="needs-validation"
                      onValidSubmit={this.handleValidSubmit}
                    >
                      {this.state.showAlert ? (
                        <Alert color={this.state.alertType}>
                          <p>{this.state.alertText}</p>
                        </Alert>
                      ) : null}


                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Row>
                              <Col md={6}>
                                <CardImg top className="avatar-lg onclicklink" src={this.state.photo} alt="Teacher Profile Image" onClick={this.handlepic.bind(this, 'teacher_profile')} />

                              </Col>


                              <Col md={6}>

                              </Col>
                            </Row>
                          </div>

                        </Col>



                        <Col md={6}>
                          <div className="mb-3 mandate">
                            <AvField
                              name="item"
                              label={this.state.itemsubonename}
                              value={this.state.itemvalue}
                              type="text"
                              required
                              placeholder={'Enter ' + this.state.itemsubonename}
                            />
                          </div>
                        </Col>

                        <Col md={6}>
                          <div className="mb-3">
                            <FormGroup className="mb-4 mandate">
                              <Label>Date of Birth</Label>
                              <InputGroup>
                                <Flatpickr
                                  className="form-control d-block"
                                  name="dob"
                                  placeholder="dd M,yyyy"
                                  options={{
                                    altInput: true,
                                    altFormat: "F j, Y",
                                    dateFormat: "Y-m-d",
                                    defaultDate: localStorage.teadob,
                                    onClose: function (selectedDates, dateStr, instance) {
                                      console.log('selectedDates', selectedDates);
                                      console.log('dateStr', dateStr);
                                      console.log('instance', instance);
                                      localStorage.setItem('teadob', dateStr);
                                    }
                                  }}
                                // value={this.state.itemdate} 
                                />
                              </InputGroup>
                            </FormGroup>
                          </div>
                        </Col>

                        <Col md={6}>
                          <div className="mb-3 mandate">
                            <AvField
                              name="phone"
                              label='Mobile'
                              value={this.state.phone}
                              type="text"
                              required
                              placeholder={'Enter Mobile'}
                            />
                          </div>
                        </Col>



                        <Col md={6}>
                          <div className="mb-3">
                            <div className="form-group mandate">
                              <label className="">Gender</label>
                              <div className="form__form-group-field" style={{ height: '30px' }}>
                                <Select
                                  name="gendervalue"
                                  value={this.state.select_gender.filter(
                                    (option) => option.value == this.state.gendervalue,
                                  )}
                                  onChange={this.handleSelectgenderChange}
                                  options={this.state.select_gender}
                                  clearable={false}
                                  className="react-select-team"
                                  classNamePrefix="react-select"
                                />
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col md={6}>
                          <div className="mb-3 mandate">
                            <AvField
                              name="email"
                              label='Email'
                              value={this.state.email}
                              type="email"

                              placeholder={'Enter Email'}
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <div className="form-group mandate">
                              <label className="">Qualification</label>
                              <div className="form__form-group-field" style={{ height: '30px' }}>
                                <Select
                                  name="qualificationvalue"
                                  value={this.state.select_qualification.filter(
                                    (option) => option.value == this.state.qualificationvalue,
                                  )}
                                  onChange={this.handleSelectqualificationChange}
                                  options={this.state.select_qualification}
                                  clearable={false}
                                  className="react-select-team"
                                  classNamePrefix="react-select"
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3 mandate">
                            <AvField name="qualification_description" label='Qualification Description' value={this.state.qualification_description} type="text" required placeholder={'Enter  Qualification Description'} />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <AvField name="address" label='Address' value={this.state.address} type="text" required placeholder={'Enter  Address'} />
                          </div>
                        </Col>

                        <Col md={6}>
                          <div className="mb-3">
                            <FormGroup className="mb-4 mandate">
                              <Label>Joining Date</Label>
                              <InputGroup>
                                <Flatpickr
                                  className="form-control d-block"
                                  name="joining_date"
                                  placeholder="dd M,yyyy"
                                  options={{
                                    altInput: true,
                                    altFormat: "F j, Y",
                                    dateFormat: "Y-m-d",
                                    defaultDate: localStorage.teajoining_date,
                                    onClose: function (selectedDates, dateStr, instance) {
                                      console.log('selectedDates', selectedDates);
                                      console.log('dateStr', dateStr);
                                      console.log('instance', instance);
                                      localStorage.setItem('teajoining_date', dateStr);
                                    }
                                  }}
                                // value={this.state.itemdate} 
                                />
                              </InputGroup>
                            </FormGroup>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3 mandate">
                            <AvField name="employee_id" label='Employee ID' value={this.state.employee_id} type="text" required placeholder={'Enter  Employee ID'} />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <div className="form-group">
                              <label className="">Religion</label>
                              <div className="form__form-group-field" style={{ height: '30px' }}>
                                <Select
                                  name="religionvalue"
                                  value={this.state.select_religion.filter(
                                    (option) => option.value == this.state.religionvalue,
                                  )}
                                  onChange={this.handleSelectreligionChange}
                                  options={this.state.select_religion}
                                  clearable={false}
                                  className="react-select-team"
                                  classNamePrefix="react-select"
                                />
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col md={6}>
                          <div className="mb-3">
                            <div className="form-group">
                              <label className="">Caste Category</label>
                              <div className="form__form-group-field" style={{ height: '30px' }}>
                                <Select
                                  name="ccastevalue"
                                  value={this.state.select_ccaste.filter(
                                    (option) => option.value == this.state.ccastevalue,
                                  )}
                                  onChange={this.handleSelectccasteChange}
                                  options={this.state.select_ccaste}
                                  clearable={false}
                                  className="react-select-team"
                                  classNamePrefix="react-select"
                                />
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col md={6}>
                          <div className="mb-3">
                            <AvField
                              name="castevalue"
                              label='Caste'
                              value={this.state.castevalue}
                              type="text"

                              placeholder={'Enter Caste'}
                            />
                          </div>
                        </Col>


                        <Col md={6}>
                          <div className="mb-3">
                            <AvField name="pf_num" label='PF No.' value={this.state.pf_num} type="text" placeholder={'Enter  PF No.'} />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <AvField name="esi_num" label='ESI No.' value={this.state.esi_num} type="text" placeholder={'Enter  ESI No.'} />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <AvField name="adhaar_num" label='Adhaar No' value={this.state.adhaar_num} type="text" placeholder={'Enter  Adhaar No'} />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Row>
                              <Col md={6}>
                                <CardImg top className="avatar-lg onclicklink" src={this.state.adhaar_copy} alt="Adhaar Copy" onClick={this.handlepic.bind(this, 'teacher_adhaar_copy')} />
                              </Col>

                              <Col md={6}>

                              </Col>
                            </Row>
                          </div>

                        </Col>


                        <Col md={6}>
                          <div className="mb-3">
                            <AvField name="pancard_num" label='Pancard No.' value={this.state.pancard_num} type="text" placeholder={'Enter  Pancard No.'} />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Row>
                              <Col md={6}>
                                <CardImg top className="avatar-lg onclicklink" src={this.state.pancard_copy} alt="Pancard Copy" onClick={this.handlepic.bind(this, 'teacher_pancard_copy')} />
                              </Col>

                              <Col md={6}>

                              </Col>
                            </Row>
                          </div>

                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <AvField name="bank_name" label='Bank Name' value={this.state.bank_name} type="text" placeholder={'Enter  Bank Name'} />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <AvField name="account_num" label='Account No.' value={this.state.account_num} type="text" placeholder={'Enter  Account No.'} />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <AvField name="branch_name" label='Branch Name' value={this.state.branch_name} type="text" placeholder={'Enter  Branch Name'} />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <AvField name="ifsc_code" label='IFSC Code' value={this.state.ifsc_code} type="text" placeholder={'Enter  IFSC Code'} />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <AvField name="micr_code" label='MICR Code' value={this.state.micr_code} type="text" placeholder={'Enter  MICR Code'} />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <AvField name="swift_code" label='SWIFT Code' value={this.state.swift_code} type="text" placeholder={'Enter  SWIFT Code'} />
                          </div>
                        </Col>

                      </Row>
                      <div className="mt-4 d-grid">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                        >
                          {localStorage.whattodoteacher}
                        </button>
                      </div>


                    </AvForm>


                  </CardBody>
                </Card>
              </Col>
            </Row>

          </div>
          <Modal
            isOpen={this.state.addKeyModal}

            centered={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">{this.state.modalheading} </h5>
              <button
                type="button"
                onClick={this.handleCkey}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">

              {this.state.ofwhich == 'teacher_profile' ? (
                <AvForm
                  className="needs-validation"
                  onValidSubmit={this.uploadProfileImage}
                >
                  {this.state.showAlert ? (
                    <Alert color={this.state.alertType}>
                      <p>{this.state.alertText}</p>
                    </Alert>
                  ) : null}
                  <div className="mb-3">
                    {profilesrc && (
                      <ReactCrop
                        src={profilesrc}
                        crop={crop}
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onProfileCropComplete}
                        onChange={this.onCropChange}
                      />
                    )}

                  </div>

                  <div className="mb-3">
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <input type="file" onChange={this.handleProfileFile} />
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 d-grid">

                    <button
                      className="btn btn-primary btn-block waves-effect waves-light"
                      type="submit"
                    >
                      Crop and Update {this.state.modalheading}
                    </button>
                  </div>
                </AvForm>
              ) : null}
              {this.state.ofwhich == 'teacher_adhaar_copy' ? (
                <AvForm
                  className="needs-validation"
                  onValidSubmit={this.uploadAdhaarImage}
                >
                  {this.state.showAlert ? (
                    <Alert color={this.state.alertType}>
                      <p>{this.state.alertText}</p>
                    </Alert>
                  ) : null}
                  <div className="mb-3">
                    {adhaarsrc && (
                      <ReactCrop
                        src={adhaarsrc}
                        crop={crop}
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onAdhaarCropComplete}
                        onChange={this.onCropChange}
                      />
                    )}

                  </div>

                  <div className="mb-3">
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <input type="file" onChange={this.handleAdhaarFile} />
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 d-grid">

                    <button
                      className="btn btn-primary btn-block waves-effect waves-light"
                      type="submit"
                    >
                      Crop and Update {this.state.modalheading}
                    </button>
                  </div>
                </AvForm>
              ) : null}
              {this.state.ofwhich == 'teacher_pancard_copy' ? (
                <AvForm
                  className="needs-validation"
                  onValidSubmit={this.uploadPanImage}
                >
                  {this.state.showAlert ? (
                    <Alert color={this.state.alertType}>
                      <p>{this.state.alertText}</p>
                    </Alert>
                  ) : null}
                  <div className="mb-3">
                    {pansrc && (
                      <ReactCrop
                        src={pansrc}
                        crop={crop}
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onPanCropComplete}
                        onChange={this.onCropChange}
                      />
                    )}

                  </div>

                  <div className="mb-3">
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <input type="file" onChange={this.handlePanFile} />
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 d-grid">

                    <button
                      className="btn btn-primary btn-block waves-effect waves-light"
                      type="submit"
                    >
                      Crop and Update {this.state.modalheading}
                    </button>
                  </div>
                </AvForm>
              ) : null}

            </div>
          </Modal>
        </div>
      </React.Fragment>
    )
  }
}

export default MngTeacher
