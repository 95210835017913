import React, { Component } from "react"
import { Alert, Row, Col, Card, CardBody, CardTitle, CardImg, CardText, CardSubtitle, Modal, Button, FormGroup, InputGroup, Label, Table } from "reactstrap";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';
import MetaTags from 'react-meta-tags';
import axios from 'axios';
// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"
import { STD_URL, USER_IMG, NO_IMG } from "../../../helpers/url_helper";
// import SweetAlert from "react-bootstrap-sweetalert"
import Father from "./Father";
import Mother from "./Mother";
import Guardian from "./Guardian";
import GuardianTwo from "./GuardianTwo";
import Select from 'react-select';
import swal from 'sweetalert';
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "../../../components/Common/datatables.scss"

class MngStudent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      itempageabout: 'Add Student | Management',
      itemmainname: 'Student Management',
      itemsubname: 'Add Student',
      itemsubonename: 'Student',
      tname: 'schstd',
      tdepname: 'dummy',
      itemvalue: '',
      showAlert: false,
      alertType: '',
      alertText: '',
      student: [],
      select_gender: [{
        value: '',
        label: (<div>
          Select Gender
        </div>
        ),
      }],
      select_religion: [{
        value: '',
        label: (<div>
          Select Religion
        </div>
        ),
      }],
      select_height: [{
        value: '',
        label: (<div>
          Select Height
        </div>
        ),
      }],
      select_weight: [{
        value: '',
        label: (<div>
          Select Weight
        </div>
        ),
      }],
      select_activity: [{
        value: '',
        label: (<div>
          Select Activity
        </div>
        ),
      }],
      select_bloodgrp: [{
        value: '',
        label: (<div>
          Select Blood Group
        </div>
        ),
      }],
      select_country: [{
        value: '',
        label: (<div>
          Select Country
        </div>
        ),
      }],
      select_astate: [{
        value: '',
        label: (<div>
          Select State
        </div>
        ),
      }],
      select_ccaste: [{
        value: '',
        label: (<div>
          Select Caste Category
        </div>
        ),
      }],

      std_name: '',
      admission_num: '',
      std_admission_date: '',
      std_class: '',
      std_section: '',
      std_roll_num: '',
      password: '',
      std_gender: '',
      std_dob: '',
      std_blood_group: '',
      std_religion: '',
      std_ccaste: '',
      std_caste: '',
      std_email: '',
      std_state: '',
      std_country: '',
      std_address: '',
      std_adhaar_num: '',
      std_adhaar_copy: '',
      std_photo: '',
      std_dob_cert: '',
      std_caste_cert: '',
      std_height: '',
      std_weight: '',
      std_house: '',
      father_name: '',
      father_phone: '',
      father_email: '',
      father_occupation: '',
      father_photo: '',
      father_adhaar_num: '',
      father_adhaar_copy: '',
      father_pan_num: '',
      father_pan_copy: '',
      mother_name: '',
      mother_phone: '',
      mother_email: '',
      mother_occupation: '',
      mother_photo: '',
      mother_adhaar_num: '',
      mother_adhaar_copy: '',
      mother_pan_num: '',
      mother_pan_copy: '',
      guardian_name: '',
      guardian_relation: '',
      guardian_email: '',
      guardian_phone: '',
      guardian_photo: '',
      guardian_address: '',
      guardian_occupation: '',
      guardian_address: '',
      guardian_adhaar_num: '',
      guardian_adhaar_copy: '',
      guardian_pan_num: '',
      guardian_pan_copy: '',
      guardian_2_name: '',
      guardian_2_relation: '',
      guardian_2_email: '',
      guardian_2_phone: '',
      guardian_2_photo: '',
      guardian_2_address: '',
      guardian_2_occupation: '',
      guardian_2_address: '',
      guardian_2_adhaar_num: '',
      guardian_2_adhaar_copy: '',
      guardian_2_pan_num: '',


      profilesrc: null,
      adhaarsrc: null,
      dobsrc: null,
      castesrc: null,
      crop: {
        unit: "%",
        width: 30,
        aspect: 1 / 1
      },
    }

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleGetkeys = this.handleGetkeys.bind(this);

    this.handlepic = this.handlepic.bind(this);
    this.handleProfileFile = this.handleProfileFile.bind(this);
    this.handleAdhaarFile = this.handleAdhaarFile.bind(this);
    this.handleDobFile = this.handleDobFile.bind(this);
    this.handleCasteFile = this.handleCasteFile.bind(this);

    this.handleRelation = this.handleRelation.bind(this);

    localStorage.setItem('std_dob', '');
    localStorage.setItem('std_admission_date', '');
  }

  componentDidMount() {

    this.handleAddkeys();

    if (localStorage.patid) {
      this.handleGetkeys(localStorage.patid);
    }
  }

  handleRelation = (whois) => {
    let editpat = false;
    if (localStorage.patid) {
      editpat = true;
    }
    this.setState({
      whois: whois,
      editpat: editpat,
    }, () => {
      console.log('whois', whois);
    });
  };

  handleGetkeys = (patsts) => {
    console.log('mngstdpatid', patsts);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
      table: 'schstdparnt',
      itemid: localStorage.patid,
    };
    const getuurl = STD_URL + 'getparent';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (response.data.error) {
          swal(response.data.message, {
            icon: 'success',
          });
        } else {
          localStorage.setItem("token", response.data.token);
          var patdata = response.data.allitems;


          this.setState({
            whois: '',
            editmode: false,
            patid: localStorage.patid,
            patstds: patdata.patstds,
            father_name: patdata.father_name,
            father_phone: patdata.father_phone,
            father_email: patdata.father_email,
            father_occupation: patdata.father_occupation,
            father_photo: patdata.father_photo,
            father_adhaar_num: patdata.father_adhaar_num,
            father_adhaar_copy: patdata.father_adhaar_copy,
            father_pan_num: patdata.father_pan_num,
            father_pan_copy: patdata.father_pan_copy,
            mother_name: patdata.mother_name,
            mother_phone: patdata.mother_phone,
            mother_email: patdata.mother_email,
            mother_occupation: patdata.mother_occupation,
            mother_photo: patdata.mother_photo,
            mother_adhaar_num: patdata.mother_adhaar_num,
            mother_adhaar_copy: patdata.mother_adhaar_copy,
            mother_pan_num: patdata.mother_pan_num,
            mother_pan_copy: patdata.mother_pan_copy,
            guardian_name: patdata.guardian_name,
            guardian_phone: patdata.guardian_phone,
            guardian_email: patdata.guardian_email,
            guardian_address: patdata.guardian_address,
            guardian_occupation: patdata.guardian_occupation,
            guardian_photo: patdata.guardian_photo,
            guardian_adhaar_num: patdata.guardian_adhaar_num,
            guardian_adhaar_copy: patdata.guardian_adhaar_copy,
            guardian_pan_num: patdata.guardian_pan_num,
            guardian_pan_copy: patdata.guardian_pan_copy,
            guardian_2_name: patdata.guardian_2_name,
            guardian_2_phone: patdata.guardian_2_phone,
            guardian_2_email: patdata.guardian_2_email,
            guardian_2_address: patdata.guardian_2_address,
            guardian_2_occupation: patdata.guardian_2_occupation,
            guardian_2_photo: patdata.guardian_2_photo,
            guardian_2_adhaar_num: patdata.guardian_2_adhaar_num,
            guardian_2_adhaar_copy: patdata.guardian_2_adhaar_copy,
            guardian_2_pan_num: patdata.guardian_2_pan_num,
            guardian_2_pan_copy: patdata.guardian_2_pan_copy,

          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        // localStorage.clear();
        // this.props.history.push('/'); //eslint-disable-line
      });
  };
  handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name + ' ' + value);
    this.setState({ [name]: value });
  };
  handleAddkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
    };
    const getuurl = STD_URL + 'getstdoptions';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce options', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);
          const allgenders = [...response.data.genders];
          const allreligions = [...response.data.religions];
          const allbloodgrps = [...response.data.bloodgrps];
          const allcountrys = [...response.data.countrys];
          const allastates = [...response.data.astates];
          const allactivities = [...response.data.activities];
          const allheights = [...response.data.heights];
          const allweights = [...response.data.weights];
          const allccastes = [...response.data.ccastes];


          this.setState({
            editmode: false,
            allastates: allastates,
            std_name: '',
            admission_num: '',
            std_admission_date: '',
            std_class: '',
            std_section: '',
            std_roll_num: '',
            password: '',
            std_gender: '',
            std_dob: '',
            std_blood_group: '',
            std_religion: '',
            std_ccaste: '',
            std_caste: '',
            std_email: '',
            std_state: '',
            std_country: '',
            std_address: '',
            std_adhaar_num: '',
            std_adhaar_copy: '',
            std_photo: '',
            std_dob_cert: '',
            std_caste_cert: '',
            std_height: '',
            std_weight: '',
            std_house: '',
            select_gender: [{
              value: '',
              label: (<div>
                Select Gender
              </div>
              ),
            }],
            select_religion: [{
              value: '',
              label: (<div>
                Select Religion
              </div>
              ),
            }],
            select_bloodgrp: [{
              value: '',
              label: (<div>
                Select Blood Group
              </div>
              ),
            }],
            select_country: [{
              value: '',
              label: (<div>
                Select Country
              </div>
              ),
            }],
            select_height: [{
              value: '',
              label: (<div>
                Select Height
              </div>
              ),
            }],
            select_weight: [{
              value: '',
              label: (<div>
                Select Weight
              </div>
              ),
            }],
            select_activity: [{
              value: '',
              label: (<div>
                Select Activity
              </div>
              ),
            }],
            select_ccaste: [{
              value: '',
              label: (<div>
                Select Caste Category
              </div>
              ),
            }],

          }, () => {
            if (allgenders.length >= 1) {
              allgenders.map((item) => {
                this.state.select_gender.push({
                  value: item.id,
                  label: (
                    <div>
                      {item.name}
                    </div>
                  ),
                });
              });
            }
            if (allreligions.length >= 1) {
              allreligions.map((item) => {
                this.state.select_religion.push({
                  value: item.id,
                  label: (
                    <div>
                      {item.name}
                    </div>
                  ),
                });
              });
            }
            if (allbloodgrps.length >= 1) {
              allbloodgrps.map((item) => {
                this.state.select_bloodgrp.push({
                  value: item.id,
                  label: (
                    <div>
                      {item.name}
                    </div>
                  ),
                });
              });
            }
            if (allcountrys.length >= 1) {
              allcountrys.map((item) => {
                this.state.select_country.push({
                  value: item.id,
                  label: (
                    <div>
                      {item.name}
                    </div>
                  ),
                });
              });
            }
            if (allactivities.length >= 1) {
              allactivities.map((item) => {
                this.state.select_activity.push({
                  value: item.id,
                  label: (
                    <div>
                      {item.name}
                    </div>
                  ),
                });
              });
            }
            if (allheights.length >= 1) {
              allheights.map((item) => {
                this.state.select_height.push({
                  value: item.id,
                  label: (
                    <div>
                      {item.name}
                    </div>
                  ),
                });
              });
            }
            if (allweights.length >= 1) {
              allweights.map((item) => {
                this.state.select_weight.push({
                  value: item.id,
                  label: (
                    <div>
                      {item.name}
                    </div>
                  ),
                });
              });
            }
            if (allccastes.length >= 1) {
              allccastes.map((item) => {
                this.state.select_ccaste.push({
                  value: item.id,
                  label: (
                    <div>
                      {item.name}
                    </div>
                  ),
                });
              });
            }

          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        // localStorage.clear();
        //this.props.history.push('/'); //eslint-disable-line
      });
  };



  handleValidSubmit = (event, values) => {
    event.preventDefault();

    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var addkeyurl = STD_URL + 'additemstd';
    if (!this.state.gendervalue) {
      swal("Select Gender", {
        icon: 'warning',
      });
    } else if (!this.state.std_photo) {
      swal("Select Student Photo", {
        icon: 'warning',
      });
    } else if (!this.state.std_adhaar_copy) {
      swal("Select Adhaar", {
        icon: 'warning',
      });
    } else if (!this.state.std_dob_cert) {
      swal("Select DOB Certificate", {
        icon: 'warning',
      });
    } else if (!localStorage.std_dob) {
      swal("Select DOB", {
        icon: 'warning',
      });
    } else if (!localStorage.std_admission_date && localStorage.stdclsid && localStorage.stdsecid) {
      swal("Select Admission Date", {
        icon: 'warning',
      });
    } else {
      var postData = {};
      if (localStorage.stdclsid && localStorage.stdsecid) {
        postData = {
          item: values.item,
          uid: localStorage.usercode,
          table: this.state.tname,
          patid: localStorage.patid,
          addtype: 'yes',
          item: values.std_name,
          adminum: values.admission_num,
          stdrollnum: values.std_roll_num,
          admidate: localStorage.std_admission_date,
          schstrucid: localStorage.schstrucid,
          clsid: localStorage.stdclsid,
          secid: localStorage.stdsecid,
          std_adhaar_num: values.std_adhaar_num,
          std_gender: this.state.gendervalue,
          std_dob: localStorage.std_dob,
          std_blood_group: this.state.bloodgrpvalue,
          std_religion: this.state.religionvalue,
          std_ccaste: this.state.ccastevalue,
          std_caste: values.std_caste,
          std_email: values.std_email,
          std_state: this.state.astatevalue,
          std_country: this.state.countryvalue,
          std_address: values.std_address,
          std_adhaar_copy: this.state.std_adhaar_copy,
          std_photo: this.state.std_photo,
          std_dob_cert: this.state.std_dob_cert,
          std_caste_cert: this.state.std_caste_cert,
          std_height: this.state.heightvalue,
          std_weight: this.state.weightvalue,
          std_house: this.state.activityvalue,
        };
      } else {
        postData = {
          item: values.item,
          addtype: 'no',
          uid: localStorage.usercode,
          table: this.state.tname,
          patid: localStorage.patid,
          item: values.std_name,
          schstrucid: localStorage.schstrucid,
          std_adhaar_num: values.std_adhaar_num,
          std_gender: this.state.gendervalue,
          std_dob: localStorage.std_dob,
          std_blood_group: this.state.bloodgrpvalue,
          std_religion: this.state.religionvalue,
          std_ccaste: this.state.ccastevalue,
          std_caste: values.std_caste,
          std_email: values.std_email,
          std_state: this.state.astatevalue,
          std_country: this.state.countryvalue,
          std_address: values.std_address,
          std_adhaar_copy: this.state.std_adhaar_copy,
          std_photo: this.state.std_photo,
          std_dob_cert: this.state.std_dob_cert,
          std_caste_cert: this.state.std_caste_cert,
          std_height: this.state.heightvalue,
          std_weight: this.state.weightvalue,
          std_house: this.state.activityvalue,
        };
      }

      if (this.state.editmode) {
        addkeyurl = STD_URL + 'edititemstd';
        postData = {
          itemid: this.state.editid,
          uid: localStorage.usercode,
          table: this.state.tname,
          item: values.std_name,
          std_adhaar_num: values.std_adhaar_num,
          std_gender: this.state.gendervalue,
          std_dob: localStorage.std_dob,
          std_blood_group: this.state.bloodgrpvalue,
          std_religion: this.state.religionvalue,
          std_ccaste: this.state.ccastevalue,
          std_caste: values.std_caste,
          std_email: values.std_email,
          std_state: this.state.astatevalue,
          std_country: this.state.countryvalue,
          std_address: values.std_address,
          std_adhaar_copy: this.state.std_adhaar_copy,
          std_photo: this.state.std_photo,
          std_dob_cert: this.state.std_dob_cert,
          std_caste_cert: this.state.std_caste_cert,
          std_height: this.state.heightvalue,
          std_weight: this.state.weightvalue,
          std_house: this.state.activityvalue,

        };
      }


      axios
        .post(addkeyurl, postData, {
          headers: headers,
        })
        .then((response) => {
          console.log(response.data.message);
          if (response.data.error) {
            this.setState({
              showAlert: true,
              alertType: 'warning',
              alertText: response.data.message,
            });
          } else {
            localStorage.setItem("token", response.data.token);
            this.setState({
              showAlert: false,
              editmode: false,
              std_name: '',
              admission_num: '',
              std_admission_date: '',
              std_class: '',
              std_section: '',
              std_roll_num: '',
              password: '',
              std_gender: '',
              std_dob: '',
              std_blood_group: '',
              std_religion: '',
              std_ccaste: '',
              std_caste: '',
              std_email: '',
              std_state: '',
              std_country: '',
              std_address: '',
              std_adhaar_num: '',
              std_adhaar_copy: '',
              std_photo: '',
              std_dob_cert: '',
              std_caste_cert: '',
              std_height: '',
              std_weight: '',
              std_house: '',
            });
            swal(values.std_name + ' ' + response.data.message, {
              icon: 'success',
            });
            this.handleGetkeys(localStorage.patid);
            //this.props.history.push('/viewstudent'); //eslint-disable-line
          }
        })
        .catch((error) => {
          console.log('Error is ', error);
          this.setState({
            alertType: 'danger',
            showAlert: true,
            alertText: 'Failed Try Again Later!',
          });
        });
    }
  };







  handleSelectgenderChange = (selectedOption) => {
    let gendervalue = selectedOption.value;
    this.setState({ gendervalue: gendervalue, gendervaluename: selectedOption.label.props.children, });
  };
  handleSelectcountryChange = (selectedOption) => {
    let countryvalue = selectedOption.value;
    this.setState({
      countryvalue: countryvalue, countryvaluename: selectedOption.label.props.children, select_astate: [{
        value: '',
        label: (<div>
          Select Caste
        </div>
        ),
      }],
    }, () => {
      if (this.state.allastates.length >= 1) {
        this.state.allastates.map((item) => {
          if (countryvalue == item.mid) {
            this.state.select_astate.push({
              value: item.id,
              label: (
                <div>
                  {item.name}
                </div>
              ),
            });
          }
        });
      }
    });
  };

  handleSelectastateChange = (selectedOption) => {
    let astatevalue = selectedOption.value;
    this.setState({ astatevalue: astatevalue, astatevaluename: selectedOption.label.props.children, });
  };

  handleSelectreligionChange = (selectedOption) => {
    let religionvalue = selectedOption.value;
    this.setState({ religionvalue: religionvalue, religionvaluename: selectedOption.label.props.children, });
  };
  handleSelectbloodgrpChange = (selectedOption) => {
    let bloodgrpvalue = selectedOption.value;
    this.setState({ bloodgrpvalue: bloodgrpvalue, bloodgrpvaluename: selectedOption.label.props.children, });
  };
  handleSelectactivityChange = (selectedOption) => {
    let activityvalue = selectedOption.value;
    this.setState({ activityvalue: activityvalue, activityvaluename: selectedOption.label.props.children, });
  };
  handleSelectheightChange = (selectedOption) => {
    let heightvalue = selectedOption.value;
    this.setState({ heightvalue: heightvalue, heightvaluename: selectedOption.label.props.children, });
  };
  handleSelectweightChange = (selectedOption) => {
    let weightvalue = selectedOption.value;
    this.setState({ weightvalue: weightvalue, weightvaluename: selectedOption.label.props.children, });
  };
  handleSelectccasteChange = (selectedOption) => {
    let ccastevalue = selectedOption.value;
    this.setState({
      ccastevalue: ccastevalue, ccastevaluename: selectedOption.label.props.children,

    });
  };


  uploadProfileImage = (event) => {
    console.log(this.state.photo);
    var formData = new FormData();
    formData.append('file', this.state.croppedprofileImage);
    formData.append('item', this.state.ofwhich);
    formData.append('uid', localStorage.usercode);
    formData.append('token', localStorage.token);
    // console.log(event.target.files[0]);
    let proimgurl = STD_URL + 'addfile';
    fetch(proimgurl, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {

        console.log('data', data);
        if (data.error) {
          console.log('data.error', data.error);
          swal(item + ' ' + data.message, {
            icon: 'warning',
          });
        } else {
          localStorage.setItem("token", data.token);
          this.setState(
            {
              std_photo: data.urlimage,
              addKeyModal: false,
            },
            () => {
              console.log(this.state.std_photo);
            },
          );
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  uploadAdhaarImage = (event) => {
    console.log(this.state.adhaar_copy);
    var formData = new FormData();
    formData.append('file', this.state.croppedadhaarImage);
    formData.append('item', this.state.ofwhich);
    formData.append('uid', localStorage.usercode);
    formData.append('token', localStorage.token);
    // console.log(event.target.files[0]);
    let proimgurl = STD_URL + 'addfile';
    fetch(proimgurl, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {

        console.log('data', data);
        if (data.error) {
          console.log('data.error', data.error);
          swal(item + ' ' + data.message, {
            icon: 'warning',
          });
        } else {
          localStorage.setItem("token", data.token);
          this.setState(
            {
              std_adhaar_copy: data.urlimage,
              addKeyModal: false,
            },
            () => {
              console.log(this.state.std_adhaar_copy);
            },
          );
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  uploadDobImage = (event) => {
    console.log(this.state.ofwhich);
    var formData = new FormData();
    formData.append('file', this.state.croppeddobImage);
    formData.append('item', this.state.ofwhich);
    formData.append('uid', localStorage.usercode);
    formData.append('token', localStorage.token);
    // console.log(event.target.files[0]);
    let proimgurl = STD_URL + 'addfile';
    fetch(proimgurl, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {

        console.log('data', data);
        if (data.error) {
          console.log('data.error', data.error);
          swal(item + ' ' + data.message, {
            icon: 'warning',
          });
        } else {
          localStorage.setItem("token", data.token);
          this.setState(
            {
              std_dob_cert: data.urlimage,
              addKeyModal: false,
            },
            () => {
              console.log(this.state.std_dob_cert);
            },
          );
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  uploadCasteImage = (event) => {
    console.log(this.state.std_caste_cert);
    var formData = new FormData();
    formData.append('file', this.state.croppedcasteImage);
    formData.append('item', this.state.ofwhich);
    formData.append('uid', localStorage.usercode);
    formData.append('token', localStorage.token);
    // console.log(event.target.files[0]);
    let proimgurl = STD_URL + 'addfile';
    fetch(proimgurl, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {

        console.log('data', data);
        if (data.error) {
          console.log('data.error', data.error);
          swal(item + ' ' + data.message, {
            icon: 'warning',
          });
        } else {
          localStorage.setItem("token", data.token);
          this.setState(
            {
              std_caste_cert: data.urlimage,
              addKeyModal: false,
            },
            () => {
              console.log(this.state.std_caste_cert);
            },
          );
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };


  onImageLoaded = image => {
    this.imageRef = image
  }

  onCropChange = (crop) => {
    this.setState({ crop });
  }

  onProfileCropComplete = crop => {
    if (this.imageRef && crop.width && crop.height) {
      this.getCroppedImg(this.imageRef, crop, 'profile');
    }
  }
  onAdhaarCropComplete = crop => {
    if (this.imageRef && crop.width && crop.height) {
      this.getCroppedImg(this.imageRef, crop, 'adhaar');
    }
  }
  onDobCropComplete = crop => {
    if (this.imageRef && crop.width && crop.height) {
      this.getCroppedImg(this.imageRef, crop, 'dob');
    }
  }
  onCasteCropComplete = crop => {
    if (this.imageRef && crop.width && crop.height) {
      this.getCroppedImg(this.imageRef, crop, 'caste');
    }
  }

  getCroppedImg(image, crop, whichimg) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    const reader = new FileReader()
    canvas.toBlob(blob => {
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        if (whichimg == 'profile') {
          this.dataURLtoFileProfile(reader.result, whichimg)
        } else if (whichimg == 'adhaar') {
          this.dataURLtoFileAdhaar(reader.result, whichimg)
        } else if (whichimg == 'dob') {
          this.dataURLtoFileDob(reader.result, whichimg)
        } else if (whichimg == 'caste') {
          this.dataURLtoFileCaste(reader.result, whichimg)
        }
      }
    })
  }

  dataURLtoFileProfile(dataurl) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    var imgext = mime.split('/');
    let cropedfilename = 'profilecropped.' + imgext[1];
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], cropedfilename, { type: mime });
    this.setState({ croppedprofileImage: croppedImage }, () => {
      console.log('croppedImage', this.state.croppedprofileImage);
      console.log('profilemime', mime);
      console.log('profilecropedfilename', cropedfilename);
    })
  }
  dataURLtoFileAdhaar(dataurl) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    var imgext = mime.split('/');
    let cropedfilename = 'adhaarcropped.' + imgext[1];
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], cropedfilename, { type: mime });
    this.setState({ croppedadhaarImage: croppedImage }, () => {
      console.log('croppedImage', this.state.croppedadhaarImage);
      console.log('adhaarmime', mime);
      console.log('adhaarcropedfilename', cropedfilename);
    })
  }
  dataURLtoFileDob(dataurl) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    var imgext = mime.split('/');
    let cropedfilename = 'dobcropped.' + imgext[1];
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], cropedfilename, { type: mime });
    this.setState({ croppeddobImage: croppedImage }, () => {
      console.log('croppedImage', this.state.croppeddobImage);
      console.log('panmime', mime);
      console.log('pancropedfilename', cropedfilename);
    })
  }
  dataURLtoFileCaste(dataurl) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    var imgext = mime.split('/');
    let cropedfilename = 'castecropped.' + imgext[1];
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], cropedfilename, { type: mime });
    this.setState({ croppedcasteImage: croppedImage }, () => {
      console.log('croppedImage', this.state.croppedcasteImage);
      console.log('panmime', mime);
      console.log('pancropedfilename', cropedfilename);
    })
  }
  handleCkey = () => {
    this.setState({
      addKeyModal: false,
      ofwhich: '',
      croppedprofileImage: '',
      croppedadhaarImage: '',
      croppeddobImage: '',
      croppedcasteImage: '',

    });
  };
  handlepic = (ofwhich) => {
    this.setState({
      addKeyModal: true,
      ofwhich: ofwhich,
      croppedprofileImage: '',
      croppedadhaarImage: '',
      croppeddobImage: '',
      croppedcasteImage: '',
    });
  };
  handleProfileFile = e => {
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
      this.setState({
        profilesrc: fileReader.result,
      })
    }
    fileReader.readAsDataURL(e.target.files[0])
  }
  handleAdhaarFile = e => {
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
      this.setState({ adhaarsrc: fileReader.result })
    }
    fileReader.readAsDataURL(e.target.files[0])
  }
  handleDobFile = e => {
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
      this.setState({ dobsrc: fileReader.result })
    }
    fileReader.readAsDataURL(e.target.files[0])
  }
  handleCasteFile = e => {
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
      this.setState({ castesrc: fileReader.result })
    }
    fileReader.readAsDataURL(e.target.files[0])
  }

  render() {
    const { student, crop, profilesrc, adhaarsrc, dobsrc, castesrc } = this.state
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.state.itempageabout} </title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title={this.state.itemmainname} breadcrumbItem={this.state.itemsubname} />
            {this.state.isFits ? (
              <Lightbox
                mainSrc={this.state.lightimg}
                enableZoom={false}
                imageCaption={
                  "Caption. Can be aligned it to any side and contain any HTML."
                }
                onCloseRequest={() => this.setState({ isFits: false })}
              />
            ) : null}
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">
                      <span className="card-title-desc onclicklink"
                        onClick={() => {
                          //this.props.history.push('/students'); //eslint-disable-line
                        }}
                      >Back to students</span>

                    </CardTitle>

                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl="4">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4 h4">Student Under Guardians</CardTitle>
                    <Row>
                      {this.state.patstds && this.state.patstds.map((student, stdindex) => {
                        return (
                          <Col xl="6" key={stdindex}>

                            <p className="text-muted mb-0">{student.stdname}</p>
                            <img
                              onClick={() => {
                                localStorage.setItem('stdid', student.stdid);
                                localStorage.setItem('stddob', student.stddob);
                                localStorage.setItem('stdadmidate', student.admdate);
                                this.props.history.push('/viewstudent'); //eslint-disable-line
                              }}
                              src={student.stdphoto}
                              alt="Student Photo"
                              className="img-thumbnail onclicklink"
                            />
                          </Col>
                        )
                      })}
                    </Row>
                  </CardBody>
                </Card>

                {localStorage.patid && this.state.father_name && this.state.mother_name && this.state.guardian_name && this.state.guardian_2_name ? null : (
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4 h4">Student Parents/Gurdians</CardTitle>
                      <Row>
                        {this.state.father_name ? null : (
                          <Col md={3}>
                            <Button
                              className="float-end btn-sm"
                              color="success"
                              onClick={this.handleRelation.bind(this, 'father')}
                            >
                              + Father
                            </Button>
                          </Col>
                        )}
                        {this.state.mother_name ? null : (
                          <Col md={3}>
                            <Button
                              className="float-end btn-sm"
                              color="success"
                              onClick={this.handleRelation.bind(this, 'mother')}
                            >
                              + Mother
                             </Button>
                          </Col>
                        )}
                        {this.state.guardian_name ? null : (
                          <Col md={3}>
                            <Button
                              className="float-end btn-sm"
                              color="success"
                              onClick={this.handleRelation.bind(this, 'guardian')}
                            >
                              + Guardian
                            </Button>
                          </Col>
                        )}
                        {this.state.guardian_2_name ? null : (
                          <Col md={3}>
                            <Button
                              className="float-end btn-sm"
                              color="success"
                              onClick={this.handleRelation.bind(this, 'guardiantwo')}
                            >
                              + Guardian 2
                            </Button>
                          </Col>
                        )}





                      </Row>
                      <Col md={12}>
                        {this.state.whois == 'father' ? (
                          <Father getPatsts={this.handleGetkeys.bind(this)} editmode={this.state.editpat} />
                        ) : null}
                        {this.state.whois == 'mother' ? (
                          <Mother getPatsts={this.handleGetkeys.bind(this)} editmode={this.state.editpat} />
                        ) : null}
                        {this.state.whois == 'guardian' ? (
                          <Guardian getPatsts={this.handleGetkeys.bind(this)} editmode={this.state.editpat} />
                        ) : null}
                        {this.state.whois == 'guardiantwo' ? (
                          <GuardianTwo getPatsts={this.handleGetkeys.bind(this)} editmode={this.state.editpat} />
                        ) : null}
                      </Col>


                    </CardBody>
                  </Card>
                )}

                {this.state.patid ? (
                  <>
                    {this.state.father_name ? (

                      <Father getPatsts={this.handleGetkeys} editmode={false} />

                    ) : null}
                    {this.state.mother_name ? (

                      <Mother getPatsts={this.handleGetkeys} editmode={false} />

                    ) : null}
                    {this.state.guardian_name ? (

                      <Guardian getPatsts={this.handleGetkeys} editmode={false} />

                    ) : null}
                    {this.state.guardian_2_name ? (

                      <GuardianTwo getPatsts={this.handleGetkeys} editmode={false} />

                    ) : null}
                  </>
                ) : null}


              </Col>

              <Col xl="8">


                <Card>
                  <CardBody>
                    <CardTitle className="mb-4 h4">Add Student Details</CardTitle>
                    <Row>


                      {this.state.patid ? (
                        <>
                          <AvForm
                            className="needs-validation"
                            onValidSubmit={this.handleValidSubmit}
                          >
                            {this.state.showAlert ? (
                              <Alert color={this.state.alertType}>
                                <p>{this.state.alertText}</p>
                              </Alert>
                            ) : null}


                            <Row>
                              {localStorage.stdclsid && localStorage.stdsecid ? (
                                <>
                                  <Col md={6}>
                                    <div className="mb-3 mandate">
                                      <AvField
                                        name="admission_num"
                                        label='Admission No.'
                                        value={this.state.admission_num}
                                        type="text"
                                        required
                                        placeholder='Enter Admission No.'
                                      />
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="mb-3 mandate">
                                      <AvField
                                        name="std_roll_num"
                                        label='Roll No.'
                                        value={this.state.std_roll_num}
                                        type="text"
                                        required
                                        placeholder='Enter Roll No.'
                                      />
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="mb-3">
                                      <FormGroup className="mb-4 mandate">
                                        <Label>Date of Admission</Label>
                                        <InputGroup>
                                          <Flatpickr
                                            className="form-control d-block"
                                            name="std_admission_date"
                                            placeholder="dd M,yyyy"
                                            options={{
                                              altInput: true,
                                              altFormat: "F j, Y",
                                              dateFormat: "Y-m-d",
                                              defaultDate: localStorage.std_admission_date,
                                              onClose: function (selectedDates, dateStr, instance) {
                                                console.log('selectedDates', selectedDates);
                                                console.log('dateStr', dateStr);
                                                console.log('instance', instance);
                                                localStorage.setItem('std_admission_date', dateStr);
                                              }
                                            }}
                                          // value={this.state.itemdate} 
                                          />
                                        </InputGroup>
                                      </FormGroup>
                                    </div>
                                  </Col>
                                </>
                              ) : null}
                              <Col md={6}>
                                <div className="mb-3 mandate">
                                  <AvField
                                    name="std_name"
                                    label='Student Name'
                                    value={this.state.student_name}
                                    type="text"
                                    required
                                    placeholder='Enter Name'
                                  />
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3 mandate">
                                  <AvField
                                    name="std_adhaar_num"
                                    label='Adhaar No'
                                    value={this.state.student_adhaar_num}
                                    type="number"
                                    required
                                    validate={{
                                      required: { value: true, errorMessage: 'Please enter Adhaar' },
                                      pattern: { value: '^[0-9]+$', errorMessage: 'Your Adhaar must be only numbers' },
                                      minLength: { value: 12, errorMessage: 'Your Adhaar must be 12 characters' },
                                      maxLength: { value: 12, errorMessage: 'Your Adhaar not more than 12 characters' }
                                    }}
                                    placeholder={'Enter  Adhaar No'}
                                  />
                                </div>
                              </Col>



                              <Col md={6}>
                                <div className="mb-3">
                                  <div className="form-group mandate">
                                    <label className="">Gender</label>
                                    <div className="form__form-group-field" style={{ height: '30px' }}>
                                      <Select
                                        name="gendervalue"
                                        value={this.state.select_gender.filter(
                                          (option) => option.value == this.state.gendervalue,
                                        )}
                                        onChange={this.handleSelectgenderChange}
                                        options={this.state.select_gender}
                                        clearable={false}
                                        className="react-select-team"
                                        classNamePrefix="react-select"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <FormGroup className="mb-4 mandate">
                                    <Label>Date of Birth</Label>
                                    <InputGroup>
                                      <Flatpickr
                                        className="form-control d-block"
                                        name="std_dob"
                                        placeholder="dd M,yyyy"
                                        options={{
                                          altInput: true,
                                          altFormat: "F j, Y",
                                          dateFormat: "Y-m-d",
                                          defaultDate: localStorage.std_dob,
                                          onClose: function (selectedDates, dateStr, instance) {
                                            console.log('selectedDates', selectedDates);
                                            console.log('dateStr', dateStr);
                                            console.log('instance', instance);
                                            localStorage.setItem('std_dob', dateStr);
                                          }
                                        }}
                                      // value={this.state.itemdate} 
                                      />
                                    </InputGroup>
                                  </FormGroup>
                                </div>
                              </Col>



                              <Col md={6}>
                                <div className="mb-3">
                                  <div className="form-group">
                                    <label className="">Blood Group</label>
                                    <div className="form__form-group-field" style={{ height: '30px' }}>
                                      <Select
                                        name="bloodgrpvalue"
                                        value={this.state.select_bloodgrp.filter(
                                          (option) => option.value == this.state.bloodgrpvalue,
                                        )}
                                        onChange={this.handleSelectbloodgrpChange}
                                        options={this.state.select_bloodgrp}
                                        clearable={false}
                                        className="react-select-team"
                                        classNamePrefix="react-select"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Col>



                              <Col md={6}>
                                <div className="mb-3">
                                  <div className="form-group">
                                    <label className="">Religion</label>
                                    <div className="form__form-group-field" style={{ height: '30px' }}>
                                      <Select
                                        name="religionvalue"
                                        value={this.state.select_religion.filter(
                                          (option) => option.value == this.state.religionvalue,
                                        )}
                                        onChange={this.handleSelectreligionChange}
                                        options={this.state.select_religion}
                                        clearable={false}
                                        className="react-select-team"
                                        classNamePrefix="react-select"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <div className="form-group">
                                    <label className="">Caste Category</label>
                                    <div className="form__form-group-field" style={{ height: '30px' }}>
                                      <Select
                                        name="ccastevalue"
                                        value={this.state.select_ccaste.filter(
                                          (option) => option.value == this.state.ccastevalue,
                                        )}
                                        onChange={this.handleSelectccasteChange}
                                        options={this.state.select_ccaste}
                                        clearable={false}
                                        className="react-select-team"
                                        classNamePrefix="react-select"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <AvField
                                    name="castevalue"
                                    label='Caste'
                                    value={this.state.castevalue}
                                    type="text"
                                    placeholder={'Enter Caste'}
                                  />
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <div className="form-group">
                                    <label className="">Student House Activity</label>
                                    <div className="form__form-group-field" style={{ height: '30px' }}>
                                      <Select
                                        name="activityvalue"
                                        value={this.state.select_activity.filter(
                                          (option) => option.value == this.state.activityvalue,
                                        )}
                                        onChange={this.handleSelectactivityChange}
                                        options={this.state.select_activity}
                                        clearable={false}
                                        className="react-select-team"
                                        classNamePrefix="react-select"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="mb-3">
                                  <AvField
                                    name="std_email"
                                    label='Email'
                                    value={this.state.student_email}
                                    type="email"
                                    placeholder={'Enter Email'}
                                  />
                                </div>
                              </Col>



                              <Col md={6}>
                                <div className="mb-3">
                                  <div className="form-group">
                                    <label className="">Country</label>
                                    <div className="form__form-group-field" style={{ height: '30px' }}>
                                      <Select
                                        name="countryvalue"
                                        value={this.state.select_country.filter(
                                          (option) => option.value == this.state.countryvalue,
                                        )}
                                        onChange={this.handleSelectcountryChange}
                                        options={this.state.select_country}
                                        clearable={false}
                                        className="react-select-team"
                                        classNamePrefix="react-select"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <div className="form-group">
                                    <label className="">State</label>
                                    <div className="form__form-group-field" style={{ height: '30px' }}>
                                      <Select
                                        name="astatevalue"
                                        value={this.state.select_astate.filter(
                                          (option) => option.value == this.state.astatevalue,
                                        )}
                                        onChange={this.handleSelectastateChange}
                                        options={this.state.select_astate}
                                        clearable={false}
                                        className="react-select-team"
                                        classNamePrefix="react-select"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Col>


                              <Col md={12}>
                                <div className="mb-3 mandate">
                                  <AvField name="std_address" label='Address' value={this.state.address} type="text" required placeholder={'Enter  Address'} />
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <div className="form-group">
                                    <label className="">Height Group</label>
                                    <div className="form__form-group-field" style={{ height: '30px' }}>
                                      <Select
                                        name="heightvalue"
                                        value={this.state.select_height.filter(
                                          (option) => option.value == this.state.heightvalue,
                                        )}
                                        onChange={this.handleSelectheightChange}
                                        options={this.state.select_height}
                                        clearable={false}
                                        className="react-select-team"
                                        classNamePrefix="react-select"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <div className="form-group">
                                    <label className="">Weight Group</label>
                                    <div className="form__form-group-field" style={{ height: '30px' }}>
                                      <Select
                                        name="weightvalue"
                                        value={this.state.select_weight.filter(
                                          (option) => option.value == this.state.weightvalue,
                                        )}
                                        onChange={this.handleSelectweightChange}
                                        options={this.state.select_weight}
                                        clearable={false}
                                        className="react-select-team"
                                        classNamePrefix="react-select"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Col>


                              <Row>
                                <Col md={3}>
                                  <div className="mb-3 mandate">
                                    <label>Student Image</label>
                                    <br />
                                    <CardImg top className="avatar-lg onclicklink" src={this.state.std_photo} alt="Photo" onClick={this.handlepic.bind(this, 'std_photo')} />

                                  </div>
                                </Col>
                                <Col md={3}>
                                  <div className="mb-3 mandate">
                                    <label>Adhaar Scan Copy</label>
                                    <br />
                                    <CardImg top className="avatar-lg onclicklink" src={this.state.std_adhaar_copy} alt="Adhaar Copy" onClick={this.handlepic.bind(this, 'std_adhaar_copy')} />

                                  </div>
                                </Col>
                                <Col md={3}>
                                  <div className="mb-3 mandate">
                                    <label>DOB Scan Copy</label>
                                    <br />
                                    <CardImg top className="avatar-lg onclicklink" src={this.state.std_dob_cert} alt="DOB Certificate" onClick={this.handlepic.bind(this, 'std_dob_cert')} />

                                  </div>
                                </Col>
                                <Col md={3}>
                                  <div className="mb-3">
                                    <label>Caste Scan Copy</label>
                                    <br />
                                    <CardImg top className="avatar-lg onclicklink" src={this.state.std_caste_cert} alt="Caste Certificate" onClick={this.handlepic.bind(this, 'std_caste_cert')} />

                                  </div>
                                </Col>


                              </Row>

                            </Row>
                            <div className="mt-4 d-grid">
                              <button
                                className="btn btn-primary btn-block waves-effect waves-light"
                                type="submit"
                              >
                                Submit Student
                        </button>
                            </div>

                          </AvForm>
                        </>
                      ) : null}

                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </div>








          <Modal
            isOpen={this.state.addKeyModal}

            centered={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">{this.state.modalheading} </h5>
              <button
                type="button"
                onClick={this.handleCkey}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">

              {this.state.ofwhich == 'std_photo' ? (
                <AvForm
                  className="needs-validation"
                  onValidSubmit={this.uploadProfileImage}
                >
                  {this.state.showAlert ? (
                    <Alert color={this.state.alertType}>
                      <p>{this.state.alertText}</p>
                    </Alert>
                  ) : null}
                  <div className="mb-3">
                    {profilesrc && (
                      <ReactCrop
                        src={profilesrc}
                        crop={crop}
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onProfileCropComplete}
                        onChange={this.onCropChange}
                      />
                    )}

                  </div>

                  <div className="mb-3">
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <input type="file" onChange={this.handleProfileFile} />
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 d-grid">

                    <button
                      className="btn btn-primary btn-block waves-effect waves-light"
                      type="submit"
                    >
                      Crop and Update {this.state.modalheading}
                    </button>
                  </div>
                </AvForm>
              ) : null}
              {this.state.ofwhich == 'std_adhaar_copy' ? (
                <AvForm
                  className="needs-validation"
                  onValidSubmit={this.uploadAdhaarImage}
                >
                  {this.state.showAlert ? (
                    <Alert color={this.state.alertType}>
                      <p>{this.state.alertText}</p>
                    </Alert>
                  ) : null}
                  <div className="mb-3">
                    {adhaarsrc && (
                      <ReactCrop
                        src={adhaarsrc}
                        crop={crop}
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onAdhaarCropComplete}
                        onChange={this.onCropChange}
                      />
                    )}

                  </div>

                  <div className="mb-3">
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <input type="file" onChange={this.handleAdhaarFile} />
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 d-grid">

                    <button
                      className="btn btn-primary btn-block waves-effect waves-light"
                      type="submit"
                    >
                      Crop and Update {this.state.modalheading}
                    </button>
                  </div>
                </AvForm>
              ) : null}
              {this.state.ofwhich == 'std_dob_cert' ? (
                <AvForm
                  className="needs-validation"
                  onValidSubmit={this.uploadDobImage}
                >
                  {this.state.showAlert ? (
                    <Alert color={this.state.alertType}>
                      <p>{this.state.alertText}</p>
                    </Alert>
                  ) : null}
                  <div className="mb-3">
                    {dobsrc && (
                      <ReactCrop
                        src={dobsrc}
                        crop={crop}
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onDobCropComplete}
                        onChange={this.onCropChange}
                      />
                    )}

                  </div>

                  <div className="mb-3">
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <input type="file" onChange={this.handleDobFile} />
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 d-grid">

                    <button
                      className="btn btn-primary btn-block waves-effect waves-light"
                      type="submit"
                    >
                      Crop and Update {this.state.modalheading}
                    </button>
                  </div>
                </AvForm>
              ) : null}

              {this.state.ofwhich == 'std_caste_cert' ? (
                <AvForm
                  className="needs-validation"
                  onValidSubmit={this.uploadCasteImage}
                >
                  {this.state.showAlert ? (
                    <Alert color={this.state.alertType}>
                      <p>{this.state.alertText}</p>
                    </Alert>
                  ) : null}
                  <div className="mb-3">
                    {castesrc && (
                      <ReactCrop
                        src={castesrc}
                        crop={crop}
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCasteCropComplete}
                        onChange={this.onCropChange}
                      />
                    )}

                  </div>

                  <div className="mb-3">
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <input type="file" onChange={this.handleCasteFile} />
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 d-grid">

                    <button
                      className="btn btn-primary btn-block waves-effect waves-light"
                      type="submit"
                    >
                      Crop and Update {this.state.modalheading}
                    </button>
                  </div>
                </AvForm>
              ) : null}

            </div>
          </Modal>


        </div>
      </React.Fragment>
    )
  }
}

export default MngStudent
