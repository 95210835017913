import React, { Component } from "react"
import { withRouter } from 'react-router';
import {
  Container, Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  Col,
  Row,
} from "reactstrap"
import { map } from "lodash"
import axios from 'axios';
import { SCH_URL } from "../../helpers/url_helper";

const initialstate = {

  relateditems: [],

};

class Dashboard extends Component {
  constructor(props) {
    super(props)
    // this.state = {}
    this.handleGetkeys = this.handleGetkeys.bind(this);


    localStorage.setItem('schboardid', '');
    localStorage.setItem('schboardname', '');
    localStorage.setItem('schmedium', '');
    localStorage.setItem('schbranchname', '');
    localStorage.setItem('schbranchid', '');
  }
  state = { ...initialstate };
  componentDidMount() {
    this.handleGetkeys();
  }

  handleGetkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
      table: 'schstruc',
    };
    const getuurl = SCH_URL + 'getmystructures';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);
          console.log('allitems', response.data.allitems);


          this.setState({
            relateditems: response.data.allitems,

          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        // localStorage.clear();
        //this.props.history.push('/'); //eslint-disable-line
      });
  };




  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* <h3>Schools-Admin Dashboard</h3> */}

            {/* <Card>
              <CardBody>
                <CardTitle className="h4">Title text</CardTitle>
                <p className="card-title-desc">
                  Some text
                </p>
              </CardBody>
            </Card> */}

            <Row>
              {map(this.state.relateditems, (mitem, index) => (
                <Col lg={4} key={index}>
                  <Card>
                    <CardHeader className="h5 bg-transparent border-bottom text-uppercase"><b>School: </b>{mitem.itemname}</CardHeader>
                    <CardBody>
                      <CardTitle className="h4 mt-0">
                        <b>Branch: </b> {mitem.branch}
                      </CardTitle>
                      <CardText>
                        <b>Medium: </b>{mitem.medium}
                      </CardText>
                      <button className="btn btn-primary btn-block waves-effect waves-light" onClick={() => {
                        localStorage.setItem('schstrucid', mitem.id);
                        localStorage.setItem('schboardid', mitem.boardid);
                        localStorage.setItem('schboardname', mitem.boardname);
                        localStorage.setItem('schmedium', mitem.medium);
                        localStorage.setItem('schbranchname', mitem.branch);
                        localStorage.setItem('schbranchid', mitem.branchid);
                        this.props.history.push('/schstructure'); //eslint-disable-line
                      }}>
                        Manage Sections, Subjects, Teachers of <b>{mitem.boardname}  </b> Board Structure
                      </button>
                      <br />
                      <br />
                      {/* <button className="btn btn-primary btn-block waves-effect waves-light" onClick={() => {
                        localStorage.setItem('schstrucid', mitem.id);
                        localStorage.setItem('schboardid', mitem.boardid);
                        localStorage.setItem('schboardname', mitem.boardname);
                        localStorage.setItem('schmedium', mitem.medium);
                        localStorage.setItem('schbranchname', mitem.branch);
                        localStorage.setItem('schbranchid', mitem.branchid);
                        this.props.history.push('/schsections'); //eslint-disable-line
                      }}>
                        Manage Time Table Templates of <b>{mitem.boardname}  </b> Board Structure
                      </button> */}
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>


          </Container>
        </div>
      </React.Fragment>
    )
  }
}


export default withRouter(Dashboard);
