import React, { Component } from "react"
import { Alert, Row, Col, Card, CardBody, CardTitle, CardImg, CardText, CardSubtitle, Modal, Button, FormGroup, InputGroup, Label, Table } from "reactstrap";
import { map } from "lodash"
import MetaTags from 'react-meta-tags';
import axios from 'axios';
import readXlsxFile from 'read-excel-file';
import { TEACH_URL, USER_IMG, NO_IMG } from "../../../helpers/url_helper";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//Import Card
import TeacherCard from "./TeacherCard"
const initialstate = {
  itempageabout: 'Teachers | Management',
  itemmainname: 'Management',
  itemsubname: 'Teachers',
  itemsubonename: 'Teacher',
  itemactionname: 'Add Teacher',
  tname: 'schtea',
  tdepname: 'dummy',
  itemvalue: '',
  allitems: [],
  relateditems: [],

};
class Teachers extends Component {
  constructor(props) {
    super(props)
    // this.state = {}
    this.handleGetkeys = this.handleGetkeys.bind(this);
    this.handleAkey = this.handleAkey.bind(this);

    localStorage.setItem('teajoining_date', '');
    localStorage.setItem('teadob', '');
    localStorage.setItem('whattodoteacher', '');
    localStorage.setItem('teaid', '');
  }
  state = { ...initialstate };
  componentDidMount() {
    this.handleGetkeys();
  }

  handleGetkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
      table: this.state.tname,
      deptable: this.state.tdepname,
    };
    const getuurl = TEACH_URL + 'getitemstea';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);
          const allitems = [...response.data.allitems];
          console.log('allitems', response.data.allitems);


          let tkeys = 0;
          allitems.map((dkey) => {
            tkeys = tkeys + 1;
          });

          this.setState({
            tkeys: tkeys,
            allitems: response.data.allitems,
            relateditems: response.data.allitems,

          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        // localStorage.clear();
        //this.props.history.push('/'); //eslint-disable-line
      });
  };


  handleAkey = () => {
    localStorage.setItem('teaid', '');
    localStorage.setItem('teadob', '');
    localStorage.setItem('teajoining_date', '');
    localStorage.setItem('whattodoteacher', 'Add Teacher');
    this.props.history.push('/mngteacher'); //eslint-disable-line
  };
  handleCkey = () => {
    this.setState({
      addKeyModal: false,

    });
  };

  handleCSVAkey = () => {
    this.setState({
      addKeyModal: true,
    });
  };

  uploadExcelQuestions = (event) => {
    event.preventDefault();

    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };

    var addkeyurl = TEACH_URL + 'exceltea';
    var postData = {
      items: this.state.excelteachers,
      uid: localStorage.usercode,
      table: this.state.tname,
    };
    axios
      .post(addkeyurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log(response.data.message);
        if (response.data.error) {
          this.setState({
            showAlert: true,
            alertType: 'warning',
            alertText: response.data.message,
          });
        } else {
          this.setState(
            {
              addKeyModal: false,
              showAlert: false,
            },
            () => {
              this.handleGetkeys();
            },
          );
        }
      })
      .catch((error) => {
        console.log('Error is ', error);
        this.setState({
          alertType: 'danger',
          showAlert: true,
          alertText: 'Failed Try Again Later!',
        });
      });
  };


  render() {

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.state.itempageabout} </title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title={this.state.itemmainname} breadcrumbItem={this.state.itemsubname} />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">{this.state.itemsubname}
                      <Button
                        className="float-end btn-sm"
                        color="success"
                        onClick={this.handleAkey}
                      >
                        + Add Teacher
						        		</Button>
                      <br />
                      <br />
                      <Button
                        className="float-end btn-sm"
                        color="success"
                        onClick={this.handleCSVAkey}
                      >
                        + Bulk Upload Teachers
						        		</Button>
                    </CardTitle>
                    <p className="card-title-desc">Some Description</p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              {map(this.state.relateditems, (mitem, index) => (
                <TeacherCard user={mitem} key={"_user_" + index} />
              ))}
            </Row>

          </div>

        </div>
        <Modal
          isOpen={this.state.addKeyModal}

          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">{this.state.modalheading} </h5>
            <button
              type="button"
              onClick={this.handleCkey}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">


            <form
              className="form"
              onSubmit={(event) => { e.preventDefault(); }}
            >
              {this.state.showAlert ? (
                <Alert color={this.state.alertType}>
                  <p>{this.state.alertText}</p>
                </Alert>
              ) : null}


              <div className="mb-3">
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <input type="file" id="input" onChange={(e) => {
                      this.setState({
                        excelFile: e.target.files[0],
                      });
                    }} />
                  </div>
                </div>
              </div>

              <div className="mt-4 d-grid">

                <button
                  className="btn btn-primary btn-block waves-effect waves-light"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    readXlsxFile(this.state.excelFile).then((rows) => {
                      let teachersArray = rows.filter((row, index) => {
                        if (index > 0) {
                          return {
                            teaname: row[0],
                            email: row[1],
                            phone: row[2],
                            gender: row[3],
                            dob: row[4],
                            religion: row[5],
                            ccaste: row[6],
                            caste: row[7],
                            qualification: row[8],
                            qualification_description: row[9],
                            address: row[10],
                            employee_id: row[11],
                            joining_date: row[12],
                            adhaar_num: row[13],
                            pf_num: row[14],
                            esi_num: row[15],
                            pancard_num: row[16],
                            bank_name: row[17],
                            branch_name: row[18],
                            account_num: row[19],
                            ifsc_code: row[20],
                            micr_code: row[21],
                            swift_code: row[22],
                            photo: USER_IMG,
                            adhaar_copy: NO_IMG,
                            pancard_copy: NO_IMG,
                          };
                        }
                      });
                      this.setState(
                        {
                          excelteachers: [...teachersArray],
                        },
                        () => {
                          this.uploadExcelQuestions(e);
                        },
                      );
                    });
                  }}
                >
                  Upload Teachers Excel
                </button>
              </div>
            </form>




          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

export default Teachers
