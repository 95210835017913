import React, { Component } from "react"
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";

import 'react-image-crop/lib/ReactCrop.scss';
import "../../../components/Common/datatables.scss"

class CheckPat extends Component {
  constructor(props) {
    super(props)
    this.state = {
      whois: '',
      showAlert: false,
      alertType: '',
      alertText: '',

    }

    this.handleRelation = this.handleRelation.bind(this);

  }

  componentDidMount() {

  }



  render() {

    return (
      <React.Fragment>

        <Card>
          <CardBody>
            <CardTitle className="h4">Student Guardian

                    </CardTitle>


            <Row>
              <Col md={12}>
                <AvForm
                  className="needs-validation"
                  onValidSubmit={this.handleValidSubmit}
                >
                  {this.state.showAlert ? (
                    <Alert color={this.state.alertType}>
                      <p>{this.state.alertText}</p>
                    </Alert>
                  ) : null}


                  <Row>




                    <Col md={9}>
                      <div className="mb-3 mandate">
                        <AvField
                          name="father_phone"
                          label="Mobile"
                          value={this.state.father_phone}
                          type="number"
                          required
                          placeholder={'Enter Mobile'}
                          validate={{
                            required: { value: true, errorMessage: 'Please enter Mobile' },
                            pattern: { value: '^[0-9]+$', errorMessage: 'Your Mobile must be only numbers' },
                            minLength: { value: 10, errorMessage: 'Your Mobile must be 10 characters' },
                            maxLength: { value: 10, errorMessage: 'Your Mobile not more than 10 characters' }
                          }}
                        />
                      </div>
                    </Col>


                    <Col md={3}>
                      <button
                        className="btn btn-primary btn-block waves-effect waves-light"
                        type="submit"
                      >
                        Check Guardian
                        </button>
                    </Col>

                  </Row>


                </AvForm>
              </Col>

            </Row>


          </CardBody>
        </Card>

      </React.Fragment>
    )
  }
}

export default CheckPat
