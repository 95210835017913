import React, { Component } from "react";
import axios from 'axios';
import {
  Alert, Row, Col, Card, CardBody, CardTitle, CardImg, CardText, CardSubtitle, Modal, Button, FormGroup, InputGroup, Label, Table, Container,
  Media,
} from "reactstrap";
import { STD_URL } from "../../../helpers/url_helper";
import MetaTags from 'react-meta-tags';
//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "../../../components/Common/datatables.scss"
const initialstate = {
  itempageabout: 'View Student | Management',
  itemmainname: 'Student Management',
  itemsubname: 'View Student',
  tname: 'schstd',
  tdepname: 'dummy',
  itemvalue: '',
  student: [],
  showAlert: false,
  alertType: '',
  alertText: '',
  isFits: false,

};

class ViewStudent extends Component {
  constructor(props) {
    super(props)
    // this.state = {}
    this.handleGetkeys = this.handleGetkeys.bind(this);
    this.handleEditkey = this.handleEditkey.bind(this);
    this.handleAkey = this.handleAkey.bind(this);

    if (localStorage.stdid) {
      this.handleGetkeys();
    } else {
      this.props.history.push('/students'); //eslint-disable-line
    }
  }
  state = { ...initialstate };
  componentDidMount() {

  }
  handleAkey = () => {
    localStorage.setItem('stdid', '');
    localStorage.setItem('std_dob', '');
    localStorage.setItem('std_admission_date', '');
    localStorage.setItem('whattodostudent', 'Add Student');
    localStorage.setItem('studentdata', '');
    this.props.history.push('/mngstudent'); //eslint-disable-line
  };
  handleEditkey = (stdid, patid) => {
    localStorage.setItem('whattodostudent', 'Edit student');
    localStorage.setItem('stdid', stdid);
    localStorage.setItem('patid', patid);
    this.props.history.push('/mngstudent'); //eslint-disable-line
  };

  handleGetkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
      stdid: localStorage.stdid,
    };
    const getuurl = STD_URL + 'getviewstddetails';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);

          this.setState({
            student: response.data.allitems,
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        localStorage.clear();
        this.props.history.push('/'); //eslint-disable-line
      });
  };
  render() {
    const { student } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.state.itempageabout} </title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title={this.state.itemmainname} breadcrumbItem={this.state.itemsubname} />
            {this.state.isFits ? (
              <Lightbox
                mainSrc={this.state.lightimg}
                enableZoom={false}
                imageCaption={
                  "Caption. Can be aligned it to any side and contain any HTML."
                }
                onCloseRequest={() => this.setState({ isFits: false })}
              />
            ) : null}
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">
                      <span className="card-title-desc onclicklink"
                        onClick={() => {
                          this.props.history.push('/students'); //eslint-disable-line
                        }}
                      >Back to Students</span>
                      <Button
                        className="float-end btn-sm"
                        color="success"
                        onClick={this.handleAkey}
                      >
                        + Add student
						        		</Button>
                    </CardTitle>

                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl="4">

                <Card>
                  <CardBody>
                    <CardTitle className="mb-4 h4">Other Students of this Section</CardTitle>
                    {student.scsstds && student.scsstds.map((std, stdindex) => {
                      if (std.stdid != localStorage.stdid) {
                        return (
                          <Col xl="6" key={stdindex}>

                            <p className="text-muted mb-0">{std.std_name}</p>
                            <img
                              onClick={() => {
                                localStorage.setItem('stdid', std.stdid);
                                localStorage.setItem('stddob', std.stddob);
                                localStorage.setItem('stdadmidate', std.std_admission_date);
                                this.props.history.push('/viewstudent'); //eslint-disable-line
                              }}
                              src={std.std_photo}
                              alt="Student Photo"
                              className="img-thumbnail onclicklink"
                            />
                          </Col>
                        )
                      }
                    })}
                  </CardBody>
                </Card>

                {/* <Card>
                  <CardBody>
                    <CardTitle className="mb-4 h4">Parent Information</CardTitle>
                    <CardTitle className="mb-4 h4">Father</CardTitle>


                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row"><img
                              onClick={() => {
                                this.setState({
                                  isFits: true,
                                  lightimg: student.father_photo
                                });
                              }}
                              src={student.father_photo}
                              alt=""
                              className="img-thumbnail"
                            /></th>
                            <td> </td>
                          </tr>
                          <tr>
                            <th scope="row">Full Name :</th>
                            <td>{student.father_name}</td>
                          </tr>
                          <tr>
                            <th scope="row">Mobile :</th>
                            <td>{student.father_phone}</td>
                          </tr>
                          <tr>
                            <th scope="row">E-mail :</th>
                            <td>{student.father_email}</td>
                          </tr>
                          <tr>
                            <th scope="row">Occupation :</th>
                            <td>{student.father_occupation}</td>
                          </tr>
                          <tr>
                            <th scope="row">Adhaar :</th>
                            <td>{student.father_adhaar_num}</td>
                          </tr>
                          <tr>
                            <th scope="row">Adhaar Copy :</th>
                            <td><img
                              onClick={() => {
                                this.setState({
                                  isFits: true,
                                  lightimg: student.father_adhaar_copy
                                });
                              }}
                              className="img-fluid"
                              alt="Adhaar copy"
                              src={student.father_adhaar_copy}
                              width="35"
                            /></td>
                          </tr>

                          <tr>
                            <th scope="row">Pancard no :</th>
                            <td>{student.father_pan_num}</td>
                          </tr>
                          {student.father_pan_copy ? (
                            <tr>
                              <th scope="row">Pancard Copy :</th>
                              <td><img
                                onClick={() => {
                                  this.setState({
                                    isFits: true,
                                    lightimg: student.father_pan_copy
                                  });
                                }}
                                className="img-fluid"
                                alt="Adhaar copy"
                                src={student.father_pan_copy}
                                width="35"
                              /></td>
                            </tr>
                          ) : null}
                        </tbody>
                      </Table>



                    </div>
                  </CardBody>
                </Card>


                <Card>
                  <CardBody>
                    <CardTitle className="mb-4 h4">Mother</CardTitle>


                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row"><img
                              onClick={() => {
                                this.setState({
                                  isFits: true,
                                  lightimg: this.state.mother_photo
                                });
                              }}
                              src={student.mother_photo}
                              alt=""
                              className="img-thumbnail"
                            /></th>
                            <td> </td>
                          </tr>
                          <tr>
                            <th scope="row">Full Name :</th>
                            <td>{student.mother_name}</td>
                          </tr>
                          <tr>
                            <th scope="row">Mobile :</th>
                            <td>{student.mother_phone}</td>
                          </tr>
                          <tr>
                            <th scope="row">E-mail :</th>
                            <td>{student.mother_email}</td>
                          </tr>
                          <tr>
                            <th scope="row">Occupation :</th>
                            <td>{student.mother_occupation}</td>
                          </tr>
                          <tr>
                            <th scope="row">Adhaar :</th>
                            <td>{student.mother_adhaar_num}</td>
                          </tr>
                          <tr>
                            <th scope="row">Adhaar Copy :</th>
                            <td><img
                              onClick={() => {
                                this.setState({
                                  isFits: true,
                                  lightimg: student.mother_adhaar_copy
                                });
                              }}
                              className="img-fluid"
                              alt="Adhaar copy"
                              src={student.mother_adhaar_copy}
                              width="35"
                            /></td>
                          </tr>

                          <tr>
                            <th scope="row">Pancard no :</th>
                            <td>{student.mother_pan_num}</td>
                          </tr>
                          {student.mother_pan_copy ? (
                            <tr>
                              <th scope="row">Pancard Copy :</th>
                              <td><img
                                onClick={() => {
                                  this.setState({
                                    isFits: true,
                                    lightimg: student.mother_pan_copy
                                  });
                                }}
                                className="img-fluid"
                                alt="Adhaar copy"
                                src={student.mother_pan_copy}
                                width="35"
                              /></td>
                            </tr>
                          ) : null}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>


                <Card>
                  <CardBody>
                    <CardTitle className="mb-4 h4">Guardian 1</CardTitle>


                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row"><img
                              onClick={() => {
                                this.setState({
                                  isFits: true,
                                  lightimg: student.guardian_photo
                                });
                              }}
                              src={student.guardian_photo}
                              alt=""
                              className="img-thumbnail"
                            /></th>
                            <td> </td>
                          </tr>
                          <tr>
                            <th scope="row">Full Name :</th>
                            <td>{student.guardian_name}</td>
                          </tr>
                          <tr>
                            <th scope="row">Mobile :</th>
                            <td>{student.guardian_phone}</td>
                          </tr>
                          <tr>
                            <th scope="row">E-mail :</th>
                            <td>{student.guardian_email}</td>
                          </tr>
                          <tr>
                            <th scope="row">Occupation :</th>
                            <td>{student.guardian_occupation}</td>
                          </tr>
                          <tr>
                            <th scope="row">Adhaar :</th>
                            <td>{student.guardian_adhaar_num}</td>
                          </tr>
                          <tr>
                            <th scope="row">Adhaar Copy :</th>
                            <td><img
                              onClick={() => {
                                this.setState({
                                  isFits: true,
                                  lightimg: student.guardian_adhaar_copy
                                });
                              }}
                              className="img-fluid"
                              alt="Adhaar copy"
                              src={student.guardian_adhaar_copy}
                              width="35"
                            /></td>
                          </tr>

                          <tr>
                            <th scope="row">Pancard no :</th>
                            <td>{student.guardian_pan_num}</td>
                          </tr>
                          {student.guardian_pan_copy ? (
                            <tr>
                              <th scope="row">Pancard Copy :</th>
                              <td><img
                                onClick={() => {
                                  this.setState({
                                    isFits: true,
                                    lightimg: student.guardian_pan_copy
                                  });
                                }}
                                className="img-fluid"
                                alt="Adhaar copy"
                                src={student.guardian_pan_copy}
                                width="35"
                              /></td>
                            </tr>
                          ) : null}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>


                <Card>
                  <CardBody>
                    <CardTitle className="mb-4 h4">Guadian 2</CardTitle>


                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row"><img
                              onClick={() => {
                                this.setState({
                                  isFits: true,
                                  lightimg: student.guardian_2_photo
                                });
                              }}
                              src={student.guardian_2_photo}
                              alt=""
                              className="img-thumbnail"
                            /></th>
                            <td> </td>
                          </tr>
                          <tr>
                            <th scope="row">Full Name :</th>
                            <td>{student.guardian_2_name}</td>
                          </tr>
                          <tr>
                            <th scope="row">Mobile :</th>
                            <td>{student.guardian_2_phone}</td>
                          </tr>
                          <tr>
                            <th scope="row">E-mail :</th>
                            <td>{student.guardian_2_email}</td>
                          </tr>
                          <tr>
                            <th scope="row">Occupation :</th>
                            <td>{student.guardian_2_occupation}</td>
                          </tr>
                          <tr>
                            <th scope="row">Adhaar :</th>
                            <td>{student.guardian_2_adhaar_num}</td>
                          </tr>
                          <tr>
                            <th scope="row">Adhaar Copy :</th>
                            <td><img
                              onClick={() => {
                                this.setState({
                                  isFits: true,
                                  lightimg: student.guardian_2_adhaar_copy
                                });
                              }}
                              className="img-fluid"
                              alt="Adhaar copy"
                              src={student.guardian_2_adhaar_copy}
                              width="35"
                            /></td>
                          </tr>

                          <tr>
                            <th scope="row">Pancard no :</th>
                            <td>{student.guardian_2_pan_num}</td>
                          </tr>
                          {student.guardian_2_pan_copy ? (
                            <tr>
                              <th scope="row">Pancard Copy :</th>
                              <td><img
                                onClick={() => {
                                  this.setState({
                                    isFits: true,
                                    lightimg: student.guardian_2_pan_copy
                                  });
                                }}
                                className="img-fluid"
                                alt="Adhaar copy"
                                src={student.guardian_2_pan_copy}
                                width="35"
                              /></td>
                            </tr>
                          ) : null}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card> */}

              </Col>

              <Col xl="8">

                <Card className="overflow-hidden">
                  <CardBody>
                    <CardTitle className="mb-4 h4">Name: {student.std_name}</CardTitle>
                    <Row>

                      <Col sm="3">
                        <div className="mb-4">
                          <img
                            onClick={() => {
                              this.setState({
                                isFits: true,
                                lightimg: student.photo
                              });
                            }}
                            src={student.std_photo}
                            alt=""
                            className="img-thumbnail"
                          />
                        </div>
                        <p className="text-muted mb-0 text-truncate">
                          <b>Class:</b> {student.std_class}<br />
                          <b>Section:</b> {student.std_section}
                        </p>

                      </Col>

                      <Col sm={9}>
                        <div className="pt-4">
                          <Row>
                            <Col xs="4">
                              <p className="text-muted mb-0">Admission Date</p>
                              <h5 className="font-size-15">
                                {student.std_admission_date}
                              </h5>
                            </Col>
                            <Col xs="4">
                              <p className="text-muted mb-0">Admission Number</p>
                              <h5 className="font-size-15">
                                {student.admission_num}
                              </h5>

                            </Col>
                            <Col xs="4">
                              {/* <Button
                                className="float-end btn-sm"
                                color="success"
                                onClick={this.handleEditkey.bind(this, student.id, student.patid)}
                              >
                                Edit Details
                              </Button> */}
                            </Col>
                          </Row>
                        </div>
                        <div className="pt-4">
                          <Row>
                            <Col xs="4">
                              <p className="text-muted mb-0">Roll No</p>
                              <h5 className="font-size-15">
                                {student.std_roll_num}
                              </h5>
                            </Col>
                            <Col xs="4">
                              <p className="text-muted mb-0">DOB</p>
                              <h5 className="font-size-15">
                                {student.std_dob}
                              </h5>
                            </Col>
                            <Col xs="4">
                              <p className="text-muted mb-0">Blood Group</p>
                              <h5 className="font-size-15">
                                {student.bloodgroup}
                              </h5>
                            </Col>
                          </Row>
                        </div>
                        <div className="pt-4">
                          <Row>
                            <Col xs="4">
                              <p className="text-muted mb-0">Adhaar No</p>
                              <h5 className="font-size-15">
                                {student.std_adhaar_num}
                              </h5>
                            </Col>
                            <Col xs="4">
                              <p className="text-muted mb-0">Email</p>
                              <h5 className="font-size-15">
                                {student.std_email}
                              </h5>
                            </Col>
                            <Col xs="4">
                              <p className="text-muted mb-0">Activity House</p>
                              <h5 className="font-size-15">
                                {student.std_house}
                              </h5>
                            </Col>
                          </Row>
                        </div>





                      </Col>
                      <Col sm={12}>
                        <div className="pt-4">
                          <Row>
                            <Col xs="3">
                              <p className="text-muted mb-0">Gender</p>
                              <h5 className="font-size-15">
                                {student.gender}
                              </h5>
                            </Col>
                            <Col xs="3">
                              <p className="text-muted mb-0">Religion</p>
                              <h5 className="font-size-15">
                                {student.religion}
                              </h5>
                            </Col>
                            <Col xs="3">
                              <p className="text-muted mb-0">Caste Category</p>
                              <h5 className="font-size-15">
                                {student.std_ccaste}
                              </h5>
                            </Col>
                            <Col xs="3">
                              <p className="text-muted mb-0">Caste Name</p>
                              <h5 className="font-size-15">
                                {student.std_caste}
                              </h5>
                            </Col>
                          </Row>
                        </div>

                      </Col>

                      <Col sm={12}>
                        <div className="pt-4">
                          <Row>
                            <Col xs="3">
                              <p className="text-muted mb-0">State</p>
                              <h5 className="font-size-15">
                                {student.std_state}
                              </h5>
                            </Col>
                            <Col xs="3">
                              <p className="text-muted mb-0">Country</p>
                              <h5 className="font-size-15">
                                {student.std_country}
                              </h5>
                            </Col>
                            <Col xs="3">
                              <p className="text-muted mb-0">Height / Weight</p>
                              <h5 className="font-size-15">
                                {student.std_height} / {student.std_weight}
                              </h5>
                            </Col>

                          </Row>
                        </div>

                        <div className="pt-4">
                          <Row>
                            <Col xs="6">
                              <p className="text-muted mb-0">Address</p>
                              <h5 className="font-size-15">
                                {student.std_address}
                              </h5>
                            </Col>
                            <Col xs="2">
                              <p className="text-muted mb-0">Adhaar Copy</p>
                              <img
                                onClick={() => {
                                  this.setState({
                                    isFits: true,
                                    lightimg: student.std_adhaar_copy
                                  });
                                }}
                                className="img-fluid"
                                alt="Adhaar copy"
                                src={student.std_adhaar_copy}

                              />
                            </Col>
                            <Col xs="2">
                              <p className="text-muted mb-0">DOB Certificate Copy</p>
                              <img
                                onClick={() => {
                                  this.setState({
                                    isFits: true,
                                    lightimg: student.std_dob_cert
                                  });
                                }}
                                className="img-fluid"
                                alt="Adhaar copy"
                                src={student.std_dob_cert}

                              />
                            </Col>
                            <Col xs="2">
                              <p className="text-muted mb-0">Caste Certification Copy</p>
                              <img
                                onClick={() => {
                                  this.setState({
                                    isFits: true,
                                    lightimg: student.std_caste_cert
                                  });
                                }}
                                className="img-fluid"
                                alt="Adhaar copy"
                                src={student.std_caste_cert}

                              />
                            </Col>

                          </Row>
                        </div>
                      </Col>

                    </Row>
                  </CardBody>
                </Card>

                <Card className="overflow-hidden">
                  <CardBody>

                    <CardTitle className="mb-4 h4">Father: {student.father_name}</CardTitle>
                    <Row>
                      <Col sm="3">
                        <div className="mb-4">
                          <img
                            onClick={() => {
                              this.setState({
                                isFits: true,
                                lightimg: student.father_photo
                              });
                            }}
                            src={student.father_photo}
                            alt="father photo"
                            className="img-thumbnail"
                          />
                        </div>
                      </Col>

                      <Col sm={9}>
                        <div className="pt-4">
                          <Row>
                            <Col xs="4">
                              <p className="text-muted mb-0">Phone</p>
                              <h5 className="font-size-15">
                                {student.father_phone}
                              </h5>
                            </Col>
                            <Col xs="4">
                              <p className="text-muted mb-0">Email</p>
                              <h5 className="font-size-15">
                                {student.father_email}
                              </h5>

                            </Col>
                            <Col xs="4">
                              <p className="text-muted mb-0">Occupation</p>
                              <h5 className="font-size-15">
                                {student.father_occupation}
                              </h5>
                            </Col>
                          </Row>
                        </div>
                        <div className="pt-4">
                          <Row>
                            <Col xs="4">
                              <p className="text-muted mb-0">Adhaar</p>
                              <h5 className="font-size-15">
                                {student.father_adhaar_num}
                              </h5>
                            </Col>
                            <Col xs="4">
                              <p className="text-muted mb-0">Pancard no</p>
                              <h5 className="font-size-15">
                                {student.father_pan_num}
                              </h5>
                            </Col>
                            <Col xs="2">
                              <p className="text-muted mb-0">Adhaar Copy</p>
                              <img
                                onClick={() => {
                                  this.setState({
                                    isFits: true,
                                    lightimg: student.father_adhaar_copy
                                  });
                                }}
                                className="img-fluid"
                                alt="Adhaar copy"
                                src={student.father_adhaar_copy}

                              />
                            </Col>
                            <Col xs="2">
                              <p className="text-muted mb-0">Pancard Copy</p>
                              {student.father_pan_copy ? (
                                <img
                                  onClick={() => {
                                    this.setState({
                                      isFits: true,
                                      lightimg: student.father_pan_copy
                                    });
                                  }}
                                  className="img-fluid"
                                  alt="Adhaar copy"
                                  src={student.father_pan_copy}

                                />
                              ) : null}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>

                  </CardBody>
                </Card>

                <Card className="overflow-hidden">
                  <CardBody>

                    <CardTitle className="mb-4 h4">Mother: {student.mother_name}</CardTitle>
                    <Row>
                      <Col sm="3">
                        <div className="mb-4">
                          <img
                            onClick={() => {
                              this.setState({
                                isFits: true,
                                lightimg: student.mother_photo
                              });
                            }}
                            src={student.mother_photo}
                            alt="father photo"
                            className="img-thumbnail"
                          />
                        </div>
                      </Col>

                      <Col sm={9}>
                        <div className="pt-4">
                          <Row>
                            <Col xs="4">
                              <p className="text-muted mb-0">Phone</p>
                              <h5 className="font-size-15">
                                {student.mother_phone}
                              </h5>
                            </Col>
                            <Col xs="4">
                              <p className="text-muted mb-0">Email</p>
                              <h5 className="font-size-15">
                                {student.mother_email}
                              </h5>

                            </Col>
                            <Col xs="4">
                              <p className="text-muted mb-0">Occupation</p>
                              <h5 className="font-size-15">
                                {student.mother_occupation}
                              </h5>
                            </Col>
                          </Row>
                        </div>
                        <div className="pt-4">
                          <Row>
                            <Col xs="4">
                              <p className="text-muted mb-0">Adhaar</p>
                              <h5 className="font-size-15">
                                {student.mother_adhaar_num}
                              </h5>
                            </Col>
                            <Col xs="4">
                              <p className="text-muted mb-0">Pancard no</p>
                              <h5 className="font-size-15">
                                {student.mother_pan_num}
                              </h5>
                            </Col>
                            <Col xs="2">
                              <p className="text-muted mb-0">Adhaar Copy</p>
                              <img
                                onClick={() => {
                                  this.setState({
                                    isFits: true,
                                    lightimg: student.mother_adhaar_copy
                                  });
                                }}
                                className="img-fluid"
                                alt="Adhaar copy"
                                src={student.mother_adhaar_copy}

                              />
                            </Col>
                            <Col xs="2">
                              <p className="text-muted mb-0">Pancard Copy</p>
                              {student.mother_pan_copy ? (
                                <img
                                  onClick={() => {
                                    this.setState({
                                      isFits: true,
                                      lightimg: student.mother_pan_copy
                                    });
                                  }}
                                  className="img-fluid"
                                  alt="Adhaar copy"
                                  src={student.mother_pan_copy}

                                />
                              ) : null}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>

                  </CardBody>
                </Card>

                <Card className="overflow-hidden">
                  <CardBody>

                    <CardTitle className="mb-4 h4">Guardian: {student.guardian_name}</CardTitle>
                    <Row>
                      <Col sm="3">
                        <div className="mb-4">
                          <img
                            onClick={() => {
                              this.setState({
                                isFits: true,
                                lightimg: student.guardian_photo
                              });
                            }}
                            src={student.guardian_photo}
                            alt="father photo"
                            className="img-thumbnail"
                          />
                        </div>
                      </Col>

                      <Col sm={9}>
                        <div className="pt-4">
                          <Row>
                            <Col xs="4">
                              <p className="text-muted mb-0">Phone</p>
                              <h5 className="font-size-15">
                                {student.guardian_phone}
                              </h5>
                            </Col>
                            <Col xs="4">
                              <p className="text-muted mb-0">Email</p>
                              <h5 className="font-size-15">
                                {student.guardian_email}
                              </h5>

                            </Col>
                            <Col xs="4">
                              <p className="text-muted mb-0">Occupation</p>
                              <h5 className="font-size-15">
                                {student.guardian_occupation}
                              </h5>
                            </Col>
                          </Row>
                        </div>
                        <div className="pt-4">
                          <Row>
                            <Col xs="4">
                              <p className="text-muted mb-0">Adhaar</p>
                              <h5 className="font-size-15">
                                {student.guardian_adhaar_num}
                              </h5>
                            </Col>
                            <Col xs="4">
                              <p className="text-muted mb-0">Pancard no</p>
                              <h5 className="font-size-15">
                                {student.guardian_pan_num}
                              </h5>
                            </Col>
                            <Col xs="2">
                              <p className="text-muted mb-0">Adhaar Copy</p>
                              <img
                                onClick={() => {
                                  this.setState({
                                    isFits: true,
                                    lightimg: student.guardian_adhaar_copy
                                  });
                                }}
                                className="img-fluid"
                                alt="Adhaar copy"
                                src={student.guardian_adhaar_copy}

                              />
                            </Col>
                            <Col xs="2">
                              <p className="text-muted mb-0">Pancard Copy</p>
                              {student.guardian_pan_copy ? (
                                <img
                                  onClick={() => {
                                    this.setState({
                                      isFits: true,
                                      lightimg: student.guardian_pan_copy
                                    });
                                  }}
                                  className="img-fluid"
                                  alt="Adhaar copy"
                                  src={student.guardian_pan_copy}

                                />
                              ) : null}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>

                  </CardBody>
                </Card>

                <Card className="overflow-hidden">
                  <CardBody>

                    <CardTitle className="mb-4 h4">Guardian 2: {student.guardian_2_name}</CardTitle>
                    <Row>
                      <Col sm="3">
                        <div className="mb-4">
                          <img
                            onClick={() => {
                              this.setState({
                                isFits: true,
                                lightimg: student.guardian_2_photo
                              });
                            }}
                            src={student.guardian_2_photo}
                            alt="father photo"
                            className="img-thumbnail"
                          />
                        </div>
                      </Col>

                      <Col sm={9}>
                        <div className="pt-4">
                          <Row>
                            <Col xs="4">
                              <p className="text-muted mb-0">Phone</p>
                              <h5 className="font-size-15">
                                {student.guardian_2_phone}
                              </h5>
                            </Col>
                            <Col xs="4">
                              <p className="text-muted mb-0">Email</p>
                              <h5 className="font-size-15">
                                {student.guardian_2_email}
                              </h5>

                            </Col>
                            <Col xs="4">
                              <p className="text-muted mb-0">Occupation</p>
                              <h5 className="font-size-15">
                                {student.guardian_2_occupation}
                              </h5>
                            </Col>
                          </Row>
                        </div>
                        <div className="pt-4">
                          <Row>
                            <Col xs="4">
                              <p className="text-muted mb-0">Adhaar</p>
                              <h5 className="font-size-15">
                                {student.guardian_2_adhaar_num}
                              </h5>
                            </Col>
                            <Col xs="4">
                              <p className="text-muted mb-0">Pancard no</p>
                              <h5 className="font-size-15">
                                {student.guardian_2_pan_num}
                              </h5>
                            </Col>
                            <Col xs="2">
                              <p className="text-muted mb-0">Adhaar Copy</p>
                              <img
                                onClick={() => {
                                  this.setState({
                                    isFits: true,
                                    lightimg: student.guardian_2_adhaar_copy
                                  });
                                }}
                                className="img-fluid"
                                alt="Adhaar copy"
                                src={student.guardian_2_adhaar_copy}

                              />
                            </Col>
                            <Col xs="2">
                              <p className="text-muted mb-0">Pancard Copy</p>
                              {student.guardian_2_pan_copy ? (
                                <img
                                  onClick={() => {
                                    this.setState({
                                      isFits: true,
                                      lightimg: student.guardian_2_pan_copy
                                    });
                                  }}
                                  className="img-fluid"
                                  alt="Adhaar copy"
                                  src={student.guardian_2_pan_copy}

                                />
                              ) : null}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>

                  </CardBody>
                </Card>



                <Card>
                  <CardBody>
                    <CardTitle className="mb-4 h4">Other Info</CardTitle>


                  </CardBody>
                </Card>
              </Col>
            </Row>

          </div>

        </div>
      </React.Fragment>
    )
  }
}

export default ViewStudent
