import React, { Component } from "react"
import ReactDOM from "react-dom";
import { Alert, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Modal, Button, Table } from "reactstrap";

import MetaTags from 'react-meta-tags';
import axios from 'axios';
// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"
import { BRAN_URL } from "../../../helpers/url_helper";
// import SweetAlert from "react-bootstrap-sweetalert"
import Switch from 'react-switch';
import Select from 'react-select';
import swal from 'sweetalert';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "../../../components/Common/datatables.scss"
const initialstate = {
  itempageabout: 'Capex | Branch',
  itemmainname: 'Branch',
  itemsubname: 'Capex',
  itemasubonename: 'Room',
  itembsubonename: 'Room Capacity',
  mitemsubonename: 'Branch',
  itemdescriptionname: 'Description',
  itemactionname: 'Add Room',
  tname: 'schrm',
  tdepname: 'dummy',
  tmdepname: 'schbran',
  mitemkey: 'bra_branch',
  clmb: 'clmbrc',
  itemavalue: '',
  itembvalue: '',
  mitemvalue: '',
  descriptionvalue: '',
  select_mitems: [{
    value: '',
    label: (<div>
      Select Branch
    </div>
    ),
  }],
  allitems: [],
  allmitems: [],
  relateditems: [],
  showAlert: false,
  alertType: '',
  alertText: '',
  keyId: 0,
  keyName: '',
  oldkeyname: '',
  newkeyname: '',
  noorid: 0,

  addKeyModal: false,
  akeys: 0,
  bkeys: 0,
  tkeys: 0,
};
class Capex extends Component {
  constructor(props) {
    super(props)
    // this.state = {}
    this.handleGetkeys = this.handleGetkeys.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleDkey = this.handleDkey.bind(this);
    this.handleTkey = this.handleTkey.bind(this);
    this.handleEditkey = this.handleEditkey.bind(this);

  }
  state = { ...initialstate };
  componentDidMount() {

    this.handleGetkeys();


  }

  handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name + ' ' + value);
    this.setState({ [name]: value });
  };
  handleGetkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
      table: this.state.tname,
      deptable: this.state.tdepname,
      mtable: this.state.tmdepname,
      clmb: this.state.clmb,
    };
    const getuurl = BRAN_URL + 'getmabitems';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);
          const allitems = [...response.data.details];
          console.log('allitems', response.data.details);

          var allmitems = [];
          let akeys = 0;

          let tkeys = 0;
          allitems.map((dkey) => {
            akeys = akeys + dkey.status;
            tkeys = tkeys + 1;
            if (dkey.status == 1) {
              allmitems.push({ id: dkey.id, mitemname: dkey.mitemname });
            }
          });

          this.setState({
            akeys: akeys,
            bkeys: tkeys - akeys,
            tkeys: tkeys,
            allmitems: allmitems,
            relateditems: allitems,
            select_mitems: [{
              value: '',
              label: (<div>
                Select Branch
              </div>
              ),
            }],
          }, () => {
            allmitems.map((opmitems) => {
              this.state.select_mitems.push({
                value: opmitems.id,
                label: (
                  <div>
                    {opmitems.mitemname}
                  </div>
                ),
              });
            });
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        // localStorage.clear();
        //this.props.history.push('/'); //eslint-disable-line
      });
  };

  handleValidSubmit = (event, values) => {
    event.preventDefault();
    console.log('values', values);
    if (this.state.mitemvalue == '') {
      this.setState({
        showAlert: true,
        alertType: 'warning',
        alertText: 'Select ' + this.state.mitemsubonename,
      });
    } else {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: localStorage.token,
      };
      var addkeyurl = BRAN_URL + 'addmabitem';
      var postData = {
        itema: values.itema,
        itemb: values.itemb,
        description: values.description,
        mitem: this.state.mitemvalue,
        uid: localStorage.usercode,
        table: this.state.tname,
        mtable: this.state.tmdepname,
        clmb: this.state.clmb,

      };
      if (this.state.editmode) {
        addkeyurl = BRAN_URL + 'editmabitem';
        postData = {
          itemid: this.state.editid,
          itema: values.itema,
          itemb: values.itemb,
          description: values.description,
          mitem: this.state.mitemvalue,
          uid: localStorage.usercode,
          table: this.state.tname,
          mtable: this.state.tmdepname,
          clmb: this.state.clmb,

        };
      } else if (this.state.csvmode) {
        addkeyurl = BRAN_URL + 'csvmabitem';
        postData = {
          mitem: this.state.mitemvalue,
          uid: localStorage.usercode,
          table: this.state.tname,
          mtable: this.state.tmdepname,
          clmb: this.state.clmb,

        };
      }


      axios
        .post(addkeyurl, postData, {
          headers: headers,
        })
        .then((response) => {
          console.log(response.data.message);
          console.log('response.data', response.data);
          if (response.data.error) {
            this.setState({
              showAlert: true,
              alertType: 'warning',
              alertText: response.data.message,
            });
          } else {
            localStorage.setItem("token", response.data.token);
            this.handleGetkeys();
            this.setState(
              {
                addKeyModal: false,
              });
            swal(values.itema + ' ' + response.data.message, {
              icon: 'success',
            });
          }
        })
        .catch((error) => {
          console.log('Error is ', error);
          this.setState({
            alertType: 'danger',
            showAlert: true,
            alertText: 'Failed Try Again Later!',
          });
        });

    }
  };

  handleDkey = (id, item, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'id');
    console.log(item + 'item');

    swal({
      title: 'Delete ' + item,
      text: 'Are you sure?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((keyconfirm) => {
      if (keyconfirm) {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: localStorage.token,
        };

        const delkeyurl = BRAN_URL + 'delitem';
        var postData = {
          keyid: id,
          uid: localStorage.usercode,
          table: this.state.tname,
        };
        axios
          .post(delkeyurl, postData, {
            headers: headers,
          })
          .then((response) => {
            console.log(response.data.message);

            if (response.data.error) {
              swal(item + ' ' + response.data.message, {
                icon: 'warning',
              });
            } else {
              localStorage.setItem("token", response.data.token);
              this.handleGetkeys();
              swal(item + ' ' + response.data.message, {
                icon: 'success',
              });
            }
          })
          .catch((error) => {
            console.log('Error is ', error);
            swal('Try Again Later', {
              icon: 'warning',
            });
          });
      }
    });
  };
  handleTkey = (id, item, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'id');
    console.log(item + 'item');
    var keysts = checked == true ? 1 : 0;
    var kstatus = 'Deactivate';
    if (checked) {
      kstatus = 'Activate';
    }
    swal({
      title: kstatus + ' ' + item,
      text: 'Are you sure?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((keyconfirm) => {
      if (keyconfirm) {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: localStorage.token,
        };

        const togkeyurl = BRAN_URL + 'togitem';
        var postData = {
          keyid: id,
          kstatus: keysts,
          uid: localStorage.usercode,
          table: this.state.tname,

        };
        axios
          .post(togkeyurl, postData, {
            headers: headers,
          })
          .then((response) => {
            console.log(response.data.message);

            if (response.data.error) {
              swal(item + ' ' + response.data.message, {
                icon: 'warning',
              });
            } else {
              localStorage.setItem("token", response.data.token);
              this.handleGetkeys();
              swal(item + ' ' + response.data.message, {
                icon: 'success',
              });
            }
          })
          .catch((error) => {
            console.log('Error is ', error);
            swal('Try Again Later', {
              icon: 'Danger',
            });
          });
      }
    });
  };

  handleAkey = (id, name) => {
    console.log(id + 'id');
    console.log(name + 'name');
    this.setState({
      addKeyModal: true,
      itemactionname: 'Add to ' + name + 'Branch',
      editmode: false,
      itemavalue: '',
      itembvalue: '',
      mitemvalue: id,
      descriptionvalue: '',
    });
  };
  handleCkey = () => {
    this.setState({
      addKeyModal: false,
      addCsvModal: false,
      editmode: false,
      itemavalue: '',
      itembvalue: '',
      mitemvalue: '',
      descriptionvalue: '',

    });
  };
  handleEditkey = (id, itema, itemb, mitem, description, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'id');
    console.log(itema + 'itema');
    console.log(itemb + 'itemb');
    console.log(mitem + 'mitem');
    console.log(description + 'description');
    this.setState({
      addKeyModal: true,
      editmode: true,
      editid: id,
      itemavalue: itema,
      itembvalue: itemb,
      mitemvalue: mitem,
      descriptionvalue: description,
      itemactionname: 'Edit ' + this.state.itemasubonename,
    });
  };

  handleCSVkey = (mitem, checked) => {
    console.log(checked + 'checked');
    console.log(mitem + 'mitem');
    this.setState({
      addCsvModal: true,
      addKeyModal: false,
      mitemvalue: mitem,
      itemactionname: 'CSV Upload ' + this.state.itemasubonename,
    });
  };

  handleSelectMitemChange = (selectedOption) => {
    let mitemvalue = selectedOption.value;
    this.setState({ mitemvalue: mitemvalue });
  };

  render() {

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.state.itempageabout} </title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title={this.state.itemmainname} breadcrumbItem={this.state.itemsubname} />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">{this.state.itemsubname}

                    </CardTitle>
                    <p className="card-title-desc">Some Description</p>


                  </CardBody>
                </Card>
              </Col>
              {this.state.relateditems &&
                this.state.relateditems.map((mitem, index) => {
                  var allitems = mitem.itemdetails;
                  return (
                    <Col className="col-12" key={index}>
                      <Card>
                        <CardBody>
                          <CardTitle className="h4">{mitem.mitemname}
                            <Button
                              className="float-end btn-sm"
                              color="success"
                              onClick={this.handleAkey.bind(this, mitem.id, mitem.mitemname)}
                            >
                              +
							            	</Button>
                          </CardTitle>


                          <div className="table-responsive">
                            <Table className="table mb-0">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>{this.state.itemasubonename}</th>
                                  <th>{this.state.itembsubonename}</th>
                                  <th>Description</th>
                                  <th>date Added</th>
                                  <th colSpan='2'>Manage</th>
                                </tr>
                              </thead>
                              <tbody>
                                {allitems &&
                                  allitems.map((item, index) => {
                                    var ksts = false;
                                    if (item.status == 1) {
                                      ksts = true;
                                    }

                                    return (
                                      <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>
                                          <span className="float-start"
                                            style={{ marginRight: '10px', cursor: 'pointer' }}
                                            onClick={this.handleEditkey.bind(
                                              this,
                                              item.id,
                                              item.itemaname,
                                              item.itembname,
                                              mitem.id,
                                              item.description,
                                            )}
                                          >
                                            <i className="mdi mdi-pencil d-block font-size-16"></i>{" "}{" "}
                                          </span>
                                          <strong>{" "}{item.itemaname}</strong>
                                        </td>
                                        <td>{item.itembname}</td>
                                        <td>{item.description}</td>
                                        <td>{item.posted}</td>
                                        <td>
                                          <Switch
                                            onChange={this.handleTkey.bind(
                                              this,
                                              item.id,
                                              item.itemname,
                                            )}
                                            checked={ksts}

                                          />
                                        </td>

                                        <td>

                                          {item.del == 0 ? (
                                            <button
                                              className="btn btn-danger btn-sm"
                                              color="primary"
                                              id={item.id}
                                              onClick={this.handleDkey.bind(
                                                this,
                                                item.id,
                                                item.itemname,
                                              )}
                                            >
                                              <i className="mdi mdi-trash-can d-block font-size-16"></i>{" "}
                                            </button>
                                          ) : null}
                                        </td>
                                      </tr>
                                    );
                                  })}

                              </tbody>
                            </Table>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  )
                })}
            </Row>
          </div>
          <Modal
            isOpen={this.state.addKeyModal}

            centered={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">{this.state.itemactionname} </h5>
              <button
                type="button"
                onClick={this.handleCkey}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">


              <AvForm
                className="needs-validation"
                onValidSubmit={this.handleValidSubmit}
              >
                {this.state.showAlert ? (
                  <Alert color={this.state.alertType}>
                    <p>{this.state.alertText}</p>
                  </Alert>
                ) : null}

                <div className="mb-3">

                  <div className="form__form-group">
                    <span className="form__form-group-label">{this.state.mitemsubonename} </span>
                    <div className="form__form-group-field" style={{ height: '30px' }}>

                      <Select
                        name="mitem"
                        value={this.state.select_mitems.filter(
                          (option) => option.value == this.state.mitemvalue,
                        )}
                        onChange={this.handleSelectMitemChange}
                        options={this.state.select_mitems}
                        clearable={false}
                        className="react-select-team"
                        classNamePrefix="react-select"
                        // defaultMenuIsOpen={true}
                        isDisabled={true}
                      />
                    </div>
                  </div>

                </div>
                <div className="mb-3">
                  <AvField
                    name="itema"
                    label={this.state.itemasubonename}
                    value={this.state.itemavalue}
                    type="text"
                    required
                    placeholder={'Enter ' + this.state.itemasubonename}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    name="itemb"
                    label={this.state.itembsubonename}
                    value={this.state.itembvalue}
                    required
                    placeholder={'Enter ' + this.state.itembsubonename}
                    type="number"
                    errorMessage="Enter Only Number"
                    validate={{
                      required: { value: true },
                      pattern: {
                        value: "^[0-9]+$",
                        errorMessage: "Only Numbers",
                      },
                    }}
                  />

                </div>

                <div className="mb-3">
                  <AvField
                    name="description"
                    label={this.state.itemdescriptionname}
                    value={this.state.descriptionvalue}
                    type="text"
                    required
                    placeholder={'Enter ' + this.state.itemdescriptionname}
                  />

                </div>

                <div className="mt-4 d-grid">
                  <button
                    className="btn btn-primary btn-block waves-effect waves-light"
                    type="submit"
                  >
                    {this.state.itemactionname}
                  </button>
                </div>


              </AvForm>
            </div>
          </Modal>

        </div>
      </React.Fragment>
    )
  }
}

export default Capex
