import React, { Component } from "react"
import {
  Alert, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Modal, Button, Table
} from "reactstrap";
// import classnames from "classnames"
import MetaTags from 'react-meta-tags';
import axios from 'axios';
// availity-reactstrap-validation
import { AvField, AvForm, AvGroup, AvInput, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import { SCH_URL } from "../../../helpers/url_helper";
// import SweetAlert from "react-bootstrap-sweetalert"
// import Switch from 'react-switch';
import Select from 'react-select';
import swal from 'sweetalert';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "../../../components/Common/datatables.scss"
const initialstate = {
  customActiveTab: 1,
  itempageabout: 'Manage School Structure Modules | ',

  itemsubname: 'Manage School Structure Modules',
  itemsubonename: 'Structure',
  tname: 'astruc',
  tdepname: 'schstruc',

  itemasubonename: 'Section',
  itembsubonename: 'Section Capacity',
  itemdescriptionname: 'Description',
  itemactionname: 'Add Section',

  allitems: [],
  relateditems: [],
  defaultValues: {},
  allmsubs: [],
  showAlert: false,
  alertType: '',
  alertText: '',

  addKeyModal: false,
  akeys: 0,
  bkeys: 0,
  tkeys: 0,
  select_feetypes: [{
    value: '',
    valueid: '',
    label: (<div>
      Select Fee Type
    </div>
    ),
  }],
  select_notitypes: [{
    value: '',
    valueid: '',
    label: (<div>
      Select Notification Type
    </div>
    ),
  }],
  select_notiintvals: [{
    value: '',
    valueid: '',
    label: (<div>
      Select Notification Intervel
    </div>
    ),
  }],

};
class SchSections extends Component {
  constructor(props) {
    super(props)
    // this.state = {}

    this.handleGetkeys = this.handleGetkeys.bind(this);
    this.addSec = this.addSec.bind(this);

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleDkey = this.handleDkey.bind(this);
    this.handleRoomkey = this.handleRoomkey.bind(this);
    this.handleTeacherkey = this.handleTeacherkey.bind(this);
  }
  state = { ...initialstate };
  componentDidMount() {
    if (localStorage.schboardid) {
      this.handleGetkeys();

    } else {
      this.props.history.push('/dashboard'); //eslint-disable-line
    }



  }

  handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name + ' ' + value);
    this.setState({ [name]: value });
  };
  handleGetkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
      mitem: localStorage.schboardid,
      mmitem: localStorage.schstrucid,
      branid: localStorage.schbranchid,
    };
    const getuurl = SCH_URL + 'getschoolstructuresections';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);
          const allitems = [...response.data.allitems];
          console.log('allitems', response.data.allitems);
          const allfeetypes = [...response.data.feetypes];
          const allnotitypes = [...response.data.notitypes];
          const allnotiintvals = [...response.data.notiintvals];



          this.setState({

            allitems: response.data.allitems,
            relateditems: response.data.allitems,
            allweekdays: response.data.weekdays,
            select_feetypes: [{
              value: '',
              valueid: '',
              label: (<div>
                Select Room
              </div>
              ),
            }],
            select_notitypes: [{
              value: '',
              valueid: '',
              label: (<div>
                Select Teacher
              </div>
              ),
            }],
            select_notiintvals: [{
              value: '',
              valueid: '',
              label: (<div>
                Select Notification Intervel
              </div>
              ),
            }],

          }, () => {
            if (allfeetypes.length >= 1) {
              allfeetypes.map((item) => {
                this.state.select_feetypes.push({
                  value: item.itemname,
                  valueid: item.id,
                  label: (
                    <div>
                      {item.itemname}/{item.itemtwo}
                    </div>
                  ),
                });
              });
            }
            if (allnotitypes.length >= 1) {
              allnotitypes.map((item) => {
                this.state.select_notitypes.push({
                  value: item.itemname,
                  label: (
                    <div>
                      {item.itemname}/{item.itemtwo}
                    </div>
                  ),
                });
              });
            }
            if (allnotiintvals.length >= 1) {
              allnotiintvals.map((item) => {
                this.state.select_notiintvals.push({
                  value: item.itemname,
                  label: (
                    <div>
                      {item.itemname}/{item.itemtwo}
                    </div>
                  ),
                });
              });
            }
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        // localStorage.clear();
        //this.props.history.push('/'); //eslint-disable-line
      });
  };


  handleDkey = (id, item, tble, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'id');
    console.log(item + 'item');

    swal({
      title: 'Delete ' + item,
      text: 'Are you sure?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((keyconfirm) => {
      if (keyconfirm) {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: localStorage.token,
        };

        const delkeyurl = SCH_URL + 'delitem';
        var postData = {
          keyid: id,
          uid: localStorage.usercode,
          table: tble,
        };
        axios
          .post(delkeyurl, postData, {
            headers: headers,
          })
          .then((response) => {
            console.log(response.data.message);

            if (response.data.error) {
              swal(item + ' ' + response.data.message, {
                icon: 'warning',
              });
            } else {
              localStorage.setItem("token", response.data.token);
              this.handleGetkeys();
              swal(item + ' ' + response.data.message, {
                icon: 'success',
              });
            }
          })
          .catch((error) => {
            console.log('Error is ', error);
            swal('Try Again Later', {
              icon: 'warning',
            });
          });
      }
    });
  };

  handleValidSubmit = (event, values) => {
    event.preventDefault();
    console.log('values', values);
    var msecsubs = JSON.stringify(values.msecsubs);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var addkeyurl = SCH_URL + 'addmmabitem';
    var postData = {
      itema: values.itema,
      itemb: values.itemb,
      description: values.description,
      msecsubs: msecsubs,
      mitem: this.state.mitemvalue,
      mmitem: localStorage.schstrucid,
      uid: localStorage.usercode,
      table: 'schsec',
      mtable: 'mcl',
      mmtable: 'schstruc',
      clmb: 'clmbsc',

    };
    if (this.state.editmode) {
      addkeyurl = SCH_URL + 'editmmabitem';
      postData = {
        itemid: this.state.editid,
        itema: values.itema,
        itemb: values.itemb,
        description: values.description,
        mitem: this.state.mitemvalue,
        mmitem: localStorage.schstrucid,
        uid: localStorage.usercode,
        table: 'schsec',
        mtable: 'mcl',
        mmtable: 'schstruc',
        clmb: 'clmbsc',

      };
    }

    axios
      .post(addkeyurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log(response.data.message);
        console.log('response.data', response.data);
        if (response.data.error) {
          this.setState({
            showAlert: true,
            alertType: 'warning',
            alertText: response.data.message,
          });
        } else {
          localStorage.setItem("token", response.data.token);
          this.handleGetkeys();
          this.setState(
            {
              addKeyModal: false,
            });
          swal(values.itema + ' ' + response.data.message, {
            icon: 'success',
          });
        }
      })
      .catch((error) => {
        console.log('Error is ', error);
        this.setState({
          alertType: 'danger',
          showAlert: true,
          alertText: 'Failed Try Again Later!',
        });
      });


  };
  addSec = (id, name, msubs) => {
    const defaultValues = {
      msecsubs: [],
      itema: '',
      itemb: '',
      description: '',
    };
    console.log(id + 'id');
    console.log(name + 'name');
    console.log('msubs', msubs);
    this.setState({
      addKeyModal: true,
      addRoomModal: false,
      itemactionname: 'Add Section to ' + name,
      editmode: false,
      mitemvalue: id,
      allmsubs: msubs,
      defaultValues: defaultValues,
    });
  };
  handleEditkey = (id, itema, itemb, mitem, description, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'id');
    console.log(itema + 'itema');
    console.log(itemb + 'itemb');
    console.log(mitem + 'mitem');
    console.log(description + 'description');
    const defaultValues = {
      msecsubs: [],
      itema: itema,
      itemb: itemb,
      description: description,
    };
    this.setState({
      addKeyModal: true,
      editmode: true,
      editid: id,
      mitemvalue: mitem,
      allmsubs: [],
      defaultValues: defaultValues,
      itemactionname: 'Edit ' + this.state.itemasubonename,
    });
  };


  handleCkey = () => {
    this.setState({
      addKeyModal: false,
      addRoomModal: false,
      addTeacherModal: false,
      showAlert: false,
      itemvalue: '',

    });
  };

  handleRoomkey = (id, checked) => {
    console.log(id + 'id');
    this.setState({
      addRoomModal: true,
      addTeacherModal: false,
      addKeyModal: false,
      msecid: id,
      itemactionname: 'Assign Room ',
    });
  };

  handleTeacherkey = (id, checked) => {
    console.log(id + 'id');
    this.setState({
      addTeacherModal: true,
      addRoomModal: false,
      addKeyModal: false,
      secsubid: id,
      itemactionname: 'Assign Teacher ',
    });
  };

  handleValidTeacherSubmit = (event, values) => {
    event.preventDefault();
    console.log('values', values);
    if (this.state.teachervalue == '') {
      this.setState({
        showAlert: true,
        alertType: 'warning',
        alertText: 'Select Teacher',
      });
    } else {


      const headers = {
        'Content-Type': 'application/json',
        Authorization: localStorage.token,
      };
      var addkeyurl = SCH_URL + 'linkteacher';
      var postData = {
        teacherid: this.state.teachervalue,
        secsubid: this.state.secsubid,
        uid: localStorage.usercode,
        table: 'schsecsub',
      };

      axios
        .post(addkeyurl, postData, {
          headers: headers,
        })
        .then((response) => {
          console.log(response.data.message);
          if (response.data.error) {
            this.setState({
              showAlert: true,
              alertType: 'warning',
              alertText: response.data.message,
            });
          } else {
            localStorage.setItem("token", response.data.token);
            this.handleGetkeys();
            this.setState(
              {
                addTeacherModal: false,
                msecsubid: '',
                teachervalue: '',
              });
            swal('Teacher ' + response.data.message, {
              icon: 'success',
            });
          }
        })
        .catch((error) => {
          console.log('Error is ', error);
          this.setState({
            alertType: 'danger',
            showAlert: true,
            alertText: 'Failed Try Again Later!',
          });
        });

    }
  };

  handleSelectroomChange = (selectedOption) => {
    let roomvalue = selectedOption.value;
    this.setState({ roomvalue: roomvalue, roomvaluename: selectedOption.label.props.children, });
  };

  handleSelectteacherChange = (selectedOption) => {
    let teachervalue = selectedOption.value;
    this.setState({ teachervalue: teachervalue, teachervaluename: selectedOption.label.props.children, });
  };

  handleValidRoomSubmit = (event, values) => {
    event.preventDefault();
    console.log('values', values);
    if (this.state.roomvalue == '') {
      this.setState({
        showAlert: true,
        alertType: 'warning',
        alertText: 'Select Room',
      });
    } else {


      const headers = {
        'Content-Type': 'application/json',
        Authorization: localStorage.token,
      };
      var addkeyurl = SCH_URL + 'linkroom';
      var postData = {
        roomid: this.state.roomvalue,
        secid: this.state.msecid,
        uid: localStorage.usercode,
        table: 'schsec',
      };

      axios
        .post(addkeyurl, postData, {
          headers: headers,
        })
        .then((response) => {
          console.log(response.data.message);
          if (response.data.error) {
            this.setState({
              showAlert: true,
              alertType: 'warning',
              alertText: response.data.message,
            });
          } else {
            localStorage.setItem("token", response.data.token);
            this.handleGetkeys();
            this.setState(
              {
                addRoomModal: false,
                msecid: '',
                roomvalue: '',
              });
            swal('Room ' + response.data.message, {
              icon: 'success',
            });
          }
        })
        .catch((error) => {
          console.log('Error is ', error);
          this.setState({
            alertType: 'danger',
            showAlert: true,
            alertText: 'Failed Try Again Later!',
          });
        });

    }
  };


  render() {

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.state.itempageabout}{localStorage.boardname} </title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title={localStorage.acaboardname} breadcrumbItem={this.state.itemsubname} />


            <Card>
              <CardBody>
                <p className="card-title-desc onclicklink" onClick={() => {
                  this.props.history.push('/dashboard'); //eslint-disable-line
                }}>Back to Boards</p>




                {this.state.relateditems &&
                  this.state.relateditems.map((mitem, schindex) => {

                    return (
                      <div key={schindex}>
                        {mitem.structure_dtls && mitem.structure_dtls.map((mcat, mcatindex) => {

                          return (
                            <div className="col-md-12" key={mcatindex}>
                              <Card className="catcard">
                                <CardBody>
                                  <CardTitle className="h4">{mcat.mcatname}
                                  </CardTitle>
                                </CardBody>
                              </Card>


                              {mcat.mcls.length >= 1 ? (

                                <>
                                  {mcat.mcls && mcat.mcls.map((mclass, mclsindex) => {


                                    return (
                                      <div key={mclsindex}>
                                        <Card>
                                          <CardBody>
                                            <CardTitle className="h4"><i className="mdi mdi-chevron-right" /> {mclass.mclname}
                                            </CardTitle>

                                            {mclass.clsections &&
                                              mclass.clsections.map((msec, secindex) => {
                                                return (


                                                  <Card>
                                                    <CardBody>
                                                      <CardTitle className="h4"><i className="mdi mdi-chevron-right" /> {msec.secname}
                                                      </CardTitle>
                                                      <p className="card-title-desc">{msec.description}</p>
                                                      <div className="row">
                                                        <div className="col-md-6">
                                                          <span className="as-class clearfix"><i className="mdi mdi-chevron-right" />  Time Table Templates
                                                        <span
                                                              className="xmarl10 btn btn-sm btn-success"
                                                              color="primary" onClick={this.handleTeacherkey.bind(this)}><i className="bx bx-plus font-size-16 align-middle ms-1" />  </span>
                                                          </span>
                                                          {msec.timetemplates && msec.timetemplates.map((timetemplate, ttindex) => {
                                                            return (
                                                              <span key={ttindex}>{timetemplate.timetemplate}</span>
                                                            )
                                                          })}
                                                        </div>
                                                        <div className="col-md-6">
                                                          <span className="as-class clearfix"><i className="mdi mdi-chevron-right" />  Fee Structure
                                                      <span
                                                              className="xmarl10 btn btn-sm btn-success"
                                                              color="primary" onClick={this.handleTeacherkey.bind(this)}><i className="bx bx-plus font-size-16 align-middle ms-1" />  </span>

                                                          </span>
                                                          {msec.secfees && msec.secfees.map((secfee, sfindex) => {
                                                            return (
                                                              <span key={sfindex}>{secfee.secfee}</span>
                                                            )
                                                          })}

                                                        </div>

                                                      </div>
                                                    </CardBody>
                                                  </Card>
                                                )
                                              })}


                                          </CardBody>
                                        </Card>

                                      </div>
                                    )
                                  })}
                                </>

                              ) : (
                                <Card>
                                  <CardBody>
                                    <div className="row">
                                      <div className="col-md-2">
                                        No Classes
                                    </div>
                                      <div className="col-md-10">No Subjects</div>
                                    </div>
                                  </CardBody>
                                </Card>

                              )}



                            </div>
                          )
                        })}
                      </div>
                    );
                  })}


              </CardBody>
            </Card>

          </div>
          <Modal
            isOpen={this.state.addKeyModal}

            centered={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">{this.state.itemactionname} </h5>
              <button
                type="button"
                onClick={this.handleCkey}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">


              <AvForm
                className="needs-validation"
                onValidSubmit={this.handleValidSubmit} model={this.state.defaultValues}
              >
                {this.state.showAlert ? (
                  <Alert color={this.state.alertType}>
                    <p>{this.state.alertText}</p>
                  </Alert>
                ) : null}


                <div className="mb-3">
                  <AvField
                    name="itema"
                    label={this.state.itemasubonename}
                    type="text"
                    required
                    placeholder={'Enter ' + this.state.itemasubonename}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    name="itemb"
                    label={this.state.itembsubonename}
                    required
                    placeholder={'Enter ' + this.state.itembsubonename}
                    type="number"
                    errorMessage="Enter Only Number"
                    validate={{
                      required: { value: true },
                      pattern: {
                        value: "^[0-9]+$",
                        errorMessage: "Only Numbers",
                      },
                    }}
                  />

                </div>

                <div className="mb-3">
                  <AvField
                    name="description"
                    label={this.state.itemdescriptionname}
                    type="text"
                    // required
                    placeholder={'Enter ' + this.state.itemdescriptionname}
                  />

                </div>
                {this.state.editmode ? null : (
                  <div className="mb-3">
                    <AvCheckboxGroup name="msecsubs" label="Select Subjects" required>
                      {this.state.allmsubs && this.state.allmsubs.map((allmsub, index) => {
                        return (
                          <AvCheckbox key={index} label={allmsub.msubname} value={allmsub.msubid} />
                        )
                      })}
                    </AvCheckboxGroup>
                  </div>
                )}
                <div className="mt-4 d-grid">
                  <button
                    className="btn btn-primary btn-block waves-effect waves-light"
                    type="submit"
                  >
                    {this.state.itemactionname}
                  </button>
                </div>


              </AvForm>
            </div>
          </Modal>

          <Modal
            isOpen={this.state.addRoomModal}

            centered={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">{this.state.itemactionname} </h5>
              <button
                type="button"
                onClick={this.handleCkey}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">



              <AvForm
                className="needs-validation"
                onValidSubmit={this.handleValidRoomSubmit}
              >
                {this.state.showAlert ? (
                  <Alert color={this.state.alertType}>
                    <p>{this.state.alertText}</p>
                  </Alert>
                ) : null}

                {/* schstrucid */}

                <div className="mb-3">
                  <div className="form-group">
                    <label className="">Rooms</label>
                    <div className="form__form-group-field" style={{ height: '30px' }}>
                      <Select
                        name="roomvalue"
                        value={this.state.select_feetypes.filter(
                          (option) => option.value == this.state.roomvalue,
                        )}
                        onChange={this.handleSelectroomChange}
                        options={this.state.select_feetypes}
                        clearable={false}
                        className="react-select-team"
                        classNamePrefix="react-select"
                      />
                    </div>
                  </div>
                </div>






                <div className="mt-4 d-grid">

                  <button
                    className="btn btn-primary btn-block waves-effect waves-light"
                    type="submit" style={{ marginTop: '18px' }}
                  >
                    {this.state.itemactionname}
                  </button>
                </div>


              </AvForm>

            </div>
          </Modal>
          <Modal
            isOpen={this.state.addTeacherModal}

            centered={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">{this.state.itemactionname} </h5>
              <button
                type="button"
                onClick={this.handleCkey}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">



              <AvForm
                className="needs-validation"
                onValidSubmit={this.handleValidTeacherSubmit}
              >
                {this.state.showAlert ? (
                  <Alert color={this.state.alertType}>
                    <p>{this.state.alertText}</p>
                  </Alert>
                ) : null}

                {/* schstrucid */}

                <div className="mb-3">
                  <div className="form-group">
                    <label className="">Teacher / Employee ID</label>
                    <div className="form__form-group-field" style={{ height: '30px' }}>
                      <Select
                        name="teachervalue"
                        value={this.state.select_notitypes.filter(
                          (option) => option.value == this.state.teachervalue,
                        )}
                        onChange={this.handleSelectteacherChange}
                        options={this.state.select_notitypes}
                        clearable={false}
                        className="react-select-team"
                        classNamePrefix="react-select"
                      />
                    </div>
                  </div>
                </div>






                <div className="mt-4 d-grid">

                  <button
                    className="btn btn-primary btn-block waves-effect waves-light"
                    type="submit" style={{ marginTop: '18px' }}
                  >
                    {this.state.itemactionname}
                  </button>
                </div>


              </AvForm>

            </div>
          </Modal>
        </div>
      </React.Fragment>
    )
  }
}

export default SchSections
