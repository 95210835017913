import React, { Component } from "react";
import axios from 'axios';
import {
  Alert, Row, Col, Card, CardBody, CardTitle, CardImg, CardText, CardSubtitle, Modal, Button, FormGroup, InputGroup, Label, Table, Container,
  Media,
} from "reactstrap";
import { TEACH_URL } from "../../../helpers/url_helper";
import MetaTags from 'react-meta-tags';
//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "../../../components/Common/datatables.scss"
const initialstate = {
  itempageabout: 'View Teacher | Management',
  itemmainname: 'Teacher Management',
  itemsubname: 'View Teacher',
  itemsubonename: 'Teacher',
  tname: 'schtea',
  tdepname: 'dummy',
  itemvalue: '',
  teacher: [],
  showAlert: false,
  alertType: '',
  alertText: '',
  isFits: false,

};

class ViewTeacher extends Component {
  constructor(props) {
    super(props)
    // this.state = {}
    this.handleGetkeys = this.handleGetkeys.bind(this);
    this.handleEditkey = this.handleEditkey.bind(this);
    this.handleAkey = this.handleAkey.bind(this);

    if (localStorage.teaid) {
      this.handleGetkeys();
    } else {
      this.props.history.push('/teachers'); //eslint-disable-line
    }
  }
  state = { ...initialstate };
  componentDidMount() {

  }
  handleAkey = () => {
    localStorage.setItem('teaid', '');
    localStorage.setItem('teadob', '');
    localStorage.setItem('teajoining_date', '');
    localStorage.setItem('whattodoteacher', 'Add Teacher');
    localStorage.setItem('teacherdata', '');
    this.props.history.push('/mngteacher'); //eslint-disable-line
  };
  handleEditkey = (teaid) => {
    localStorage.setItem('whattodoteacher', 'Edit Teacher');
    localStorage.setItem('teaid', teaid);
    this.props.history.push('/mngteacher'); //eslint-disable-line
  };

  handleGetkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
      table: this.state.tname,
      teaid: localStorage.teaid,
    };
    const getuurl = TEACH_URL + 'getviewteadetails';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);

          this.setState({
            teacher: response.data.allitems,
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        localStorage.clear();
        this.props.history.push('/'); //eslint-disable-line
      });
  };
  render() {
    const { teacher } = this.state;
    console.log('teacher', teacher.itemname);
    console.log('teacher', teacher.photo);
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.state.itempageabout} </title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title={this.state.itemmainname} breadcrumbItem={this.state.itemsubname} />
            {this.state.isFits ? (
              <Lightbox
                mainSrc={this.state.lightimg}
                enableZoom={false}
                imageCaption={
                  "Caption. Can be aligned it to any side and contain any HTML."
                }
                onCloseRequest={() => this.setState({ isFits: false })}
              />
            ) : null}
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">
                      <span className="card-title-desc onclicklink"
                        onClick={() => {
                          this.props.history.push('/teachers'); //eslint-disable-line
                        }}
                      >Back to Teachers</span>
                      <Button
                        className="float-end btn-sm"
                        color="success"
                        onClick={this.handleAkey}
                      >
                        + Add Teacher
						        		</Button>
                    </CardTitle>

                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl="4">
                <Card className="overflow-hidden">
                  {/* <div className="bg-primary bg-soft">
                    <Row>
                      <Col xs="7">
                        <div className="text-primary p-3">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p>It will seem like simplified</p>
                        </div>
                      </Col>
                      <Col xs="5" className="align-self-end">
                        <img src={teacher.photo} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div> */}
                  <CardBody>
                    <Row>
                      <Col sm="4">
                        <div className="avatar-md mb-4">
                          <img
                            onClick={() => {
                              this.setState({
                                isFits: true,
                                lightimg: teacher.photo
                              });
                            }}
                            src={teacher.photo}
                            alt=""
                            className="img-thumbnail rounded-circle"
                          />
                        </div>

                      </Col>

                      <Col sm={8}>
                        <div className="pt-4">
                          <Row>
                            <Col xs="6">
                              <h5 className="font-size-15">
                                {teacher.joining_date}
                              </h5>
                              <p className="text-muted mb-0">Joined</p>
                            </Col>
                            <Col xs="6">
                              <h5 className="font-size-15">
                                {teacher.employee_id}
                              </h5>
                              <p className="text-muted mb-0">Employee ID</p>

                            </Col>

                          </Row>

                        </div>
                      </Col>
                      <Col sm={8}>
                        <h5 className="font-size-15 text-truncate">
                          {teacher.itemname}
                        </h5>
                        <p className="text-muted mb-0 text-truncate">
                          Designation: {teacher.designation}
                        </p>
                      </Col>
                      <Col sm={4}>
                        <Button
                          className="float-end btn-sm"
                          color="success"
                          onClick={this.handleEditkey.bind(this, teacher.id)}
                        >
                          Edit Details
								               </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle className="mb-4 h4">Personal Information</CardTitle>


                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">Full Name :</th>
                            <td>{teacher.itemname}</td>
                          </tr>
                          <tr>
                            <th scope="row">Mobile :</th>
                            <td>{teacher.phone}</td>
                          </tr>
                          <tr>
                            <th scope="row">E-mail :</th>
                            <td>{teacher.email}</td>
                          </tr>
                          <tr>
                            <th scope="row">Gender :</th>
                            <td>{teacher.gender}</td>
                          </tr>
                          <tr>
                            <th scope="row">DOB :</th>
                            <td>{teacher.dob}</td>
                          </tr>
                          <tr>
                            <th scope="row">Religion :</th>
                            <td>{teacher.religion}</td>
                          </tr>
                          <tr>
                            <th scope="row">Caste :{teacher.ccaste} </th>
                            <td>{teacher.caste}</td>
                          </tr>
                          <tr>
                            <th scope="row">Address :</th>
                            <td>{teacher.address}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>


                <Card>
                  <CardBody>
                    <CardTitle className="mb-4 h4">Qualification Information</CardTitle>


                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">Qualification :</th>
                            <td>{teacher.qualification}</td>
                          </tr>
                          <tr>
                            <th scope="row">Details :</th>
                            <td>{teacher.qualification_description}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>


                <Card>
                  <CardBody>
                    <CardTitle className="mb-4 h4">Identity Information</CardTitle>


                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">Adhaar :</th>
                            <td>{teacher.adhaar_num}</td>
                          </tr>
                          <tr>
                            <th scope="row">Adhaar Copy :</th>
                            <td><img
                              onClick={() => {
                                this.setState({
                                  isFits: true,
                                  lightimg: teacher.adhaar_copy
                                });
                              }}
                              className="img-fluid"
                              alt="Adhaar copy"
                              src={teacher.adhaar_copy}
                              width="35"
                            /></td>
                          </tr>
                          <tr>
                            <th scope="row">PF no :</th>
                            <td>{teacher.pf_num}</td>
                          </tr>
                          <tr>
                            <th scope="row">ESI no :</th>
                            <td>{teacher.esi_num}</td>
                          </tr>
                          <tr>
                            <th scope="row">Pancard no :</th>
                            <td>{teacher.pancard_num}</td>
                          </tr>
                          <tr>
                            <th scope="row">Pancard Copy :</th>
                            <td><img
                              onClick={() => {
                                this.setState({
                                  isFits: true,
                                  lightimg: teacher.pancard_copy
                                });
                              }}
                              className="img-fluid"
                              alt="Adhaar copy"
                              src={teacher.pancard_copy}
                              width="35"
                            /></td>
                          </tr>

                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>


                <Card>
                  <CardBody>
                    <CardTitle className="mb-4 h4">Bank Information</CardTitle>


                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">Bank Name :</th>
                            <td>{teacher.bank_name}</td>
                          </tr>
                          <tr>
                            <th scope="row">Account no :</th>
                            <td>{teacher.account_num}</td>
                          </tr>
                          <tr>
                            <th scope="row">Branch Name :</th>
                            <td>{teacher.branch_name}</td>
                          </tr>
                          <tr>
                            <th scope="row">IFSC code :</th>
                            <td>{teacher.ifsc_code}</td>
                          </tr>
                          <tr>
                            <th scope="row">MICR code :</th>
                            <td>{teacher.micr_code}</td>
                          </tr>
                          <tr>
                            <th scope="row">SWIFT code :</th>
                            <td>{teacher.swift_code}</td>
                          </tr>

                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>

              </Col>

              <Col xl="8">


                <Card>
                  <CardBody>
                    <CardTitle className="mb-4 h4">My Projects</CardTitle>
                    {teacher.designation}
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </div>

        </div>
      </React.Fragment>
    )
  }
}

export default ViewTeacher
