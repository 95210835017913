import PropTypes from 'prop-types'
import React, { Component } from "react"



import { connect } from "react-redux"
import { Row, Col } from "reactstrap"

import { Link } from "react-router-dom"

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"

// Import menuDropdown

import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"


import megamenuImg from "../../assets/images/megamenu-img.png"


// import images
import github from "../../assets/images/brands/github.png"
import bitbucket from "../../assets/images/brands/bitbucket.png"
import dribbble from "../../assets/images/brands/dribbble.png"
import dropbox from "../../assets/images/brands/dropbox.png"
import mail_chimp from "../../assets/images/brands/mail_chimp.png"
import slack from "../../assets/images/brands/slack.png"


import logo from "../../assets/images/logo-sm-light.png"
// import logoLight from "../../assets/images/logo-light.png"
// import logoLightSvg from "../../assets/images/logo-light.svg"
import logoDark from "../../assets/images/logo-dark.png"

//i18n
import { withTranslation } from "react-i18next"

// Redux Store


class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSearch: false,
      open: false,
      position: 'right',
    }
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.onDrawerClose = this.onDrawerClose.bind(this);
  }
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.toggleMenuCallback()
  }

  closeDrawer() {
    this.setState({ open: false });
  }
  onDrawerClose() {
    this.setState({ open: false });
  }



  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">

            <div className="d-flex">
              <div className="navbar-brand-box d-lg-block d-md-block">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="17" />
                  </span>
                </Link>
              </div>

              <button
                type="button"
                onClick={this.toggleMenu}
                className="btn btn-sm px-3 font-size-16 header-item"
                id="vertical-menu-btn"
              >
                <i className="fa fa-fw fa-bars"></i>
              </button>

              <Dropdown
                className="dropdown-mega d-none d-lg-block ms-2"
                isOpen={this.state.megaMenuDrp}
                toggle={() => {
                  this.setState({ megaMenuDrp: !this.state.megaMenuDrp })
                }}
              >
                <DropdownToggle
                  className="btn header-item waves-effect"
                  caret
                  tag="button"
                >
                  {this.props.t("Databases")}{" "}
                  <i className="mdi mdi-chevron-down" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end dropdown-megamenu">
                  <Row>
                    <Col sm={8}>
                      <Row>
                        <Col md={3}>
                          <h5 className="font-size-14 mt-0">
                            {this.props.t("Academic")}
                          </h5>
                          <ul className="list-unstyled megamenu-list">
                            <li>
                              <Link to="/aca_departments">{this.props.t("Departments")}</Link>
                            </li>
                            <li>
                              <Link to="/aca_categories">{this.props.t("Categories")}</Link>
                            </li>
                            <li>
                              <Link to="/aca_cat_classes">{this.props.t("Classes")}</Link>
                            </li>
                            <li>
                              <Link to="/aca_cat_subjects">{this.props.t("Subjects")}</Link>
                            </li>
                            <li>
                              <Link to="/aca_cat_boards">{this.props.t("Boards")}</Link>
                            </li>
                            <li>
                              <Link to="/aca_cat_years">{this.props.t("Academic Years")}</Link>
                            </li>

                          </ul>
                        </Col>

                        <Col md={3}>
                          <h5 className="font-size-14 mt-0">
                            {this.props.t("Branch Management")}
                          </h5>
                          <ul className="list-unstyled megamenu-list">


                            <li>
                              <Link to="/teachers">{this.props.t("Teachers")}</Link>
                            </li>
                            <li>
                              <Link to="/activities">{this.props.t("Activities")}</Link>
                            </li>
                            <li>
                              <Link to="/qualifications">{this.props.t("Qualifications")}</Link>
                            </li>
                            <li>
                              <Link to="/weekday">{this.props.t("Week Days")}</Link>
                            </li>
                            <li>
                              <Link to="/feetypes">{this.props.t("Fee Type")}</Link>
                            </li>
                            <li>
                              <Link to="/notificationtypes">{this.props.t("Notification type")}</Link>
                            </li>
                            <li>
                              <Link to="/notificationintvels">{this.props.t("Notification Intervel")}</Link>
                            </li>
                            <li>
                              <Link to="/timenames">{this.props.t("Time Names")}</Link>
                            </li>

                          </ul>
                        </Col>

                        <Col md={3}>
                          <h5 className="font-size-14 mt-0">
                            {this.props.t("Personal Background")}
                          </h5>
                          <ul className="list-unstyled megamenu-list">
                            <li>
                              <Link to="/religions">{this.props.t("Religions")}</Link>
                            </li>
                            <li>
                              <Link to="/castecategories">{this.props.t("Caste Categories")}</Link>
                            </li>
                            <li>
                              <Link to="/castename">{this.props.t("Caste Names")}</Link>
                            </li>
                            <li>
                              <Link to="/genders">{this.props.t("Gender")}</Link>
                            </li>
                            <li>
                              <Link to="/bloodfroup">{this.props.t("Blood Group")}</Link>
                            </li>
                            <li>
                              <Link to="/relations">{this.props.t("Gurdian Relations")}</Link>
                            </li>
                            <li>
                              <Link to="/heights">{this.props.t("Height Group")}</Link>
                            </li>
                            <li>
                              <Link to="/weights">{this.props.t("Weight Group")}</Link>
                            </li>
                          </ul>
                        </Col>
                        <Col md={3}>
                          <h5 className="font-size-14 mt-0">
                            {this.props.t("Locations")}
                          </h5>
                          <ul className="list-unstyled megamenu-list">
                            <li>
                              <Link to="/countries">{this.props.t("Country")}</Link>
                            </li>
                            <li>
                              <Link to="/cstates">{this.props.t("States")}</Link>
                            </li>
                            <li>
                              <Link to="/cities">{this.props.t("Cities")}</Link>
                            </li>

                          </ul>
                        </Col>
                        <Col md={3}>
                          <h5 className="font-size-14 mt-0">
                            {this.props.t("User Management")}
                          </h5>
                          <ul className="list-unstyled megamenu-list">
                            <li>
                              <Link to="/usermng">{this.props.t("User Managment")}</Link>
                            </li>

                          </ul>
                        </Col>
                        {/*  <Col md={4}>
                          <h5 className="font-size-14 mt-0">
                            {this.props.t("Extra Pages")}
                          </h5>
                          <ul className="list-unstyled megamenu-list">
                            <li>
                              <Link to="#">
                                {this.props.t("Light Sidebar")}
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                {this.props.t("Compact Sidebar")}
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                {this.props.t("Horizontal layout")}
                              </Link>
                            </li>
                            <li>
                              <Link to="#"> {this.props.t("Maintenance")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Coming Soon")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Timeline")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("FAQs")}</Link>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={4}>
                      <Row>
                        <Col sm={6}>
                          <h5 className="font-size-14 mt-0">
                            {this.props.t("UI Components")}
                          </h5>
                          <ul className="list-unstyled megamenu-list">
                            <li>
                              <Link to="#">{this.props.t("Lightbox")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Range Slider")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Sweet Alert")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Rating")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Forms")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Tables")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Charts")}</Link>
                            </li>
                          </ul>
                        </Col>

                        <Col sm={5}>
                          <div>
                            <img
                              src={megamenuImg}
                              alt=""
                              className="img-fluid mx-auto d-block"
                            />
                          </div>
                        </Col> */}
                      </Row>
                    </Col>
                  </Row>
                </DropdownMenu>
              </Dropdown>


            </div>
            <div className="d-flex">







              <NotificationDropdown />
              <ProfileMenu />


            </div>
          </div>
        </header>

      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { layoutType } = state.Layout
  return { layoutType }
}

export default connect(mapStatetoProps, {})(
  withTranslation()(Header)
)

Header.propTypes = {
  t: PropTypes.any,
  toggleMenuCallback: PropTypes.any,

}