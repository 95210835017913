import React, { Component } from "react"
import PropTypes from 'prop-types'
import { Link, withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  UncontrolledTooltip,
  Modal,
  Button,
  Row
} from "reactstrap";
import axios from 'axios';
import { STD_URL } from "../../../helpers/url_helper";

import { AvField, AvForm } from "availity-reactstrap-validation"

class SectionCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checkPatModal: false,
    }
    this.handleViewStd = this.handleViewStd.bind(this);
    this.handleAkey = this.handleAkey.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    localStorage.setItem('schclsid', '');
    localStorage.setItem('schsecid', '');
  }
  handleViewStd = (clsid, secid) => {

    localStorage.setItem('schclsid', clsid);
    localStorage.setItem('schsecid', secid);
    this.props.history.push('/secstudents'); //eslint-disable-line
  };

  handleAkey = (clsid, secid) => {
    this.setState({
      checkPatModal: true,
      clsid: clsid,
      secid: secid,
    });
  };
  handleCkey = () => {
    this.setState({
      checkPatModal: false,

    });
  };

  handleValidSubmit = (event, values) => {
    event.preventDefault();
    console.log('values', values);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var addkeyurl = STD_URL + 'checkpat';

    var postData = {
      uid: localStorage.usercode,
      table: 'schstdparnt',
      // adhaar_num: values.adhaar_num,
      phone: values.phone,
      whois: 'phone',
    };
    axios
      .post(addkeyurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log(response.data.message);
        if (response.data.error) {
          this.setState({
            showAlert: true,
            alertType: 'warning',
            alertText: response.data.message,
          });
        } else {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("patid", response.data.patid);


          localStorage.setItem('stdclsid', this.state.clsid);
          localStorage.setItem('stdsecid', this.state.secid);
          localStorage.setItem('stdid', '');
          localStorage.setItem('stddob', '');
          localStorage.setItem('std_admission_date', '');
          localStorage.setItem('whattodostudent', 'Add Student');
          this.props.history.push('/mngstudent'); //eslint-disable-line

        }
      })
      .catch((error) => {
        console.log('Error is ', error);
        this.setState({
          alertType: 'danger',
          showAlert: true,
          alertText: 'Failed Try Again Later!',
        });
      });

  };

  render() {
    const clssec = this.props.clssec
    return (
      <React.Fragment>

        <Card>
          <CardBody>
            <div className="text-center">
              <h5 className="font-size-15 mb-2">
                <Link to="#" className="text-dark">
                  {clssec.secname}
                </Link>
              </h5>
            </div>
            <p className="text-muted"><b>Capacity :</b> {clssec.capex} <br /> <b>Students :</b> {clssec.stds}</p>
            <div className="contact-links d-flex font-size-20 border-top">
              <div className="flex-fill">
                <Button
                  className="float-end btn-sm  xmart10"
                  color="success"
                  onClick={this.handleAkey.bind(this, clssec.mclsid, clssec.secid)}
                >
                  Add Student
                </Button>
              </div>
              <div className="flex-fill">

              </div>
              <div className="flex-fill">
                <Button
                  className="float-end btn-sm  xmart10"
                  color="success"
                  onClick={this.handleViewStd.bind(this, clssec.mclsid, clssec.secid)}
                >
                  View Students
								</Button>
              </div>
            </div>
          </CardBody>

        </Card>
        <Modal
          isOpen={this.state.checkPatModal}
          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Check Guardian </h5>
            <button
              type="button"
              onClick={this.handleCkey}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="needs-validation"
              onValidSubmit={this.handleValidSubmit}
            >
              {this.state.showAlert ? (
                <Alert color={this.state.alertType}>
                  <p>{this.state.alertText}</p>
                </Alert>
              ) : null}

              <Row>
                <Col md={9}>
                  <div className="mb-3 mandate">
                    <AvField
                      name="phone"
                      label="Mobile"
                      value={this.state.father_phone}
                      type="number"
                      required
                      placeholder={'Enter Mobile'}
                      validate={{
                        required: { value: true, errorMessage: 'Please enter Mobile' },
                        pattern: { value: '^[0-9]+$', errorMessage: 'Your Mobile must be only numbers' },
                        minLength: { value: 10, errorMessage: 'Your Mobile must be 10 characters' },
                        maxLength: { value: 10, errorMessage: 'Your Mobile not more than 10 characters' }
                      }}
                    />
                  </div>
                </Col>


                <Col md={3}>
                  <button
                    className="btn btn-primary btn-block waves-effect waves-light"
                    type="submit"
                  >
                    Check Guardian
                        </button>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

SectionCard.propTypes = {
  clssec: PropTypes.object
}


export default withRouter(SectionCard)
