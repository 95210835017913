import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { loginSuccess, logoutUserSuccess, apiError } from "./actions"

//Include Both Helper File with needed methods

import {
  postLogin,
} from "../../../helpers/apibackend_helper"



function* loginUser({ payload: { user, history } }) {
  try {

    const response = yield call(postLogin, {
      email: user.email,
      password: user.password,
    })
    console.log('response', response);
    var responcedata = JSON.stringify(response.data);
    localStorage.setItem("authUser", responcedata);
    localStorage.setItem("token", response.token);
    localStorage.setItem("usercode", response.usercode);
    localStorage.setItem("upanel", response.upanel);
    // localStorage.setItem("image_url", response.data.image_url);
    // localStorage.setItem("name", response.data.name);
    // console.log('localStorage', localStorage.token);
    yield put(loginSuccess(response.data))

    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.clear();

    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}



function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
