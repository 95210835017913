import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { } from "../../store/actions"



//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"
import SidebarContentAdmin from "./SidebarContentAdmin"
import SidebarContentCoAdmin from "./SidebarContentCoAdmin"



class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    var userMenuItems = '';
    if (localStorage.upanel == 'admin-panel') {

      userMenuItems = <SidebarContentAdmin />;
    } else if (localStorage.upanel == 'coadmin-panel') {

      userMenuItems = <SidebarContentCoAdmin />;
    } else {
      userMenuItems = <SidebarContent />;
    }
    return (
      <React.Fragment>

        <div className="vertical-menu">

          <div data-simplebar className="h-100">
            {userMenuItems}


          </div>
          <div className="sidebar-background"></div>
        </div>
      </React.Fragment>
    )
  }
}



const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
