import React, { Component } from "react"
import axios from 'axios';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Media,
  Row,
  Modal,
} from "reactstrap"

import { USR_URL } from "../helpers/url_helper";
import swal from 'sweetalert';

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../components/Common/Breadcrumb"

import user1 from "../assets/images/users/user.png"


class UserProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      name: "",
      idx: 1,
      src: null,
      crop: {
        unit: "%",
        width: 30,
        aspect: 1 / 1
      },
      croppedImageUrl: null,

    }

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
    this.handleFile = this.handleFile.bind(this)
    this.uploadImage = this.uploadImage.bind(this)
  }



  componentDidMount() {


    if (this.props.loggedinUser) {


      let userimg = user1;
      if (this.props.loggedinUser.image_url != 0) {
        userimg = this.props.loggedinUser.image_url;
      }

      this.setState({ password: this.props.loggedinUser.password, userimg: userimg })

    } else {
      localStorage.clear();
    }

  }




  handleValidSubmit = (event, values) => {
    event.preventDefault();
    console.log('values', values);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var addkeyurl = USR_URL + 'editprofile';
    var postData = {
      password: values.password,
      name: values.name,
      mobile: values.mobile,
      uid: values.uid,
      table: 'u',

    };

    axios
      .post(addkeyurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log(response.data.message);
        if (response.data.error) {
          this.setState({
            showAlert: true,
            alertType: 'warning',
            alertText: response.data.message,
          });
        } else {
          var responcedata = JSON.stringify(response.data.data);
          localStorage.setItem("authUser", responcedata);
          // localStorage.setItem("image_url", response.data.data.image_url);
          // localStorage.setItem("name", response.data.data.name);
          localStorage.setItem("token", response.data.token);

          // this.getuserdtls();
          window.location.reload(false);
        }
      })
      .catch((error) => {
        console.log('Error is ', error);
        this.setState({
          alertType: 'danger',
          showAlert: true,
          alertText: 'Failed Try Again Later!',
        });
      });


  };



  handleFile = e => {
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
      this.setState({ src: fileReader.result })
    }
    fileReader.readAsDataURL(e.target.files[0])
  }



  onImageLoaded = image => {
    this.imageRef = image
  }

  onCropChange = (crop) => {
    this.setState({ crop });
  }

  onCropComplete = crop => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = this.getCroppedImg(this.imageRef, crop)
      this.setState({ croppedImageUrl })
    }
  }

  getCroppedImg(image, crop) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    const reader = new FileReader()
    canvas.toBlob(blob => {
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        this.dataURLtoFile(reader.result)
      }
    })
  }

  dataURLtoFile(dataurl) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    var imgext = mime.split('/');
    let cropedfilename = 'cropped.' + imgext[1];
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], cropedfilename, { type: mime });
    this.setState({ croppedImage: croppedImage }, () => {
      console.log('croppedImage', this.state.croppedImage);
      console.log('mime', mime);
      console.log('cropedfilename', cropedfilename);
    })
  }

  uploadImage = (event) => {
    event.preventDefault();
    var formData = new FormData();
    formData.append('file', this.state.croppedImage);
    formData.append('uid', localStorage.usercode);
    formData.append('token', localStorage.token);
    // console.log(this.state.croppedImage);
    let proimgurl = USR_URL + 'addprofilepic';
    fetch(proimgurl, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {

        console.log('data', data);
        if (data.error) {
          console.log('data.error', data.error);
          swal(item + ' ' + data.message, {
            icon: 'warning',
          });
        } else {
          var responcedata = JSON.stringify(data.data);
          localStorage.setItem("authUser", responcedata);
          localStorage.setItem("token", data.token);
          this.setState(
            {
              addKeyModal: false,
              croppedImage: '',
            });
          window.location.reload(false);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  handleCkey = () => {
    this.setState({
      addKeyModal: false,
      croppedImage: '',

    });
  };
  handleEditProfilepic = () => {
    this.setState({
      addKeyModal: true,
      croppedImage: '',
    });
  };

  render() {
    const { crop, profile_pic, src } = this.state
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb title="Skote" breadcrumbItem="Profile" />

            <Row>
              <Col lg="6">
                <Card>
                  <CardBody>
                    {this.state.showAlert ? (
                      <Alert color={this.state.alertType}>
                        <p>{this.state.alertText}</p>
                      </Alert>
                    ) : null}
                    <AvForm
                      className="needs-validation"
                      onValidSubmit={this.handleValidSubmit}
                    >



                      <div className="mb-3 mandate">
                        <AvField
                          name="item"
                          label="Email"
                          value={this.props.loggedinUser.email}
                          type="email"
                          required
                          readOnly
                        />

                      </div>

                      <div className="mb-3 mandate">
                        <AvField
                          name="password"
                          label="Password"
                          value={this.props.loggedinUser.password}
                          type="password"
                          required
                          placeholder="Enter Password"
                          validate={{
                            required: { value: true, errorMessage: 'Please enter Mobile' },
                            minLength: { value: 8, errorMessage: 'Your Mobile must be 8 characters' }
                          }}
                        />
                      </div>

                      <div className="mb-3 mandate">
                        <AvField
                          name="name"
                          label="Name"
                          value={this.props.loggedinUser.name}
                          type="text"
                          required
                          placeholder={'Enter Name'}
                        />
                      </div>
                      <div className="mb-3 mandate">
                        <AvField
                          name="mobile"
                          label="Mobile"
                          value={this.props.loggedinUser.mobile}
                          type="number"
                          required
                          placeholder={'Enter Mobile'}
                          validate={{
                            required: { value: true, errorMessage: 'Please enter Mobile' },
                            pattern: { value: '^[0-9]+$', errorMessage: 'Your Mobile must be only numbers' },
                            minLength: { value: 10, errorMessage: 'Your Mobile must be 10 characters' },
                            maxLength: { value: 10, errorMessage: 'Your Mobile not more than 10 characters' }
                          }}
                        />
                      </div>


                      <div className="mt-4 d-grid">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                        >
                          Update Profile
                  </button>
                      </div>
                      <AvField name="uid" value={localStorage.usercode} type="hidden" />

                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6">



                <Card>
                  <CardBody>
                    <Media>
                      <div className="me-3">
                        <img
                          src={this.state.userimg}
                          alt=""
                          className="avatar-md rounded-circle img-thumbnail onclicklink"
                          onClick={this.handleEditProfilepic}
                        />
                      </div>
                      <Media body className="align-self-center">
                        <div className="text-muted">
                          <h5>{this.props.loggedinUser.name}</h5>
                          <p className="mb-1">{this.props.loggedinUser.email}</p>
                          <p className="mb-0">Mobile: {this.props.loggedinUser.mobile}</p>
                        </div>
                      </Media>
                    </Media>
                  </CardBody>
                </Card>
              </Col>

            </Row>

            <Modal
              isOpen={this.state.addKeyModal}

              centered={true}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0">Profile Picture </h5>
                <button
                  type="button"
                  onClick={this.handleCkey}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">


                <AvForm
                  className="needs-validation"
                  onValidSubmit={this.uploadImage}
                >
                  {this.state.showAlert ? (
                    <Alert color={this.state.alertType}>
                      <p>{this.state.alertText}</p>
                    </Alert>
                  ) : null}


                  <div className="mb-3">
                    {/* {this.state.croppedImage} */}
                  </div>
                  <div className="mb-3">

                    {src && (
                      <ReactCrop
                        src={src}
                        crop={crop}
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                      />
                    )}

                  </div>

                  <div className="mb-3">
                    <div className="form__form-group">

                      <div className="form__form-group-field">
                        <input type="file" id='profile_pic' value={profile_pic}
                          onChange={this.handleFile} />

                      </div>
                    </div>
                  </div>





                  <div className="mt-4 d-grid">

                    <button
                      className="btn btn-primary btn-block waves-effect waves-light"
                      type="submit"
                    >
                      Crop and Update Profile Picture
                  </button>
                  </div>


                </AvForm>
              </div>
            </Modal>

          </Container>
        </div>
      </React.Fragment>
    )
  }
}



const mapStateToProps = (state) => ({ loggedinUser: state.Login.loggedinUser })
export default withRouter(
  connect(mapStateToProps)(UserProfile)
)
