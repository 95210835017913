//MAIN URL
//REGISTER
// export const MAIN_URL = "https://demoapi.wallofjobs.com/";
// export const MAIN_URL = "https://api.wallofjobs.com/";
// export const MAIN_URL = "http://localhost/crown-api/";
export const MAIN_URL = "https://teaching.crownelearn.com/api/";

export const ACA_URL = MAIN_URL + "acaapi/";
export const BRAN_URL = MAIN_URL + "branapi/";
export const USR_URL = MAIN_URL + "api/";
export const TEACH_URL = MAIN_URL + "teachapi/";
export const STD_URL = MAIN_URL + "stdapi/";
export const SCH_URL = MAIN_URL + "schapi/";
export const USER_IMG = MAIN_URL + "images/user.png";
export const NO_IMG = MAIN_URL + "images/no-img.png";



export const POST_REGISTER = MAIN_URL + "registeruser"

//LOGIN
export const POST_LOGIN = MAIN_URL + "login"
export const POST_PASSWORD_FORGET = "/fake-forget-pwd"

//PROFILE 
export const POST_EDIT_PROFILE = USR_URL + "editprofile"


