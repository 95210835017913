import React, { Component } from "react"
import { Alert, Row, Col, Card, CardBody, CardTitle, CardImg, Modal, Button, FormGroup, InputGroup, Label, Table } from "reactstrap";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';
//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import axios from 'axios';
// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"
import { STD_URL, USER_IMG, NO_IMG } from "../../../helpers/url_helper";
import Select from 'react-select';
import swal from 'sweetalert';
import "flatpickr/dist/themes/material_blue.css"

class Guardian extends Component {
  constructor(props) {
    super(props)
    this.state = {
      itempageabout: 'Add Guardian | Management',
      itemmainname: 'Guardian Management',
      itemsubname: 'Add Guardian',
      itemsubonename: 'Guardian',
      tname: 'schstdparnt',
      tdepname: 'schstd',
      itemvalue: '',
      showAlert: false,
      alertType: '',
      alertText: '',
      select_relation: [{
        value: '',
        label: (<div>
          Select Relation
        </div>
        ),
      }],
      parent: [],
      guardian_name: '',
      guardian_phone: '',
      guardian_email: '',
      guardian_address: '',
      guardian_occupation: '',
      guardian_photo: USER_IMG,
      guardian_adhaar_num: '',
      guardian_adhaar_copy: NO_IMG,
      guardian_pan_num: '',
      guardian_pan_copy: NO_IMG,
      isFits: false,
      profilesrc: null,
      adhaarsrc: null,
      pansrc: null,
      crop: {
        unit: "%",
        width: 30,
        aspect: 1 / 1
      },
    }

    this.handleValidSubmit = this.handleValidSubmit.bind(this);

    this.handleEditkey = this.handleEditkey.bind(this);
    this.handlepic = this.handlepic.bind(this);
    this.handleProfileFile = this.handleProfileFile.bind(this);
    this.handleAdhaarFile = this.handleAdhaarFile.bind(this);
    this.handlePanFile = this.handlePanFile.bind(this);


  }

  componentDidMount() {
    if (localStorage.patid) {
      this.handleGetkeys();
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name + ' ' + value);
    this.setState({ [name]: value });
  };


  handleGetkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
      table: this.state.tname,
      itemid: localStorage.patid,
    };
    const getuurl = STD_URL + 'getparent';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (response.data.error) {
          swal(response.data.message, {
            icon: 'success',
          });
        } else {
          localStorage.setItem("token", response.data.token);
          var guardiandata = response.data.allitems;
          localStorage.setItem("guardian_name", guardiandata.guardian_name);
          const allrelations = [...response.data.relations];

          this.setState({
            editmode: this.props.editmode,
            guardian_name: guardiandata.guardian_name,
            guardian_phone: guardiandata.guardian_phone,
            guardian_email: guardiandata.guardian_email,
            guardian_address: guardiandata.guardian_address,
            guardian_occupation: guardiandata.guardian_occupation,
            guardian_photo: guardiandata.guardian_photo,
            guardian_adhaar_num: guardiandata.guardian_adhaar_num,
            guardian_adhaar_copy: guardiandata.guardian_adhaar_copy,
            guardian_pan_num: guardiandata.guardian_pan_num,
            guardian_pan_copy: guardiandata.guardian_pan_copy,
            select_relation: [{
              value: '',
              label: (<div>
                Select Relation
              </div>
              ),
            }],

          }, () => {
            if (allrelations.length >= 1) {
              allrelations.map((item) => {
                this.state.select_relation.push({
                  value: item.id,
                  label: (
                    <div>
                      {item.name}
                    </div>
                  ),
                });
              });
            }
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        // localStorage.clear();

      });
  };

  handleValidSubmit = (event, values) => {
    event.preventDefault();
    if (!this.state.relationvalue) {
      swal("Select Relation", {
        icon: 'warning',
      });
    } else if (!this.state.guardian_adhaar_copy) {
      swal("Select Adhaar Scan Copy", {
        icon: 'warning',
      });
    } else if (!this.state.guardian_photo) {
      swal("Select Photo", {
        icon: 'warning',
      });
    } else {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: localStorage.token,
      };
      var addkeyurl = STD_URL + 'additemparent';

      var postData = {
        uid: localStorage.usercode,
        table: this.state.tname,
        whois: 'guardian',
        guardian_name: values.guardian_name,
        guardian_phone: values.guardian_phone,
        guardian_email: values.guardian_email,
        guardian_address: values.guardian_address,
        guardian_occupation: values.guardian_occupation,
        guardian_photo: this.state.guardian_photo,
        guardian_adhaar_num: values.guardian_adhaar_num,
        guardian_adhaar_copy: this.state.guardian_adhaar_copy,
        guardian_pan_num: values.guardian_pan_num,
        guardian_pan_copy: this.state.guardian_pan_copy,
      };
      if (localStorage.patid) {
        addkeyurl = STD_URL + 'edititemparent';
        postData = {
          itemid: localStorage.patid,
          uid: localStorage.usercode,
          table: this.state.tname,
          whois: 'guardian',
          guardian_name: values.guardian_name,
          guardian_relation: this.state.relationvalue,
          guardian_phone: values.guardian_phone,
          guardian_email: values.guardian_email,
          guardian_address: values.guardian_address,
          guardian_occupation: values.guardian_occupation,
          guardian_photo: this.state.guardian_photo,
          guardian_adhaar_num: values.guardian_adhaar_num,
          guardian_adhaar_copy: this.state.guardian_adhaar_copy,
          guardian_pan_num: values.guardian_pan_num,
          guardian_pan_copy: this.state.guardian_pan_copy,

        };
      }


      axios
        .post(addkeyurl, postData, {
          headers: headers,
        })
        .then((response) => {
          console.log(response.data.message);
          if (response.data.error) {
            this.setState({
              showAlert: true,
              alertType: 'warning',
              alertText: response.data.message,
            });
          } else {
            localStorage.setItem("token", response.data.token);
            if (!this.state.editmode) {
              localStorage.setItem("patid", response.data.patid);
            }

            swal(values.guardian_name + ' ' + response.data.patid + response.data.message, {
              icon: 'success',
            });
            this.props.getPatsts(response.data.patid);

          }
        })
        .catch((error) => {
          console.log('Error is ', error);
          this.setState({
            alertType: 'danger',
            showAlert: true,
            alertText: 'Failed Try Again Later!',
          });
        });
    }

  };

  handleSelectrelationChange = (selectedOption) => {
    let relationvalue = selectedOption.value;
    this.setState({ relationvalue: relationvalue, relationvaluename: selectedOption.label.props.children, });
  };

  handleEditkey = () => {
    this.setState({
      editmode: true,
    });
  };

  uploadProfileImage = (event) => {
    console.log(this.state.guardian_photo);
    var formData = new FormData();
    formData.append('file', this.state.croppedprofileImage);
    formData.append('item', this.state.ofwhich);
    formData.append('uid', localStorage.usercode);
    formData.append('token', localStorage.token);
    // console.log(event.target.files[0]);
    let proimgurl = STD_URL + 'addfile';
    fetch(proimgurl, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {

        console.log('data', data);
        if (data.error) {
          console.log('data.error', data.error);
          swal(item + ' ' + data.message, {
            icon: 'warning',
          });
        } else {
          localStorage.setItem("token", data.token);
          this.setState(
            {
              guardian_photo: data.urlimage,
              addKeyModal: false,
            },
            () => {
              console.log(this.state.guardian_photo);
            },
          );
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  uploadAdhaarImage = (event) => {
    console.log(this.state.guardian_adhaar_copy);
    var formData = new FormData();
    formData.append('file', this.state.croppedadhaarImage);
    formData.append('item', this.state.ofwhich);
    formData.append('uid', localStorage.usercode);
    formData.append('token', localStorage.token);
    // console.log(event.target.files[0]);
    let proimgurl = STD_URL + 'addfile';
    fetch(proimgurl, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {

        console.log('data', data);
        if (data.error) {
          console.log('data.error', data.error);
          swal(item + ' ' + data.message, {
            icon: 'warning',
          });
        } else {
          localStorage.setItem("token", data.token);
          this.setState(
            {
              guardian_adhaar_copy: data.urlimage,
              addKeyModal: false,
            },
            () => {
              console.log(this.state.guardian_adhaar_copy);
            },
          );
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  uploadPanImage = (event) => {
    console.log(this.state.guardian_pan_copy);
    var formData = new FormData();
    formData.append('file', this.state.croppedpanImage);
    formData.append('item', this.state.ofwhich);
    formData.append('uid', localStorage.usercode);
    formData.append('token', localStorage.token);
    // console.log(event.target.files[0]);
    let proimgurl = STD_URL + 'addfile';
    fetch(proimgurl, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {

        console.log('data', data);
        if (data.error) {
          console.log('data.error', data.error);
          swal(item + ' ' + data.message, {
            icon: 'warning',
          });
        } else {
          localStorage.setItem("token", data.token);
          this.setState(
            {
              guardian_pan_copy: data.urlimage,
              addKeyModal: false,
            },
            () => {
              console.log(this.state.guardian_pan_copy);
            },
          );
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  onImageLoaded = image => {
    this.imageRef = image
  }

  onCropChange = (crop) => {
    this.setState({ crop });
  }

  onProfileCropComplete = crop => {
    if (this.imageRef && crop.width && crop.height) {
      this.getCroppedImg(this.imageRef, crop, 'profile');
    }
  }
  onAdhaarCropComplete = crop => {
    if (this.imageRef && crop.width && crop.height) {
      this.getCroppedImg(this.imageRef, crop, 'adhaar');
    }
  }
  onPanCropComplete = crop => {
    if (this.imageRef && crop.width && crop.height) {
      this.getCroppedImg(this.imageRef, crop, 'pan');
    }
  }

  getCroppedImg(image, crop, whichimg) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    const reader = new FileReader()
    canvas.toBlob(blob => {
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        if (whichimg == 'profile') {
          this.dataURLtoFileProfile(reader.result, whichimg)
        } else if (whichimg == 'adhaar') {
          this.dataURLtoFileAdhaar(reader.result, whichimg)
        } else if (whichimg == 'pan') {
          this.dataURLtoFilePan(reader.result, whichimg)
        }
      }
    })
  }

  dataURLtoFileProfile(dataurl) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    var imgext = mime.split('/');
    let cropedfilename = 'profilecropped.' + imgext[1];
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], cropedfilename, { type: mime });
    this.setState({ croppedprofileImage: croppedImage }, () => {
      console.log('croppedImage', this.state.croppedprofileImage);
      console.log('profilemime', mime);
      console.log('profilecropedfilename', cropedfilename);
    })
  }
  dataURLtoFileAdhaar(dataurl) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    var imgext = mime.split('/');
    let cropedfilename = 'adhaarcropped.' + imgext[1];
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], cropedfilename, { type: mime });
    this.setState({ croppedadhaarImage: croppedImage }, () => {
      console.log('croppedImage', this.state.croppedadhaarImage);
      console.log('adhaarmime', mime);
      console.log('adhaarcropedfilename', cropedfilename);
    })
  }
  dataURLtoFilePan(dataurl) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    var imgext = mime.split('/');
    let cropedfilename = 'pancropped.' + imgext[1];
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], cropedfilename, { type: mime });
    this.setState({ croppedpanImage: croppedImage }, () => {
      console.log('croppedImage', this.state.croppedpanImage);
      console.log('panmime', mime);
      console.log('pancropedfilename', cropedfilename);
    })
  }
  handleCkey = () => {
    this.setState({
      addKeyModal: false,
      croppedprofileImage: '',
      croppedprofileImage: '',
      croppedadhaarImage: '',
      croppedpanImage: '',

    });
  };
  handlepic = (ofwhich) => {
    this.setState({
      addKeyModal: true,
      ofwhich: ofwhich,
      croppedprofileImage: '',
      croppedadhaarImage: '',
      croppedpanImage: '',
    });
  };
  handleProfileFile = e => {
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
      this.setState({
        profilesrc: fileReader.result,
      })
    }
    fileReader.readAsDataURL(e.target.files[0])
  }
  handleAdhaarFile = e => {
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
      this.setState({ adhaarsrc: fileReader.result })
    }
    fileReader.readAsDataURL(e.target.files[0])
  }
  handlePanFile = e => {
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
      this.setState({ pansrc: fileReader.result })
    }
    fileReader.readAsDataURL(e.target.files[0])
  }

  render() {
    const { crop, profilesrc, adhaarsrc, pansrc } = this.state
    return (
      <React.Fragment>
        {this.state.isFits ? (
          <Lightbox
            mainSrc={this.state.lightimg}
            enableZoom={false}
            imageCaption={
              "Caption. Can be aligned it to any side and contain any HTML."
            }
            onCloseRequest={() => this.setState({ isFits: false })}
          />
        ) : null}

        <Card>
          <CardBody>
            <CardTitle className="mb-4 h4">Student Guardian </CardTitle>

            {localStorage.patid ? (
              <>
                {this.state.editmode ? (
                  <AvForm
                    className="needs-validation"
                    onValidSubmit={this.handleValidSubmit}
                  >
                    {this.state.showAlert ? (
                      <Alert color={this.state.alertType}>
                        <p>{this.state.alertText}</p>
                      </Alert>
                    ) : null}


                    <Row>
                      <Col md={12}>
                        <div className="mb-3 mandate">
                          <label>Guardian Image</label>
                          <br />
                          <CardImg top className="avatar-lg onclicklink" src={this.state.guardian_photo} alt="Photo" onClick={this.handlepic.bind(this, 'guardian_photo')} />

                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3">
                          <div className="form-group mandate">
                            <label className="">Relation</label>
                            <div className="form__form-group-field" style={{ height: '30px' }}>
                              <Select
                                name="relationvalue"
                                value={this.state.select_relation.filter(
                                  (option) => option.value == this.state.relationvalue,
                                )}
                                onChange={this.handleSelectrelationChange}
                                options={this.state.select_relation}
                                clearable={false}
                                className="react-select-team"
                                classNamePrefix="react-select"
                              />
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col md={12}>
                        <div className="mb-3 mandate">
                          <AvField
                            name="guardian_name"
                            label='Name'
                            value={this.state.guardian_name}
                            type="text"
                            required
                            placeholder='Enter Name'
                          />
                        </div>
                      </Col>

                      <Col md={12}>
                        <div className="mb-3 mandate">
                          <AvField
                            name="guardian_phone"
                            label="Mobile"
                            value={this.state.guardian_phone}
                            type="number"
                            required
                            placeholder={'Enter Mobile'}
                            validate={{
                              required: { value: true, errorMessage: 'Please enter Mobile' },
                              pattern: { value: '^[0-9]+$', errorMessage: 'Your Mobile must be only numbers' },
                              minLength: { value: 10, errorMessage: 'Your Mobile must be 10 characters' },
                              maxLength: { value: 10, errorMessage: 'Your Mobile not more than 10 characters' }
                            }}
                          />
                        </div>
                      </Col>

                      <Col md={12}>
                        <div className="mb-3">
                          <AvField
                            name="guardian_email"
                            label='Email'
                            value={this.state.guardian_email}
                            type="email"
                            placeholder={'Enter Email'}
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3 mandate">
                          <AvField
                            name="guardian_address"
                            label='Address'
                            value={this.state.guardian_address}
                            type="text"
                            placeholder='Enter Address'
                            required
                          />
                        </div>
                      </Col>

                      <Col md={12}>
                        <div className="mb-3 mandate">
                          <AvField
                            name="guardian_adhaar_num"
                            label='Adhaar No'
                            value={this.state.guardian_adhaar_num}
                            type="number"
                            required
                            validate={{
                              required: { value: true, errorMessage: 'Please enter Adhaar' },
                              pattern: { value: '^[0-9]+$', errorMessage: 'Your Adhaar must be only numbers' },
                              minLength: { value: 12, errorMessage: 'Your Adhaar must be 12 characters' },
                              maxLength: { value: 12, errorMessage: 'Your Adhaar not more than 12 characters' }
                            }}
                            placeholder={'Enter  Adhaar No'}
                          />
                        </div>
                      </Col>

                      <Col md={12}>
                        <div className="mb-3 mandate">
                          <label>Adhaar Scan Copy</label>
                          <br />
                          <CardImg top className="avatar-lg onclicklink" src={this.state.guardian_adhaar_copy} alt="Adhaar Copy" onClick={this.handlepic.bind(this, 'guardian_adhaar_copy')} />

                        </div>
                      </Col>


                      <Col md={12}>
                        <div className="mb-3">
                          <AvField name="guardian_pan_num" label='Pancard No.' value={this.state.guardian_pan_num} type="text" placeholder={'Enter  Pancard No.'} />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <label>Pancard Scan Copy</label>
                          <br />
                          <CardImg top className="avatar-lg onclicklink" src={this.state.guardian_pan_copy} alt="Pancard Copy" onClick={this.handlepic.bind(this, 'guardian_pan_copy')} />
                        </div>
                      </Col>

                      <Col md={12}>
                        <div className="mb-3">
                          <AvField
                            name="guardian_occupation"
                            label='Occupation'
                            value={this.state.guardian_occupation}
                            type="text"
                            placeholder='Enter Occupation'
                          />
                        </div>
                      </Col>

                    </Row>
                    <div className="mt-4 d-grid">
                      <button
                        className="btn btn-primary btn-block waves-effect waves-light"
                        type="submit"
                      >
                        Submit Student Guardian
                        </button>
                    </div>

                  </AvForm>
                ) : (


                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row"><img
                            onClick={() => {
                              this.setState({
                                isFits: true,
                                lightimg: this.state.guardian_photo
                              });
                            }}
                            src={this.state.guardian_photo}
                            alt=""
                            className="img-thumbnail"
                          /></th>
                          <td><Button
                            className="float-end btn-sm"
                            color="success"
                            onClick={this.handleEditkey}
                          >
                            Edit Guardian Details
                           </Button></td>
                        </tr>
                        <tr>
                          <th scope="row">Full Name :</th>
                          <td>{this.state.guardian_name}</td>
                        </tr>
                        <tr>
                          <th scope="row">Mobile :</th>
                          <td>{this.state.guardian_phone}</td>
                        </tr>
                        <tr>
                          <th scope="row">E-mail :</th>
                          <td>{this.state.guardian_email}</td>
                        </tr>
                        <tr>
                          <th scope="row">Occupation :</th>
                          <td>{this.state.guardian_occupation}</td>
                        </tr>
                        <tr>
                          <th scope="row">Adhaar :</th>
                          <td>{this.state.guardian_adhaar_num}</td>
                        </tr>
                        <tr>
                          <th scope="row">Adhaar Copy :</th>
                          <td><img
                            onClick={() => {
                              this.setState({
                                isFits: true,
                                lightimg: this.state.guardian_adhaar_copy
                              });
                            }}
                            className="img-fluid"
                            alt="Adhaar copy"
                            src={this.state.guardian_adhaar_copy}
                            width="35"
                          /></td>
                        </tr>

                        <tr>
                          <th scope="row">Pancard no :</th>
                          <td>{this.state.guardian_pan_num}</td>
                        </tr>
                        {this.state.guardian_pan_copy ? (
                          <tr>
                            <th scope="row">Pancard Copy :</th>
                            <td><img
                              onClick={() => {
                                this.setState({
                                  isFits: true,
                                  lightimg: this.state.guardian_pan_copy
                                });
                              }}
                              className="img-fluid"
                              alt="Adhaar copy"
                              src={this.state.guardian_pan_copy}
                              width="35"
                            /></td>
                          </tr>
                        ) : null}
                      </tbody>
                    </Table>
                  </div>
                )}
              </>
            ) : (
              <AvForm
                className="needs-validation"
                onValidSubmit={this.handleValidSubmit}
              >
                {this.state.showAlert ? (
                  <Alert color={this.state.alertType}>
                    <p>{this.state.alertText}</p>
                  </Alert>
                ) : null}


                <Row>
                  <Col md={12}>
                    <div className="mb-3 mandate">
                      <label>Guardian Image</label>
                      <br />
                      <CardImg top className="avatar-lg onclicklink" src={this.state.guardian_photo} alt="Photo" onClick={this.handlepic.bind(this, 'guardian_photo')} />

                    </div>
                  </Col>

                  <Col md={12}>
                    <div className="mb-3 mandate">
                      <AvField
                        name="guardian_name"
                        label='Name'
                        value={this.state.guardian_name}
                        type="text"
                        required
                        placeholder='Enter Name'
                      />
                    </div>
                  </Col>

                  <Col md={12}>
                    <div className="mb-3">
                      <div className="form-group mandate">
                        <label className="">Relation</label>
                        <div className="form__form-group-field" style={{ height: '30px' }}>
                          <Select
                            name="relationvalue"
                            value={this.state.select_relation.filter(
                              (option) => option.value == this.state.relationvalue,
                            )}
                            onChange={this.handleSelectrelationChange}
                            options={this.state.select_relation}
                            clearable={false}
                            className="react-select-team"
                            classNamePrefix="react-select"
                          />
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col md={12}>
                    <div className="mb-3 mandate">
                      <AvField
                        name="guardian_phone"
                        label="Mobile"
                        value={this.state.guardian_phone}
                        type="number"
                        required
                        placeholder={'Enter Mobile'}
                        validate={{
                          required: { value: true, errorMessage: 'Please enter Mobile' },
                          pattern: { value: '^[0-9]+$', errorMessage: 'Your Mobile must be only numbers' },
                          minLength: { value: 10, errorMessage: 'Your Mobile must be 10 characters' },
                          maxLength: { value: 10, errorMessage: 'Your Mobile not more than 10 characters' }
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={12}>
                    <div className="mb-3">
                      <AvField
                        name="guardian_email"
                        label='Email'
                        value={this.state.guardian_email}
                        type="email"
                        placeholder={'Enter Email'}
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3 mandate">
                      <AvField
                        name="guardian_address"
                        label='Address'
                        value={this.state.guardian_address}
                        type="text"
                        placeholder='Enter Address'
                        required
                      />
                    </div>
                  </Col>

                  <Col md={12}>
                    <div className="mb-3 mandate">
                      <AvField
                        name="guardian_adhaar_num"
                        label='Adhaar No'
                        value={this.state.guardian_adhaar_num}
                        type="number"
                        required
                        validate={{
                          required: { value: true, errorMessage: 'Please enter Adhaar' },
                          pattern: { value: '^[0-9]+$', errorMessage: 'Your Adhaar must be only numbers' },
                          minLength: { value: 12, errorMessage: 'Your Adhaar must be 12 characters' },
                          maxLength: { value: 12, errorMessage: 'Your Adhaar not more than 12 characters' }
                        }}
                        placeholder={'Enter  Adhaar No'}
                      />
                    </div>
                  </Col>

                  <Col md={12}>
                    <div className="mb-3 mandate">
                      <label>Adhaar Scan Copy</label>
                      <br />
                      <CardImg top className="avatar-lg onclicklink" src={this.state.guardian_adhaar_copy} alt="Adhaar Copy" onClick={this.handlepic.bind(this, 'guardian_adhaar_copy')} />

                    </div>
                  </Col>


                  <Col md={12}>
                    <div className="mb-3">
                      <AvField name="guardian_pan_num" label='Pancard No.' value={this.state.guardian_pan_num} type="text" placeholder={'Enter  Pancard No.'} />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <label>Pancard Scan Copy</label>
                      <br />
                      <CardImg top className="avatar-lg onclicklink" src={this.state.guardian_pan_copy} alt="Pancard Copy" onClick={this.handlepic.bind(this, 'guardian_pan_copy')} />
                    </div>
                  </Col>

                  <Col md={12}>
                    <div className="mb-3">
                      <AvField
                        name="guardian_occupation"
                        label='Occupation'
                        value={this.state.guardian_occupation}
                        type="text"
                        placeholder='Enter Occupation'
                      />
                    </div>
                  </Col>

                </Row>
                <div className="mt-4 d-grid">
                  <button
                    className="btn btn-primary btn-block waves-effect waves-light"
                    type="submit"
                  >
                    Submit Student Guardian
                        </button>
                </div>

              </AvForm>
            )}


          </CardBody>
        </Card>



        <Modal
          isOpen={this.state.addKeyModal}

          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">{this.state.modalheading} </h5>
            <button
              type="button"
              onClick={this.handleCkey}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">

            {this.state.ofwhich == 'guardian_photo' ? (
              <AvForm
                className="needs-validation"
                onValidSubmit={this.uploadProfileImage}
              >
                {this.state.showAlert ? (
                  <Alert color={this.state.alertType}>
                    <p>{this.state.alertText}</p>
                  </Alert>
                ) : null}
                <div className="mb-3">
                  {profilesrc && (
                    <ReactCrop
                      src={profilesrc}
                      crop={crop}
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onProfileCropComplete}
                      onChange={this.onCropChange}
                    />
                  )}

                </div>

                <div className="mb-3">
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <input type="file" onChange={this.handleProfileFile} />
                    </div>
                  </div>
                </div>

                <div className="mt-4 d-grid">

                  <button
                    className="btn btn-primary btn-block waves-effect waves-light"
                    type="submit"
                  >
                    Crop and Update {this.state.modalheading}
                  </button>
                </div>
              </AvForm>
            ) : null}
            {this.state.ofwhich == 'guardian_adhaar_copy' ? (
              <AvForm
                className="needs-validation"
                onValidSubmit={this.uploadAdhaarImage}
              >
                {this.state.showAlert ? (
                  <Alert color={this.state.alertType}>
                    <p>{this.state.alertText}</p>
                  </Alert>
                ) : null}
                <div className="mb-3">
                  {adhaarsrc && (
                    <ReactCrop
                      src={adhaarsrc}
                      crop={crop}
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onAdhaarCropComplete}
                      onChange={this.onCropChange}
                    />
                  )}

                </div>

                <div className="mb-3">
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <input type="file" onChange={this.handleAdhaarFile} />
                    </div>
                  </div>
                </div>

                <div className="mt-4 d-grid">

                  <button
                    className="btn btn-primary btn-block waves-effect waves-light"
                    type="submit"
                  >
                    Crop and Update {this.state.modalheading}
                  </button>
                </div>
              </AvForm>
            ) : null}
            {this.state.ofwhich == 'guardian_pan_copy' ? (
              <AvForm
                className="needs-validation"
                onValidSubmit={this.uploadPanImage}
              >
                {this.state.showAlert ? (
                  <Alert color={this.state.alertType}>
                    <p>{this.state.alertText}</p>
                  </Alert>
                ) : null}
                <div className="mb-3">
                  {pansrc && (
                    <ReactCrop
                      src={pansrc}
                      crop={crop}
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onPanCropComplete}
                      onChange={this.onCropChange}
                    />
                  )}

                </div>

                <div className="mb-3">
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <input type="file" onChange={this.handlePanFile} />
                    </div>
                  </div>
                </div>

                <div className="mt-4 d-grid">

                  <button
                    className="btn btn-primary btn-block waves-effect waves-light"
                    type="submit"
                  >
                    Crop and Update {this.state.modalheading}
                  </button>
                </div>
              </AvForm>
            ) : null}

          </div>
        </Modal>

      </React.Fragment>
    )
  }
}

export default Guardian
