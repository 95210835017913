import React, { Component } from "react"
import {
  Alert, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Modal, Button, Table, FormGroup, Input, Label, Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  Collapse,
} from "reactstrap";
import classnames from "classnames"
import MetaTags from 'react-meta-tags';
import axios from 'axios';
// availity-reactstrap-validation
import { AvField, AvForm, AvGroup, AvInput, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import { ACA_URL } from "../../helpers/url_helper";
// import SweetAlert from "react-bootstrap-sweetalert"
import Switch from 'react-switch';
import Select from 'react-select';
import swal from 'sweetalert';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../components/Common/datatables.scss"
const initialstate = {
  customActiveTab: 1,
  itempageabout: 'Academic Structure | Academic',
  itemmainname: 'Academic',
  itemsubname: 'Academic Structure',
  itemsubonename: 'Structure',
  itemactionname: 'Add Structure',
  tname: 'astruc',
  tdepname: 'dummy',

  aca_str: '',
  boardvalue: '',
  boardnamevalue: '',
  categoryvalue: [],
  rboards: [],
  rcats: [],
  rcls: [],
  rsubs: [],

  activemngcats: '',
  activemngcls: '',
  activemngsubs: '',

  aclassvalue: '',
  subjectvalue: '',
  branchvaluename: '',
  academicyearvaluename: '',
  boardvaluename: '',
  categoryvaluename: '',
  aclassvaluename: '',
  subjectvaluename: '',
  selectedclass: '',
  classesselected: '',
  activeone: true,
  activetwo: false,
  activethree: false,
  activefour: false,
  allitems: [],
  relateditems: [],
  showAlert: false,
  alertType: '',
  alertText: '',
  keyId: 0,
  keyName: '',
  oldkeyname: '',
  newkeyname: '',
  noorid: 0,

  addKeyModal: false,
  akeys: 0,
  bkeys: 0,
  tkeys: 0,
  select_bitems: [{
    value: '',
    label: (<div>
      Select Board
    </div>
    ),
  }],


};
class AcademicStructureBoards extends Component {
  constructor(props) {
    super(props)
    // this.state = {}
    this.handleGetkeys = this.handleGetkeys.bind(this);
    this.handleAddBoard = this.handleAddBoard.bind(this);
    this.handleAddBoardCats = this.handleAddBoardCats.bind(this);

    this.toggleCustom = this.toggleCustom.bind(this);
    localStorage.setItem('boardid', '');
    localStorage.setItem('acaboardname', '');
  }
  state = { ...initialstate };
  componentDidMount() {

    this.handleGetkeys();


  }
  toggleCustom(tab) {
    if (this.state.customActiveTab !== tab) {
      this.setState({
        customActiveTab: tab,
      })
    }
  }
  handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name + ' ' + value);
    this.setState({ [name]: value });
  };
  handleGetkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
      table: this.state.tname,
      deptable: this.state.tdepname,
    };
    const getuurl = ACA_URL + 'getacademicstats';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);
          const allitems = [...response.data.allitems];
          console.log('allitems', response.data.allitems);

          const allselect_bitems = [...response.data.rboards];

          let akeys = 0;

          let tkeys = 0;
          allitems.map((dkey) => {
            akeys = akeys + dkey.status;
            tkeys = tkeys + 1;
          });

          this.setState({
            akeys: akeys,
            bkeys: tkeys - akeys,
            tkeys: tkeys,
            rboards: response.data.rboards,
            acats: response.data.acats,
            acls: response.data.acls,
            asubs: response.data.asubs,
            allitems: response.data.allitems,
            relateditems: response.data.allitems,

            select_bitems: [{
              value: '',
              label: (<div>
                Select Board
              </div>
              ),
            }],

          }, () => {
            if (allselect_bitems.length >= 1) {
              allselect_bitems.map((item) => {
                this.state.select_bitems.push({
                  value: item.id,
                  label: (
                    <div>
                      {item.name}
                    </div>
                  ),
                });
              });
            }
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        localStorage.clear();
        this.props.history.push('/'); //eslint-disable-line
      });
  };

  handleSelectboardChange = (selectedOption) => {
    let boardvalue = selectedOption.value;
    this.setState({ boardvalue: boardvalue, boardvaluename: selectedOption.label.props.children, });
  };

  handleAddBoard = (event, values) => {
    event.preventDefault();
    console.log('values', values);
    //  item academicyearvalue boardvalue categoryvalue  
    if (this.state.boardvalue == '') {
      this.setState({
        showAlert: true,
        alertType: 'danger',
        alertText: 'Select Board',
      });
    } else {

      this.setState({
        showAlert: false,

        boardvalue: this.state.boardvalue,
        rcats: this.state.acats,
        activeone: true,
        activemngcls: false,
        activemngsubs: false,
        addKeyModal: true,
        itemactionname: 'Add ' + this.state.itemsubonename,

      });

    }
  };
  handleAddBoardCats = (event, values) => {
    event.preventDefault();
    console.log('values', values);
    if (this.state.boardvalue == '') {
      this.setState({
        showAlert: true,
        alertType: 'danger',
        alertText: 'Select Board',
      });
    } else if (values.categoryvalue.length == 0) {
      this.setState({
        showAlert: true,
        alertType: 'danger',
        alertText: 'Select Categories',
      });
    } else {

      var categoryvalue = JSON.stringify(values.categoryvalue);
      const headers = {
        'Content-Type': 'application/json',
        Authorization: localStorage.token,
      };
      var addkeyurl = ACA_URL + 'addmstructure';
      var postData = {

        boardvalue: this.state.boardvalue,
        categoryvalue: categoryvalue,
        boardvaluename: this.state.boardvaluename,
        uid: localStorage.usercode,
        table: this.state.tname,

      };

      axios
        .post(addkeyurl, postData, {
          headers: headers,
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error) {
            this.setState({
              showAlert: true,
              alertType: 'warning',
              alertText: response.data.message,
            });
          } else {
            localStorage.setItem("token", response.data.token);
            this.handleGetkeys();
            this.setState(
              {
                addKeyModal: false,
                showAlert: false,
              });
            swal(response.data.message, {
              icon: 'success',
            });
          }
        })
        .catch((error) => {
          console.log('Error is ', error);
          this.setState({
            alertType: 'danger',
            showAlert: true,
            alertText: 'Failed Try Again Later!',
          });
        });
    }
  };


  handleCkey = () => {
    this.setState({
      addKeyModal: false,
      showAlert: false,
      itemvalue: '',

    });
  };



  render() {
    const { rboards } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.state.itempageabout} </title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title={this.state.itemmainname} breadcrumbItem={this.state.itemsubname} />
            {rboards && rboards.length >= 1 ? (
              <Row>
                <Col className="col-12">

                  <Card>
                    <CardBody>

                      <AvForm
                        className="needs-validation"
                        onValidSubmit={this.handleAddBoard}
                      >
                        {this.state.showAlert ? (
                          <Alert color={this.state.alertType}>
                            <p>{this.state.alertText}</p>
                          </Alert>
                        ) : null}
                        <Row>


                          <div className="col-3">
                            <div className="form__form-group">
                              <span className="form__form-group-label">Board</span>
                              <div className="form__form-group-field" style={{ height: '30px' }}>
                                <Select
                                  name="boardvalue"
                                  value={this.state.select_bitems.filter(
                                    (option) => option.value == this.state.boardvalue,
                                  )}
                                  onChange={this.handleSelectboardChange}
                                  options={this.state.select_bitems}
                                  clearable={false}
                                  className="react-select-team"
                                  classNamePrefix="react-select"
                                />
                              </div>
                            </div>
                          </div>






                          <div className="col-3">

                            <button
                              className="btn btn-primary btn-block waves-effect waves-light"
                              type="submit" style={{ marginTop: '18px' }}
                            >
                              Add New Structure
                          </button>
                          </div>
                        </Row>

                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

            ) : null}

            <Card>
              <CardBody>


                <Nav tabs className="nav-tabs-custom nav-justified">

                  {this.state.relateditems &&
                    this.state.relateditems.map((mitem, index) => {



                      var isi = index + 1;
                      return (
                        <NavItem key={index}>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: this.state.customActiveTab == isi,
                            })}
                            onClick={() => {
                              this.toggleCustom(isi)
                            }}
                          >
                            <span className="d-none d-sm-block">Board: {mitem.itemname}
                              <Button
                                className="btn-sm xmarl10"
                                color="success"

                                onClick={() => {
                                  localStorage.setItem('boardid', mitem.boardvalue);
                                  localStorage.setItem('acaboardname', mitem.itemname);
                                  this.props.history.push('/academicstructure'); //eslint-disable-line
                                }}
                              >
                                <i className="mdi mdi-cog" /> Manage
                              </Button>

                            </span>
                          </NavLink>
                        </NavItem>
                      );
                    })}

                </Nav>

                <TabContent activeTab={this.state.customActiveTab} className="p-3 text-muted">
                  {this.state.relateditems &&
                    this.state.relateditems.map((mitem, index) => {
                      var issi = index + 1;
                      return (
                        <TabPane tabId={issi} key={index}>
                          {mitem.structure_dtls && mitem.structure_dtls.map((mcat, index) => {


                            return (
                              <div className="col-md-12" key={index}>
                                <Card className="catcard">
                                  <CardBody>
                                    <CardTitle className="h4">{mcat.mcatname}

                                    </CardTitle>

                                  </CardBody>
                                </Card>


                                {mcat.mcls.length >= 1 ? (

                                  <>
                                    {mcat.mcls && mcat.mcls.map((mclass, index) => {


                                      return (
                                        <Card key={index}>
                                          <CardBody>
                                            <div className="row">
                                              <div className="col-md-2">
                                                <span className="as-class clearfix"><i className="mdi mdi-chevron-right" />  {mclass.mclname}

                                                </span>
                                              </div>
                                              <div className="col-md-10">

                                                {mclass.clsubs.length >= 1 ? (
                                                  <>
                                                    {mclass.clsubs && mclass.clsubs.map((msub, index) => {
                                                      return (

                                                        <span key={index} className="as-subject"><i className="mdi mdi-chevron-double-right" />  {msub.msubname}</span>


                                                      )
                                                    })}
                                                  </>
                                                ) : "No Subjects"}

                                              </div>
                                            </div>
                                          </CardBody>
                                        </Card>
                                      )
                                    })}
                                  </>

                                ) : (
                                  <Card>
                                    <CardBody>
                                      <div className="row">
                                        <div className="col-md-2">
                                          No Classes
                                    </div>
                                        <div className="col-md-10">No Subjects</div>
                                      </div>
                                    </CardBody>
                                  </Card>

                                )}



                              </div>
                            )
                          })}
                        </TabPane>
                      );
                    })}

                </TabContent>
              </CardBody>
            </Card>

          </div>
          <Modal
            isOpen={this.state.addKeyModal}

            centered={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">{this.state.itemactionname} </h5>
              <button
                type="button"
                onClick={this.handleCkey}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {this.state.showAlert ? (
                <Alert color={this.state.alertType}>
                  <p>{this.state.alertText}</p>
                </Alert>
              ) : null}
              {this.state.activeone ? (
                <AvForm
                  className="needs-validation"
                  onValidSubmit={this.handleAddBoardCats}
                >


                  <div className="mb-3">
                    <AvCheckboxGroup name="categoryvalue" label="Select Class Categories" required>
                      {this.state.rcats && this.state.rcats.map((aclaasit, index) => {
                        return (
                          <AvCheckbox key={index} label={aclaasit.name} value={aclaasit.id} />

                        )
                      })}
                    </AvCheckboxGroup>
                  </div>

                  <div className="mt-4 d-grid">
                    <button
                      className="btn btn-primary btn-block waves-effect waves-light"
                      type="submit"
                    >
                      {this.state.itemactionname}
                    </button>
                  </div>
                </AvForm>
              ) : null}
            </div>
          </Modal>
        </div>
      </React.Fragment>
    )
  }
}

export default AcademicStructureBoards
