import React, { Component } from "react"
import { Alert, Row, Col, Card, CardBody, CardTitle, CardImg, CardText, CardSubtitle, Modal, Button, FormGroup, InputGroup, Label, Table } from "reactstrap";
import { map } from "lodash"
import MetaTags from 'react-meta-tags';
import axios from 'axios';
import readXlsxFile from 'read-excel-file';
import { STD_URL, SCH_URL, USER_IMG, NO_IMG } from "../../../helpers/url_helper";

import { AvField, AvForm } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//Import Card
import SectionCard from "./SectionCard"
// import StudentCard from "./StudentCard"
const initialstate = {
  itempageabout: 'Students | Management',
  itemmainname: 'Management',
  itemsubname: 'Students',
  itemsubonename: 'Student',
  itemactionname: 'Add Student',
  tname: 'schstd',
  tdepname: 'dummy',
  itemvalue: '',
  allitems: [],
  relateditems: [],

};
class Students extends Component {
  constructor(props) {
    super(props)
    // this.state = {}
    this.handleGetkeys = this.handleGetkeys.bind(this);
    this.handleAkey = this.handleAkey.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    localStorage.setItem('std_admission_date', '');
    localStorage.setItem('stddob', '');
    localStorage.setItem('whattodostudent', '');
    localStorage.setItem('stdid', '');
    localStorage.setItem('patid', '');
    localStorage.setItem('stdclsid', '');
    localStorage.setItem('stdsecid', '');
  }
  state = { ...initialstate };
  componentDidMount() {
    this.handleGetkeys();
  }

  handleGetkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
      mitem: localStorage.schboardid,
      mmitem: localStorage.schstrucid,
      branid: localStorage.schbranchid,
    };
    const getuurl = STD_URL + 'getmystrucstds';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);
          console.log('allitems', response.data.allitems);



          this.setState({
            allitems: response.data.allitems,
            relateditems: response.data.allitems,



          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        // localStorage.clear();
        //this.props.history.push('/'); //eslint-disable-line
      });
  };
  handleValidSubmit = (event, values) => {
    event.preventDefault();
    console.log('values', values);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var addkeyurl = STD_URL + 'checkpat';

    var postData = {
      uid: localStorage.usercode,
      table: 'schstdparnt',
      // adhaar_num: values.adhaar_num,
      phone: values.phone,
      whois: 'phone',
    };
    axios
      .post(addkeyurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log(response.data.message);
        if (response.data.error) {
          this.setState({
            showAlert: true,
            alertType: 'warning',
            alertText: response.data.message,
          });
        } else {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("patid", response.data.patid);


          localStorage.setItem('stdid', '');
          localStorage.setItem('stddob', '');
          localStorage.setItem('std_admission_date', '');
          localStorage.setItem('whattodostudent', 'Add Student');
          this.props.history.push('/mngstudent'); //eslint-disable-line

        }
      })
      .catch((error) => {
        console.log('Error is ', error);
        this.setState({
          alertType: 'danger',
          showAlert: true,
          alertText: 'Failed Try Again Later!',
        });
      });

  };
  handleAkey = () => {
    this.setState({
      checkPatModal: true,
    });
    // localStorage.setItem('stdid', '');
    // localStorage.setItem('stddob', '');
    // localStorage.setItem('std_admission_date', '');
    // localStorage.setItem('patid', '');
    // localStorage.setItem('whattodostudent', 'Add Student');
    // this.props.history.push('/mngstudent'); //eslint-disable-line
    // //this.props.history.push('/stdrelation'); //eslint-disable-line
  };
  handleCkey = () => {
    this.setState({
      addKeyModal: false,
      checkPatModal: false,

    });
  };

  handleCSVAkey = () => {
    this.setState({
      addKeyModal: true,
    });
  };

  uploadExcelQuestions = (event) => {
    event.preventDefault();

    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };

    var addkeyurl = STD_URL + 'exceltea';
    var postData = {
      items: this.state.excelstudents,
      uid: localStorage.usercode,
      table: this.state.tname,
    };
    axios
      .post(addkeyurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log(response.data.message);
        if (response.data.error) {
          this.setState({
            showAlert: true,
            alertType: 'warning',
            alertText: response.data.message,
          });
        } else {
          this.setState(
            {
              addKeyModal: false,
              showAlert: false,
            },
            () => {
              this.handleGetkeys();
            },
          );
        }
      })
      .catch((error) => {
        console.log('Error is ', error);
        this.setState({
          alertType: 'danger',
          showAlert: true,
          alertText: 'Failed Try Again Later!',
        });
      });
  };


  render() {

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.state.itempageabout} </title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title={this.state.itemmainname} breadcrumbItem={this.state.itemsubname} />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">{this.state.itemsubname}
                      <Button
                        className="float-end btn-sm"
                        color="success"
                        onClick={this.handleAkey}
                      >
                        + Add Student
						        		</Button>
                      {/* <br />
                      <br />
                      <Button
                        className="float-end btn-sm"
                        color="success"
                        onClick={this.handleCSVAkey}
                      >
                        + Bulk Upload Students
						        		</Button> */}
                    </CardTitle>
                    <p className="card-title-desc">Some Description</p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* <Row>
              {map(this.state.relateditems, (mitem, index) => (
                <StudentCard user={mitem} key={"_user_" + index} />
              ))}
            </Row> */}
            {this.state.relateditems &&
              this.state.relateditems.map((mitem, schindex) => {

                return (
                  <div key={schindex}>
                    {mitem.structure_dtls && mitem.structure_dtls.map((mcat, mcatindex) => {


                      return (
                        <div className="col-md-12" key={mcatindex}>
                          <Card className="catcard">
                            <CardBody>
                              <CardTitle className="h4">{mcat.mcatname}

                              </CardTitle>

                            </CardBody>
                          </Card>


                          {mcat.mcls.length >= 1 ? (

                            <>
                              {mcat.mcls && mcat.mcls.map((mclass, mclsindex) => {


                                return (
                                  <Card key={mclsindex} className="clsbg">
                                    <CardBody>
                                      <Row>
                                        <Col md={12}>
                                          <span className="as-class clearfix">
                                            <i className="mdi mdi-chevron-right" />
                                            {mclass.mclname}
                                          </span>
                                        </Col>
                                      </Row>
                                      <Row>
                                        {mclass.clsections &&
                                          mclass.clsections.map((msec, secindex) => {
                                            return (
                                              <Col md={2} key={secindex} className="xmart30 xmarb30">
                                                <SectionCard clssec={msec} key={"_sec_" + secindex} />
                                              </Col>
                                            );
                                          })}
                                      </Row>


                                    </CardBody>
                                  </Card>
                                )
                              })}
                            </>

                          ) : (
                            <Card>
                              <CardBody>
                                <div className="row">
                                  <div className="col-md-2">
                                    No Classes
                                    </div>
                                  <div className="col-md-10">No Sections</div>
                                </div>
                              </CardBody>
                            </Card>

                          )}



                        </div>
                      )
                    })}
                  </div>
                );
              })}

          </div>

        </div>

        <Modal
          isOpen={this.state.checkPatModal}
          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Check Guardian </h5>
            <button
              type="button"
              onClick={this.handleCkey}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="needs-validation"
              onValidSubmit={this.handleValidSubmit}
            >
              {this.state.showAlert ? (
                <Alert color={this.state.alertType}>
                  <p>{this.state.alertText}</p>
                </Alert>
              ) : null}

              <Row>
                <Col md={9}>
                  <div className="mb-3 mandate">
                    <AvField
                      name="phone"
                      label="Mobile"
                      value={this.state.father_phone}
                      type="number"
                      required
                      placeholder={'Enter Mobile'}
                      validate={{
                        required: { value: true, errorMessage: 'Please enter Mobile' },
                        pattern: { value: '^[0-9]+$', errorMessage: 'Your Mobile must be only numbers' },
                        minLength: { value: 10, errorMessage: 'Your Mobile must be 10 characters' },
                        maxLength: { value: 10, errorMessage: 'Your Mobile not more than 10 characters' }
                      }}
                    />
                  </div>
                </Col>


                <Col md={3}>
                  <button
                    className="btn btn-primary btn-block waves-effect waves-light"
                    type="submit"
                  >
                    Check Guardian
                        </button>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Modal>


        <Modal
          isOpen={this.state.addKeyModal}

          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">{this.state.modalheading} </h5>
            <button
              type="button"
              onClick={this.handleCkey}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">


            <form
              className="form"
              onSubmit={(event) => { e.preventDefault(); }}
            >
              {this.state.showAlert ? (
                <Alert color={this.state.alertType}>
                  <p>{this.state.alertText}</p>
                </Alert>
              ) : null}


              <div className="mb-3">
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <input type="file" id="input" onChange={(e) => {
                      this.setState({
                        excelFile: e.target.files[0],
                      });
                    }} />
                  </div>
                </div>
              </div>

              <div className="mt-4 d-grid">

                <button
                  className="btn btn-primary btn-block waves-effect waves-light"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    readXlsxFile(this.state.excelFile).then((rows) => {
                      let studentsArray = rows.filter((row, index) => {
                        if (index > 0) {
                          return {
                            teaname: row[0],
                            email: row[1],
                            phone: row[2],
                            gender: row[3],
                            dob: row[4],
                            religion: row[5],
                            ccaste: row[6],
                            caste: row[7],
                            qualification: row[8],
                            qualification_description: row[9],
                            address: row[10],
                            employee_id: row[11],
                            joining_date: row[12],
                            adhaar_num: row[13],
                            pf_num: row[14],
                            esi_num: row[15],
                            pancard_num: row[16],
                            bank_name: row[17],
                            branch_name: row[18],
                            account_num: row[19],
                            ifsc_code: row[20],
                            micr_code: row[21],
                            swift_code: row[22],
                            photo: USER_IMG,
                            adhaar_copy: NO_IMG,
                            pancard_copy: NO_IMG,
                          };
                        }
                      });
                      this.setState(
                        {
                          excelstudents: [...studentsArray],
                        },
                        () => {
                          this.uploadExcelQuestions(e);
                        },
                      );
                    });
                  }}
                >
                  Upload Students Excel
                </button>
              </div>
            </form>




          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

export default Students
