import React, { Component } from "react"
import { Alert, Row, Col, Card, CardBody, CardTitle, CardImg, CardText, CardSubtitle, Modal, Button, FormGroup, InputGroup, Label, Table } from "reactstrap";
import { map } from "lodash"
import MetaTags from 'react-meta-tags';
import axios from 'axios';
import { STD_URL } from "../../../helpers/url_helper";

import { AvField, AvForm } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//Import Card
import StudentCard from "./StudentCard"
// import StudentCard from "./StudentCard"
const initialstate = {
  itempageabout: 'Section Students | Management',
  itemmainname: 'Management',
  itemsubname: 'Section Students',
  tname: 'schstd',
  tdepname: 'dummy',
  itemvalue: '',
  relateditems: [],

};
class SectionStds extends Component {
  constructor(props) {
    super(props)
    // this.state = {}
    this.handleGetkeys = this.handleGetkeys.bind(this);
    this.handleAkey = this.handleAkey.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    localStorage.setItem('std_admission_date', '');
    localStorage.setItem('stddob', '');
    localStorage.setItem('whattodostudent', '');
    localStorage.setItem('stdid', '');
    localStorage.setItem('patid', '');
    localStorage.setItem('stdclsid', '');
    localStorage.setItem('stdsecid', '');
  }
  state = { ...initialstate };
  componentDidMount() {
    if (localStorage.schclsid && localStorage.schsecid) {
      this.handleGetkeys();

    } else {
      this.props.history.push('/students'); //eslint-disable-line
    }
  }

  handleGetkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
      schclsid: localStorage.schclsid,
      schsecid: localStorage.schsecid,
      schstrucid: localStorage.schstrucid,
    };
    const getuurl = STD_URL + 'getsectionstds';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);
          console.log('allitems', response.data.allitems);
          this.setState({
            relateditems: response.data.allitems,
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        // localStorage.clear();
        //this.props.history.push('/'); //eslint-disable-line
      });
  };
  handleValidSubmit = (event, values) => {
    event.preventDefault();
    console.log('values', values);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var addkeyurl = STD_URL + 'checkpat';

    var postData = {
      uid: localStorage.usercode,
      table: 'schstdparnt',
      // adhaar_num: values.adhaar_num,
      phone: values.phone,
      whois: 'phone',
    };
    axios
      .post(addkeyurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log(response.data.message);
        if (response.data.error) {
          this.setState({
            showAlert: true,
            alertType: 'warning',
            alertText: response.data.message,
          });
        } else {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("patid", response.data.patid);


          localStorage.setItem('stdclsid', this.state.clsid);
          localStorage.setItem('stdsecid', this.state.secid);
          localStorage.setItem('stdid', '');
          localStorage.setItem('stddob', '');
          localStorage.setItem('std_admission_date', '');
          localStorage.setItem('whattodostudent', 'Add Student');
          this.props.history.push('/mngstudent'); //eslint-disable-line

        }
      })
      .catch((error) => {
        console.log('Error is ', error);
        this.setState({
          alertType: 'danger',
          showAlert: true,
          alertText: 'Failed Try Again Later!',
        });
      });

  };


  handleAkey = () => {
    this.setState({
      checkPatModal: true,
      clsid: localStorage.schclsid,
      secid: localStorage.schsecid,
    });
  };
  handleCkey = () => {
    this.setState({
      checkPatModal: false,

    });
  };




  render() {

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.state.itempageabout} </title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title={this.state.itemmainname} breadcrumbItem={this.state.itemsubname} />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">{this.state.itemsubname}
                      <Button
                        className="float-end btn-sm"
                        color="success"
                        onClick={this.handleAkey}
                      >
                        + Add Student
						        		</Button>
                      {/* <br />
                      <br />
                      <Button
                        className="float-end btn-sm"
                        color="success"
                        onClick={this.handleCSVAkey}
                      >
                        + Bulk Upload Students
						        		</Button> */}
                    </CardTitle>
                    <p className="card-title-desc">Some Description</p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              {map(this.state.relateditems, (std, stdindex) => (
                <Col md={2} key={stdindex} className="xmart30 xmarb30">
                  <StudentCard secstd={std} key={"_std_" + stdindex} />
                </Col>
              ))}
            </Row>


          </div>

        </div>

        <Modal
          isOpen={this.state.checkPatModal}
          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Check Guardian </h5>
            <button
              type="button"
              onClick={this.handleCkey}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="needs-validation"
              onValidSubmit={this.handleValidSubmit}
            >
              {this.state.showAlert ? (
                <Alert color={this.state.alertType}>
                  <p>{this.state.alertText}</p>
                </Alert>
              ) : null}

              <Row>
                <Col md={9}>
                  <div className="mb-3 mandate">
                    <AvField
                      name="phone"
                      label="Mobile"
                      value={this.state.father_phone}
                      type="number"
                      required
                      placeholder={'Enter Mobile'}
                      validate={{
                        required: { value: true, errorMessage: 'Please enter Mobile' },
                        pattern: { value: '^[0-9]+$', errorMessage: 'Your Mobile must be only numbers' },
                        minLength: { value: 10, errorMessage: 'Your Mobile must be 10 characters' },
                        maxLength: { value: 10, errorMessage: 'Your Mobile not more than 10 characters' }
                      }}
                    />
                  </div>
                </Col>


                <Col md={3}>
                  <button
                    className="btn btn-primary btn-block waves-effect waves-light"
                    type="submit"
                  >
                    Check Guardian
                        </button>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Modal>

      </React.Fragment>
    )
  }
}

export default SectionStds
