import React, { Component } from "react"
import PropTypes from 'prop-types'
import { Link, withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  UncontrolledTooltip,
  Modal,
  Button,
  Row
} from "reactstrap";
import axios from 'axios';
import { STD_URL } from "../../../helpers/url_helper";

import { AvField, AvForm } from "availity-reactstrap-validation"

class StudentCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checkPatModal: false,
    }
    this.handleViewStd = this.handleViewStd.bind(this);
    this.handleAkey = this.handleAkey.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    localStorage.setItem('schclsid', '');
    localStorage.setItem('schsecid', '');
  }
  handleViewStd = (stdid, stddob, stdadmidate) => {

    localStorage.setItem('stdid', stdid);
    localStorage.setItem('stddob', stddob);
    localStorage.setItem('stdadmidate', stdadmidate);
    this.props.history.push('/viewstudent'); //eslint-disable-line
  };

  handleAkey = (clsid, secid) => {
    this.setState({
      checkPatModal: true,
      clsid: clsid,
      secid: secid,
    });
  };
  handleCkey = () => {
    this.setState({
      checkPatModal: false,

    });
  };

  handleValidSubmit = (event, values) => {
    event.preventDefault();
    console.log('values', values);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var addkeyurl = STD_URL + 'checkpat';

    var postData = {
      uid: localStorage.usercode,
      table: 'schstdparnt',
      // adhaar_num: values.adhaar_num,
      phone: values.phone,
      whois: 'phone',
    };
    axios
      .post(addkeyurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log(response.data.message);
        if (response.data.error) {
          this.setState({
            showAlert: true,
            alertType: 'warning',
            alertText: response.data.message,
          });
        } else {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("patid", response.data.patid);


          localStorage.setItem('stdclsid', this.state.clsid);
          localStorage.setItem('stdsecid', this.state.secid);
          localStorage.setItem('stdid', '');
          localStorage.setItem('stddob', '');
          localStorage.setItem('std_admission_date', '');
          localStorage.setItem('whattodostudent', 'Add Student');
          this.props.history.push('/mngstudent'); //eslint-disable-line

        }
      })
      .catch((error) => {
        console.log('Error is ', error);
        this.setState({
          alertType: 'danger',
          showAlert: true,
          alertText: 'Failed Try Again Later!',
        });
      });

  };

  render() {
    const secstd = this.props.secstd
    return (
      <React.Fragment>

        <Card>
          <CardBody>
            <div className="text-center">
              <img
                onClick={() => {
                  this.setState({
                    isFits: true,
                    lightimg: secstd.stdphoto
                  });
                }}
                src={secstd.stdphoto}
                alt="std Photo"
                className="img-thumbnail"
              />

              <h5 className="font-size-15 mb-2">
                <Link to="#" className="text-dark">
                  {secstd.stdname}
                </Link>
              </h5>
            </div>
            <p className="text-muted"><b>Adm No:</b> {secstd.admnum} <br /> <b>Adm Date :</b> {secstd.admdate} <br /> <b>Roll No :</b> {secstd.rollno}</p>
            <div className="contact-links d-flex font-size-20 border-top">
              <div className="flex-fill">

              </div>
              <div className="flex-fill">

              </div>
              <div className="flex-fill">
                <Button
                  className="float-end btn-sm  xmart10"
                  color="success"
                  onClick={this.handleViewStd.bind(this, secstd.stdid, secstd.mclsid, secstd.secid)}
                >
                  Details
								</Button>
              </div>
            </div>
          </CardBody>

        </Card>

      </React.Fragment>
    )
  }
}

StudentCard.propTypes = {
  secstd: PropTypes.object
}


export default withRouter(StudentCard)
