import axios from "axios"
import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"

// Login Method
const postLogin = data => {
  const headers = {
    'Content-Type': 'application/json',
  };
  // console.log('data', data);
  return axios
    .post(url.POST_LOGIN, data, {
      headers: headers,
    })
    .then(response => {
      // console.log('response', response);
      if (response.status == 200) return response.data
      throw response.data
    })
    .catch(err => {
      console.log('err', err);
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = err.response.data.message
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}
// const postLogin = data => post(url.POST_LOGIN, data)

// postForgetPwd
const postForgetPwd = data => post(url.POST_PASSWORD_FORGET, data)




export {
  postLogin,
  postForgetPwd,
}
