import React from "react"
import { Redirect } from "react-router-dom"

// User profile
import UserProfile from "../HorizontalLayout/UserProfile"

//dashboard
import Dashboard from '../HorizontalLayout/Dashboard'
import Academics from '../pages/Academics/index'
import AcaCategories from '../pages/Academics/AcaCategories'
import AcaDepartments from '../pages/Academics/AcaDepartments'
import AcaCategoryClasses from '../pages/Academics/AcaCategoryClasses'
import AcaCategorySections from '../pages/Academics/AcaCategorySections'
import AcaCategorySubjects from '../pages/Academics/AcaCategorySubjects'
import AcaCategoryBoards from '../pages/Academics/AcaCategoryBoards'
import AcaCategoryYear from '../pages/Academics/AcaCategoryYear'

import SchoolStructures from '../pages/School/Structure/index'
import AddSchool from '../pages/School/Structure/AddSchool'
import ManageStructure from '../pages/School/Structure/ManageStructure'


//Admin
import Teachers from '../HorizontalLayout/CoAdmin/Teachers/index'
import MngTeacher from '../HorizontalLayout/CoAdmin/Teachers/MngTeacher'
import ViewTeacher from '../HorizontalLayout/CoAdmin/Teachers/ViewTeacher'


// Schools-Admin
import Capex from '../HorizontalLayout/CoAdmin/Capex/Capex'
import SchSections from '../HorizontalLayout/CoAdmin/TimeTable/SchSections'
import TimeTable from '../HorizontalLayout/CoAdmin/TimeTable/TimeTable'
import SchAcademicStructure from '../HorizontalLayout/CoAdmin/Structure/index'

import Students from '../HorizontalLayout/CoAdmin/Students/index'
import MngStudent from '../HorizontalLayout/CoAdmin/Students/MngStudent'
import ViewStudent from '../HorizontalLayout/CoAdmin/Students/ViewStudent'
import CheckPat from '../HorizontalLayout/CoAdmin/Students/CheckPat'
import SectionStds from '../HorizontalLayout/CoAdmin/Students/SectionStds'

import DbQualification from '../pages/Db/DbQualification'
import DbActivity from '../pages/Db/DbActivity'
import DbReligion from '../pages/Db/DbReligion'
import DbBldGrp from '../pages/Db/DbBldGrp'
import DbGender from '../pages/Db/DbGender'
import DbTimeName from '../pages/Db/DbTimeName'
import DbFeeType from '../pages/Db/DbFeeType'
import DbNotificationType from '../pages/Db/DbNotificationType'
import DbNotificationIntvel from '../pages/Db/DbNotificationIntvel'
import DbWeekDay from '../pages/Db/DbWeekDay'
import DbCountry from '../pages/Db/DbCountry'
import DbState from '../pages/Db/DbState'
import DbCity from '../pages/Db/DbCity'
import DbCasteCategory from '../pages/Db/DbCasteCategory'
import DbCasteName from '../pages/Db/DbCasteName'
import DbGuardianRel from '../pages/Db/DbGuardianRel'
import DbHeight from "pages/Db/DbHeight"
import DbWeight from "pages/Db/DbWeight"

//AcademicStructure
import AcademicStructureBoards from '../pages/Academics/AcademicStructureBoards'
import AcademicStructure from '../pages/Academics/AcademicStructure'


// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import UserMng from "../HorizontalLayout/Admin/UserMng"




const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/schoolstructures", component: SchoolStructures },
  { path: "/addschool", component: AddSchool },
  { path: "/managestructure", component: ManageStructure },


  { path: "/qualifications", component: DbQualification },
  { path: "/activities", component: DbActivity },
  { path: "/religions", component: DbReligion },
  { path: "/bloodfroup", component: DbBldGrp },
  { path: "/castecategories", component: DbCasteCategory },
  { path: "/castename", component: DbCasteName },
  { path: "/genders", component: DbGender },
  { path: "/timenames", component: DbTimeName },
  { path: "/feetypes", component: DbFeeType },
  { path: "/notificationtypes", component: DbNotificationType },
  { path: "/notificationintvels", component: DbNotificationIntvel },
  { path: "/weekday", component: DbWeekDay },
  { path: "/countries", component: DbCountry },
  { path: "/cstates", component: DbState },
  { path: "/cities", component: DbCity },
  { path: "/relations", component: DbGuardianRel },
  { path: "/heights", component: DbHeight },
  { path: "/weights", component: DbWeight },

  { path: "/academics", component: Academics },
  { path: "/aca_categories", component: AcaCategories },
  { path: "/aca_departments", component: AcaDepartments },
  { path: "/aca_cat_classes", component: AcaCategoryClasses },
  { path: "/aca_cat_sections", component: AcaCategorySections },
  { path: "/aca_cat_subjects", component: AcaCategorySubjects },
  { path: "/aca_cat_boards", component: AcaCategoryBoards },
  { path: "/aca_cat_years", component: AcaCategoryYear },

  // Schools-Admin
  { path: "/capex", component: Capex },
  { path: "/timetable", component: TimeTable },
  { path: "/schsections", component: SchSections },
  { path: "/schstructure", component: SchAcademicStructure },

  { path: "/academicstructureboards", component: AcademicStructureBoards },
  { path: "/academicstructure", component: AcademicStructure },

  { path: "/teachers", component: Teachers },
  { path: "/mngteacher", component: MngTeacher },
  { path: "/viewteacher", component: ViewTeacher },

  { path: "/students", component: Students },
  { path: "/mngstudent", component: MngStudent },
  { path: "/viewstudent", component: ViewStudent },
  { path: "/checkpat", component: CheckPat },
  { path: "/secstudents", component: SectionStds },

  //profile
  { path: "/profile", component: UserProfile },

  //user management
  { path: "/usermng", component: UserMng },




  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
]

export { authProtectedRoutes, publicRoutes }
